import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { Stack, Tooltip as MUITooltip, Typography, TextField, Divider, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, Button} from '@mui/material';
import * as _ from 'lodash';
import { GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import { get, getBinary, post, postBinary } from 'utils/api';
import { useSnackbar } from 'notistack';
import { AxiosError, AxiosResponse } from 'axios';


interface FileSelectionProps {
    experimentId: number,
    callback: Function
}

export const FileSelection= (props: FileSelectionProps) => {
    const {experimentId, callback} = props;
    
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const [selectedRPMSweeps, setSelectedRPMSweeps] = useState<Array<string>>([]);
    const sx ={m:1, width: '25ch'};

    const [files, setFiles] = useState<Array<JSON>>([]);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        loadFiles(experimentId);
    },[experimentId])

    const loadFiles = (experimentId:number) => {
        get(GET_API_URLS.RESULT_FILES(experimentId), expireCallback).then((res: AxiosResponse) => {
            if (res.status === 200) {
                setFiles(res.data.result_files);
            } else {
                enqueueSnackbar('Error while loading result files.', {variant: 'error'});
            }
        }).catch((err:AxiosError) => {
            enqueueSnackbar('Error while loading result files.', {variant: 'error'});
        })
    };

    return (
        <FormControl sx={{m:1, width: '18ch'}} variant="outlined" >
            <InputLabel
                id='fileSelect-label'
                shrink={true}
            >
                Test Stand Results
            </InputLabel>
            <Select
                id='hub-fileSelect'
                labelId='fileSelect-label'
                label='Test Stand Results'
                notched={true}
                multiple = {true}
                value={selectedRPMSweeps}
                onChange={(e) => {
                    const value = _.union(e.target.value,[]);
                    setSelectedRPMSweeps(value);
                    callback(value, experimentId);
                }}
                size='small'
                style={{height:'43px',width:'355px'}}
            >
                {files.map((value:any) => { 
                if (value['filetype'] == 'TEST_STAND_RPM_SWEEP'){ 
                    return <MenuItem value={value['id']}>{value['filename'].toString()}</MenuItem>
                }
            })}
            </Select>
        </FormControl>
    )
}

export default FileSelection;