import React, {useState} from 'react';
import { Checkbox, Stack, TextField, Typography, Tooltip } from '@mui/material';

interface RangeInputProps {
    label: string,
    lowerLimit: string,
    upperLimit: string,
    onLowerLimitChange: (lowerLimit:string) => void,
    onUpperLimitChange: (upperLimit:string) => void,
    isRange: boolean,
    setIsInputRangeType: (value:boolean) => void,
    tooltip: string,
    onBlurChange: (newValue: string) => void,
    readOnly: boolean
}

export const RangeInput = (props: RangeInputProps) => {
    const {label, lowerLimit, upperLimit, onLowerLimitChange, onUpperLimitChange, isRange, setIsInputRangeType, tooltip, onBlurChange, readOnly} = props;

    return (
        <Tooltip title={tooltip} arrow>
            <Stack spacing={2}>
                    <fieldset 
                        style={{
                            border: '1px solid #00000040', 
                            borderRadius:'5px', 
                            padding:'0px 14px 2px 14px', 
                            width:'155px',
                            height: '60px'
                            }}
                        >
                        <legend 
                            style={{
                                marginLeft:'5px', 
                                color:'#00000099', 
                                fontSize: '14px', 
                                fontFamily:'"Roboto","Helvetica","Arial",sans-serif'
                            }}
                        >
                            {label}
                        </legend>
                        {isRange?(
                            <Stack direction='row' spacing={2}>
                                <TextField 
                                    label=''
                                    variant='standard'
                                    value={lowerLimit}
                                    onChange={(e) => {
                                        if(!readOnly){
                                            onLowerLimitChange(e.target.value);
                                        }
                                    }}
                                    onBlur={(e) => onBlurChange(e.target.value)}
                                    autoComplete='off'
                                />
                                <Typography style={{marginTop:'2px'}}>to</Typography>
                                <TextField 
                                    label=''
                                    variant='standard'
                                    value={upperLimit}
                                    onChange={(e) => {
                                        if(!readOnly){
                                            onUpperLimitChange(e.target.value);
                                        }
                                    }}
                                    onBlur={(e) => onBlurChange(e.target.value)}
                                    autoComplete='off'
                                    aria-readonly = {readOnly}
                                />
                            </Stack>
                        ):(
                            <TextField
                                variant='standard'
                                label=''
                                value={lowerLimit}
                                onChange={(e) => {
                                    if(!readOnly){
                                        const value = e.target.value;
                                        onLowerLimitChange(value);
                                    }
                                }}
                                onBlur={(e) => onBlurChange(e.target.value)}
                                autoComplete='off'
                                aria-readonly = {readOnly}
                            />
                        )}
                    </fieldset>
                
                <Stack direction='row-reverse' spacing={0} style={{marginTop:'2px'}}>
                    <Typography style={{marginLeft:'4px', marginRight:'6px', fontSize:'14px'}}>Range</Typography>
                    <Checkbox
                        checked={isRange}
                        onChange={(e) => {
                            if(!readOnly){
                                const value = e.target.checked;
                                setIsInputRangeType(value);
                            }
                        }}
                        onBlur={(e) => onBlurChange(e.target.value)}
                        size='small'
                        style={{padding:'0px'}}
                    />
                </Stack>
            </Stack>
        </Tooltip>
    );
}
RangeInput.defaultProps = {
    onBlurChange: () => {},
    readOnly: false
}
export default RangeInput;