import React from "react";
import "@silevis/reactgrid/styles.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import RouterConfig from "./routers";

import "static/css/App.css";


function App() {
  return (
    <div className="App" style={{width:'100%', height:'100vh'}}>
      <RouterConfig />
    </div>
  );
}

export default App;
