import React, {useState, useEffect} from 'react';
import {useSnackbar} from 'notistack';
import { Stack,Typography } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudSyncIcon from '@mui/icons-material/CloudSync';

import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from 'common/NavigationBar';
import Sidebar, { MenuButton } from 'common/Sidebar';
import { Page } from 'constants/enums';
import { GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import { get, post } from 'utils/api';
import WithAuthenticate from 'common/hoc/WithAuthenticate';
import { useNavigate } from 'react-router-dom';

export const ExperimentsQueue = () => {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const [experiments, setExperiments] = useState([]);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [refresh, setRefresh] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(25);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        if (refresh) {
            setIsLoading(true);
            get(GET_API_URLS.QUEUED_EXPERIMENTS(), expireCallback).then((res:AxiosResponse) => {
                if (res.status === 200) {
                    setExperiments(res.data['experiments'])
                } else {
                    enqueueSnackbar('Error while loading queued experiments', {variant: 'error'});
                }
            }).catch((res:AxiosError) => {
                enqueueSnackbar('Error while loading queued experiments', {variant: 'error'});
            }).finally(() => {
                setIsLoading(false);
                setRefresh(false);
            })
        }
    }, [refresh]);

    const cancelExperiments = () => {
        if (selectionModel.length === 0) {
            enqueueSnackbar('No experiment selected for cancelation', {variant:'info'});
            return;
        }

        const data = {experiment_ids: selectionModel};
        setIsLoading(true);
        post(POST_API_URLS.EXPERIMENTS_CANCELATION(), data, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Canceled selected experiments successfully', {variant: 'success'});
                setRefresh(true);
            } else {
                enqueueSnackbar('Error while canceling selected experiments', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while canceling selected experiments', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    };

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width:100, editable: false, type:'number'},
        {field: 'title', headerName: 'Project', width:200, editable: false, type:'string'},
        {field: 'created_on', headerName: 'Date', width:300, editable: false, type: 'date'},
    ];

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop:'30px', marginBottom: '20px'}}>
                <MenuButton
                    Icon={CloudSyncIcon}
                    iconColor='black'
                    label='Cloud Sync'
                    onClick={() => setRefresh(true)}
                    disabled={false}
                />
                <MenuButton
                    Icon={CancelIcon}
                    iconColor='black'
                    label='Cancel Job'
                    onClick={cancelExperiments}
                    disabled={selectionModel.length === 0}
                />
            </Stack>
        );
    }

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{height: '100%'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.QUEUE}/>
                </div>
                <Stack spacing={3} style={{paddingLeft: '30px', width: '664px'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Experiments Queue
                    </Typography>
                    <DataGrid
                        autoHeight
                        columns={columns}
                        rows={experiments}
                        checkboxSelection
                        selectionModel={selectionModel}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
                        sx={{
                            "& .MuiDataGrid-cell": {
                            padding: '2px 10px'
                            }
                        }}
                    />
                </Stack>
            </Stack>
        </>
    );
}

export default WithAuthenticate(ExperimentsQueue);