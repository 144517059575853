import React, {useState, useEffect} from 'react';
import { useSnackbar} from 'notistack';
import {useNavigate, useParams} from 'react-router-dom';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, SelectChangeEvent, Grid, Stack, TextField, Tooltip, Typography, Checkbox } from '@mui/material';
import DirectionsRunRoundedIcon from '@mui/icons-material/DirectionsRunRounded';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { AxiosError, AxiosResponse } from 'axios';
import * as _ from 'lodash';

import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from 'common/NavigationBar';
import RangeInput from 'common/RangeInput';
import OutlinedInput from 'common/OutlinedInput';
import { Page, ExperimentStatus } from 'constants/enums';
import {get, post} from 'utils/api';
import { GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import Sidebar, { MenuButton } from 'common/Sidebar';
import './css/styles.css';
import WithAuthenticate from 'common/hoc/WithAuthenticate';
import { Label, TroubleshootOutlined } from '@mui/icons-material';


export const OptimizerSettings = () => {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const experimentId = parseInt(params['experimentId'] || '0');

    const [isMorph, setMorph] = useState<boolean>(false);
    const morphChange = () =>{
        if (!readOnly){
            setMorph(!isMorph)
        }
    }

    const [experiment, setExperiment] = useState<object>({});
    const [isHybrid, setIsHybrid] = useState<boolean>(false)
    const [rpmLower, setRPMLower] = useState<string>('0');
    const [rpmUpper, setRPMUpper] = useState<string>('0');
    const [isRPMRange, setIsRPMRange] = useState<boolean>(false);
    const [chordLower, setChordLower] = useState<string>('0');
    const [chordUpper, setChordUpper] = useState<string>('0');
    const [isChordRange, setIsChordRange] = useState<boolean>(false);
    const [solveFor, setSolveFor] = useState<string>("RPM");
    const [upperBound, setUpperBound] = useState<string>('10000');
    const [lowerBound, setLowerBound] = useState<string>('0');
    const [maxTime, setMaxTime] = useState<string>('60');
    const [iterations, setIterations] = useState<string>('1000');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        setIsLoading(true);
        get(GET_API_URLS.EXPERIMENT(experimentId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const _experiment = res.data.experiment;
                setExperiment(_experiment);
                setRPMLower(`${Number(_experiment['rpm_lower'])}`);
                setRPMUpper(`${Number(_experiment['rpm_upper'])}`);
                setIsRPMRange(Number(_experiment['rpm_lower']) !== Number(_experiment['rpm_upper']))
                setChordLower(`${Number(_experiment['chord_lower'])}`);
                setChordUpper(`${Number(_experiment['chord_upper'])}`);
                setIsChordRange(Number(_experiment['chord_lower']) !== Number(_experiment['chord_upper']))
                setSolveFor(_experiment['target_parameter']);
                setUpperBound(_.get(_experiment, 'experiment_parameter.upper_bound', '10000'));
                setLowerBound(_.get(_experiment, 'experiment_parameter.lower_bound', '0'));
                setMaxTime(`${Number(_experiment['max_runtime'])}`);
                setIterations(`${Number(_experiment['max_iteration'])}`);
                setMorph(_.get(_experiment, 'experiment_parameter.hybrid_morph', false))
                setIsHybrid((_experiment['is_hybrid_blade']));
                setReadOnly([ExperimentStatus.COMPLETED].includes(ExperimentStatus[_.get(_experiment,'status', ExperimentStatus.FAILED) as ExperimentStatus]));
            } else {
                enqueueSnackbar('Error while loading the experiment.', {variant: 'error'});    
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while loading the experiment.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })                                                                            
    }, []);  
    
    const isRangeDefined = (_experiment:any) => {
        return ( _experiment && (
            (_experiment['hub_break_radius_lower'] !== _experiment['hub_break_radius_upper']) ||
            (_experiment['tip_break_radius_lower'] !== _experiment['tip_break_radius_upper']) ||
            (_experiment['blades_count_lower'] !== _experiment['blades_count_upper']) ||
            (_experiment['max_cl_lower'] !== _experiment['max_cl_upper']) ||
            (_experiment['hub_break_cl_lower'] !== _experiment['hub_break_cl_upper']) ||
            (_experiment['tip_break_cl_lower'] !== _experiment['tip_break_cl_upper'])
        ));
    }

    const runOptimization = () => {

        if ((isRPMRange && (Number(rpmLower) > Number(rpmUpper))) || 
            (isChordRange && (Number(chordLower) > Number(chordUpper)))) {
            enqueueSnackbar('Invalid range.', {variant: 'error'});
            return;
        }

        if (Number(lowerBound) > Number(upperBound)) {
            enqueueSnackbar('Invalid bound.', {variant: 'error'});
            return;
        }

        const data = {
            'max_runtime': maxTime,
            'max_iteration': iterations,
            'rpm_lower': rpmLower,
            'rpm_upper': isRPMRange?rpmUpper:rpmLower,
            'chord_lower': chordLower,
            'chord_upper': isChordRange?chordUpper:chordLower,
            'target_parameter': solveFor,
            'experiment_parameter': {
                'upper_bound': upperBound,
                'lower_bound': lowerBound,
                'hybrid_morph': isMorph,
            }
        };  

        setIsLoading(true);
        post(POST_API_URLS.EXPERIMENT_OPTIMIZATION(experimentId), data, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                setExperiment((_experiment:object) => ({..._experiment, status:ExperimentStatus.QUEUED}))
                enqueueSnackbar('Sent the experiment to the queue for optimization.', {variant: 'success'});
            } else {
                enqueueSnackbar('Error while sending the experiment for optimization.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while sending the experiment for optimization.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop:'30px', marginBottom: '20px'}}>
                <MenuButton
                    Icon={DirectionsRunRoundedIcon}
                    iconColor='black'
                    label='Add to Queue'
                    onClick={runOptimization}
                    disabled={(!experiment) || 
                        Number(chordLower)<=0  || Number(rpmLower)<=0  || 
                        (Number(rpmUpper)<=0 && solveFor==='CHORD' && isRPMRange) || (Number(chordUpper)<=0 && solveFor==='RPM' && isChordRange)  ||
                        [ExperimentStatus.COMPLETED, ExperimentStatus.COMPLETED_BUT_STOPPED, ExperimentStatus.QUEUED, 
                        ExperimentStatus.IN_PROGRESS].includes(ExperimentStatus[_.get(experiment,'status', ExperimentStatus.FAILED) as ExperimentStatus])}
                />
                <MenuButton
                    Icon={ArticleOutlinedIcon}
                    iconColor='black'
                    label='Log Files'
                    onClick={() => navigate(`/logs/${experimentId}`)}
                    disabled={(!experiment) || [ExperimentStatus.CREATED, 
                        ExperimentStatus.QUEUED].includes(ExperimentStatus[_.get(experiment,'status', ExperimentStatus.FAILED) as ExperimentStatus])}
                />
            </Stack>
        );
    };

    const smallSX ={m:1, width: '15ch'};

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{height:'100%'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.OPTIMIZER}/>
                </div>
                <Stack spacing={3} style={{paddingLeft:'30px'}}>
                    <Typography style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}>
                        Optimizer
                    </Typography>
                    <Grid container spacing={8}>
                        <fieldset className='input-grouping'>
                            <legend className='input-grouping-label'>Solve For...</legend>
                            <Stack spacing={2}>
                                <Stack direction='row'>
                                    {solveFor==='RPM'?(
                                        <OutlinedInput
                                            label='RPM'
                                            value={rpmLower}
                                            onValueChange={(newRPM) => {
                                                setRPMLower(newRPM);
                                            }}
                                            readOnly={readOnly}
                                            style={{width:'155px', height: '60px'}}
                                            tooltip='Guess an initial RPM for optimization'
                                        />
                                    ):(
                                        <RangeInput
                                            label='RPM'
                                            lowerLimit={rpmLower}
                                            onLowerLimitChange={(newRPMLower) => {
                                                setRPMLower(newRPMLower);
                                            }}
                                            upperLimit={rpmUpper}
                                            onUpperLimitChange={(newRPMUpper) => {
                                                setRPMUpper(newRPMUpper)
                                            }}
                                            isRange={isRPMRange}
                                            setIsInputRangeType={(value) => setIsRPMRange(value)}
                                            readOnly = {readOnly}
                                            tooltip='Set the RPM to optimize the chord at'
                                        />
                                    )}
                                    {solveFor==='CHORD'?(
                                        <OutlinedInput
                                            label='Chord Length (in)'
                                            value={chordLower}
                                            onValueChange={(newChord) => {
                                                setChordLower(newChord);
                                            }}
                                            readOnly={readOnly}
                                            style={{width:'155px', height: '48px'}}
                                            tooltip='Guess an initial chord for optimization'
                                        />
                                    ):(
                                        <RangeInput
                                            label='Chord Length (in)'
                                            lowerLimit={chordLower}
                                            onLowerLimitChange={(newChordLower) => {
                                                setChordLower(newChordLower);
                                            }}
                                            upperLimit={chordUpper}
                                            onUpperLimitChange={(newChordUpper) => {
                                                setChordUpper(newChordUpper)
                                            }}
                                            isRange={isChordRange}
                                            setIsInputRangeType={(value) => setIsChordRange(value)}
                                            readOnly = {readOnly}
                                            tooltip='Set the chord to optimize the RPM at'
                                        />
                                    )}
                                    <FormControl style={{marginLeft:'40px', top:'-9px', width:'208px'}}>
                                        <FormLabel id='solveFor' style={{fontSize:'16px'}}>
                                            <div style={{float:'left'}}>Solve For</div>
                                        </FormLabel>
                                        <RadioGroup 
                                            row 
                                            aria-labelledby='solveFor' 
                                            value={solveFor}
                                            onChange={(e) => {
                                                if(!readOnly){
                                                    const value = e.target.value;
                                                    if (value === 'RPM') {
                                                        setIsRPMRange(false);
                                                    } else {
                                                        setIsChordRange(false);
                                                    }
                                                    setSolveFor(value);
                                                }
                                            }}
                                        >
                                            <FormControlLabel 
                                                value='RPM' 
                                                control={<Radio size='small'/>} 
                                                label={<Typography style={{fontSize:'15px'}}>RPM</Typography>}
                                            />
                                            <FormControlLabel 
                                                value='CHORD' 
                                                control={<Radio size='small'/>} 
                                                label={<Typography style={{fontSize:'15px'}}>Chord Length</Typography>}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                                {(isRangeDefined(experiment) || (isRPMRange && (Number(rpmLower) !== Number(rpmUpper))) ||
                                 (isChordRange && (Number(chordLower) !== Number(chordUpper)))) && (
                                <div style={{color:'#00000066'}}>
                                    The solution that uses the least power will always be selected within the given ranges provided
                                </div>
                                )}
                            </Stack>
                        </fieldset>
                        
                        <fieldset className='input-grouping'>
                            <legend className='input-grouping-label'>{`${solveFor==='RPM'?'RPM':'Chord'} Bound`}</legend>
                            <Stack direction='row'>
                                <OutlinedInput
                                    label={`${solveFor==='RPM'?'RPM':'chord'} Upper Bound`}
                                    value={upperBound}
                                    onValueChange={(newUpperBound) => {
                                        setUpperBound(newUpperBound);
                                    }}
                                    readOnly={readOnly}
                                    style={{width:'155px', height: '60px'}}
                                    tooltip={`Upper limit of ${solveFor==='RPM'?'RPM':'chord'} for optimizer`}
                                />
                                <OutlinedInput
                                    label={`${solveFor==='RPM'?'RPM':'chord'} Lower Bound`}
                                    value={lowerBound}
                                    onValueChange={(newLowerBound) => {
                                        setLowerBound(newLowerBound);
                                    }}
                                    readOnly={readOnly}
                                    style={{width:'155px', height: '60px'}}
                                    tooltip={`Lower limit of ${solveFor==='RPM'?'RPM':'chord'} for optimizer`}
                                />
                            </Stack>
                        </fieldset>

                        <fieldset className='input-grouping'>
                            <legend className='input-grouping-label'>Optimizer Settings</legend>
                            <Grid>
                                
                                <OutlinedInput
                                    label='Max Time (min)'
                                    value={maxTime}
                                    onValueChange={(newMaxTime) => {
                                        setMaxTime(newMaxTime);
                                    }}
                                    readOnly={readOnly}
                                    style={{width:'155px', height: '60px'}}
                                    tooltip={`Maximum runtime for optimization process`}
                                />
                                <OutlinedInput
                                    label='# of iterations'
                                    value={iterations}
                                    onValueChange={(newIterations) => {
                                        setIterations(newIterations);
                                    }}
                                    readOnly={readOnly}
                                    style={{width:'155px', height: '60px'}}
                                    tooltip={`Maximum number of iterations`}
                                />
                                <Tooltip title="Use morphing properties">
                                    <FormControlLabel control=
                                        {<Checkbox 
                                        checked={isMorph} 
                                        onChange={morphChange}
                                        disabled={!isHybrid}
                                    
                                        />} 
                                    label="Morphing Airfoil"/>
                                </Tooltip>
                               
                            </Grid>
                        </fieldset>
                    </Grid>
                </Stack>
            </Stack>
        </>
    );

}

export default WithAuthenticate(OptimizerSettings);