import React, {useMemo} from 'react';
import {Accept, useDropzone} from 'react-dropzone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Typography } from '@mui/material';


interface DropzoneProps {
    onDrop: (acceptedFile: any) => void,
    accept: {[key: string]: Array<string>},
    description?: string,
    maxFiles?: number,
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

export const Dropzone = (props: DropzoneProps) =>  {
    const {onDrop, accept, description, maxFiles} = props;

    const {getRootProps, getInputProps, isFocused, isDragAccept, isDragReject} = useDropzone({
        onDrop: onDrop,
        maxFiles: maxFiles?maxFiles:1,
        accept: accept
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused?focusedStyle:{}),
        ...(isDragAccept?acceptStyle:{}),
        ...(isDragReject?rejectStyle:{})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div className="container">
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <FileUploadIcon style={{width: '50px', height: '50px'}}/>
                <Typography align='center'>
                    {(description===undefined)?"Drag 'n' drop the file here, or click to select the file": description}
                </Typography>
            </div>
        </div>
    );

};

export default Dropzone;