import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { Stack, Tooltip as MUITooltip, Typography, TextField, Divider, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, Button} from '@mui/material';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ViewInArRoundedIcon from '@mui/icons-material/ViewInArRounded';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import * as _ from 'lodash';
import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from 'common/NavigationBar';
import { Page, OffConditionAnalysisParameterOLD } from 'constants/enums';
import Sidebar, { MenuButton } from 'common/Sidebar';
import STLDisplay from 'components/Results/STLDisplay';
import WithAuthenticate from 'common/hoc/WithAuthenticate';
import { useLocation } from "react-router-dom";
import OutlinedInput from 'common/OutlinedInput';
import FileSelection from 'components/OffConditionAnalysis/FileSelection';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { ResultFields } from 'components/Results/ResultFields';
import { getExperimentTitle } from 'utils/utils';
import RangeStepInput from 'common/RangeStepInput';
import { GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import { get, getBinary, post, postBinary } from 'utils/api';
import { useSnackbar } from 'notistack';
import { Download } from '@mui/icons-material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

export const SweepMultipleExperiments = () => {
    const navigate = useNavigate();
    const experiment_details = useLocation();
    const experiment_details_results = Object.is(experiment_details.state,null)?[]: experiment_details.state.experiment_details
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedRPMSweeps, setSelectedRPMSweeps] = useState<any>([]);

    const goToComparisonTablePage = () => {
        navigate('/compare/results');
    }

    const colorList = ['#2196f3', '#f44336', '#4caf50', '#b56f00', '#008080'];
    const {enqueueSnackbar} = useSnackbar();
    const [sweepResult, setSweepResult] = useState([{
        rpm: [], thrust: [], torque: [], power: [], j: [], cp: [], ct: [], efficiency: [], vinf:[], altitude: []
    }]);

    const [sweepParameters, setSweepParameters] = useState({
        xAxis: OffConditionAnalysisParameterOLD.RPM, 
        pdpParam: OffConditionAnalysisParameterOLD.OTHER,
        pdpConstantType1: OffConditionAnalysisParameterOLD.V_INF,
        pdpConstantType2: OffConditionAnalysisParameterOLD.ALTITUDE,
        pdpConstantValue1: '',
        pdpConstantValue2: '',
        onlyPDP: {
            isPDP: true,
            range: {
                min: '',
                max: '',
                step: ''
            }
        },
        sweep_param: {
            range: {
                min: '',
                max: '',
                step: ''
            }
        },
        experiments: experiment_details_results

    });

    const smallSX = {m:1, width: '18ch'};
    const xAxisTypes = [
        {
            key: 1,
            value: 'RPM'
        },
        {
            key: 2,
            value: 'V∞ (ft/s)'
        },
        {
            key: 3,
            value: 'Altitude (ft)'
        },
        {
            key: 4,
            value: 'Thrust (lbf)'
        },
        {
            key: 5,
            value: 'Torque (lbfin)'
        },
        {
            key: 6,
            value: 'Power (W)'
        }

    ]
    const pdpTypes = [
        {
            key: 1,
            value: 'RPM'
        },
        {
            key: 2,
            value: 'V∞ (ft/s)'
        },
        {
            key: 3,
            value: 'Altitude (ft)'
        }

    ]

    useEffect(() =>{
        if(Object.is(experiment_details.state, null)){
            navigate('/analysis');
        }
    },[]);
    
    const expireCallback = () => {
        navigate('/login');
    }

    const getFileSelection = (selectedFiles:Array<number>, experimentId:number) => {
        setSelectedRPMSweeps({...selectedRPMSweeps, [experimentId]:selectedFiles});
    }

    function loadFileButtonDisabled(){
        for (let keys in selectedRPMSweeps){
            if (selectedRPMSweeps[keys].length > 0) {
                return false;
            }
        }
        return true;
    }

    const validationCheck = (buttonPressed:string) => {
        if (buttonPressed == 'Files'){
            return true;
        }
        let check = getLabelBasedOnSweepType(sweepParameters.xAxis);
        if (sweepParameters.sweep_param.range.min === '' || sweepParameters.sweep_param.range.max === '' || 
        ((check=='RPM'||check=='Altitude (ft)'||check=='V∞ (ft/s)') &&sweepParameters.sweep_param.range.step === '')) {
            enqueueSnackbar(`Fill up all the ${getLabelBasedOnSweepType(sweepParameters.xAxis)} fields`, {variant: 'error'});
            return false;
        } else if (sweepParameters.pdpParam !== OffConditionAnalysisParameterOLD.OTHER && (sweepParameters.onlyPDP.range.min === '' || 
            sweepParameters.onlyPDP.range.max === '' || 
            sweepParameters.onlyPDP.range.step === '')) {
                enqueueSnackbar(`Fill up all the ${getLabelBasedOnSweepType(sweepParameters.pdpParam)} fields`, {variant: 'error'});
                return false;
        } else if (sweepParameters.pdpConstantValue1 === '' ) {
            enqueueSnackbar(`Fill up the ${pdpTypes.filter(model => model.key == sweepParameters.pdpConstantType1)[0].value} field`, {variant: 'error'});
            return false;
        } else if (sweepParameters.pdpConstantValue2 === '' ) {
            enqueueSnackbar(`Fill up the ${pdpTypes.filter(model => model.key == sweepParameters.pdpConstantType2)[0].value} field`, {variant: 'error'});
            return false;
        } else if (sweepParameters.sweep_param.range.min === '' || sweepParameters.sweep_param.range.max === '' || (!(check=='RPM'||check=='Altitude (ft)'||check=='V∞ (ft/s)') &&sweepParameters.sweep_param.range.step === '')) {
            sweepParameters.sweep_param.range.step = '1'
            return true;
        } 

        return true

    }   

    const getInputData = (buttonPressed:string) => {
        const data = {
            xAxis: sweepParameters.xAxis, 
            pdpParam: sweepParameters.pdpParam,
            pdpConstantType1: sweepParameters.pdpConstantType1,
            pdpConstantValue1: sweepParameters.pdpConstantValue1,
            pdpConstantType2: sweepParameters.pdpConstantType2,
            pdpConstantValue2: sweepParameters.pdpConstantValue2,
            onlyPDP: {
                isPDP: true,
                range: {
                    min: sweepParameters.onlyPDP.range.min,
                    max: sweepParameters.onlyPDP.range.max,
                    step: sweepParameters.onlyPDP.range.step
                }
            },
            sweep_param: {
                range: {
                    min: sweepParameters.sweep_param.range.min,
                    max: sweepParameters.sweep_param.range.max,
                    step: sweepParameters.sweep_param.range.step
                }
            },
            selectedSweeps: selectedRPMSweeps, 
            button: buttonPressed,
            experiments: experiment_details_results
        }
            return data
    }

    const sweepWithType = (buttonPressed:string) => {

        if (validationCheck(buttonPressed)) {
            
            const data = getInputData(buttonPressed)
            setIsLoading(true);
            post(POST_API_URLS.SWEEP_MULTIPLE_EXPERIMENTS(),  data, expireCallback).then((res:AxiosResponse) => {
                if (res.status === 200) {
                    
                    setSweepResult(res.data['result'])
                    
                } else {
                    enqueueSnackbar('Error while loading the sweep', {variant: 'error'});
                }
            }).catch((res:AxiosError) => {
                enqueueSnackbar('Error while loading the sweep', {variant: 'error'});
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }

    const setSweepParamBasedOnXAxis = (value: string) => {

        let pdpConstantType1 = OffConditionAnalysisParameterOLD.V_INF
        let pdpConstantType2 = OffConditionAnalysisParameterOLD.ALTITUDE
        let pdpParam = OffConditionAnalysisParameterOLD.OTHER
    
        if (value.toString() === OffConditionAnalysisParameterOLD.RPM.toString()) {

            pdpConstantType1 = OffConditionAnalysisParameterOLD.V_INF
            pdpConstantType2 = OffConditionAnalysisParameterOLD.ALTITUDE

        } else if (value.toString() === OffConditionAnalysisParameterOLD.V_INF.toString()) {
            
            pdpConstantType1 = OffConditionAnalysisParameterOLD.ALTITUDE
            pdpConstantType2 = OffConditionAnalysisParameterOLD.RPM

        } else if (value.toString() === OffConditionAnalysisParameterOLD.ALTITUDE.toString()) {
            
            pdpConstantType1 = OffConditionAnalysisParameterOLD.RPM
            pdpConstantType2 = OffConditionAnalysisParameterOLD.V_INF

        } else {
            pdpParam = OffConditionAnalysisParameterOLD.RPM
        }

        setSweepParameters({...sweepParameters, xAxis: Number(value), pdpParam: pdpParam,
            pdpConstantType1: pdpConstantType1, pdpConstantType2: pdpConstantType2, pdpConstantValue1: '', pdpConstantValue2: '',
            sweep_param: {...sweepParameters.sweep_param, range: {...sweepParameters.sweep_param.range, min: '', max: '', step: ''}},
            onlyPDP: {...sweepParameters.onlyPDP, range: {...sweepParameters.onlyPDP.range, min: '', max: '', step: ''}
            }})
        setSweepResult([{
            rpm: [], thrust: [], torque: [], power: [], j: [], cp: [], ct: [], efficiency: [], vinf:[], altitude: []
        }])


    }

    const getLabelBasedOnSweepType = (type: number) => {
        return xAxisTypes.filter((model => model.key === type))[0].value
    }

    const handleGraphs = (x: string, y: string) => {
        const graphData = {datasets: sweepResult.map((value:any, index: number) => ({
            label: getExperimentTitle(experiment_details_results[index]),
            data: value[x].map((x_value:any, idx:number) => ({
                x:Number(x_value).toFixed(3), 
                y:Number(value[y][idx]).toFixed(3)
            })
        ),
        borderColor: colorList[index%5],
        pointBackgroundColor: colorList[index%5],
        showLine: true,
        }))}

        return graphData
    };

    const getGraphOptions = (title: string, xLabel: string, yLabel: string) => ({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context:any) => {
                        return context.formattedValue;
                    }
                }
            },
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: title,
            },
          },
          scales: {
              x: {
                  display: true,
                  title: {
                      display: true,
                      text: xLabel,
                  },
              },
              y: {
                  display: true,
                  title: {
                      display: true,
                      text: yLabel
                  },
                  ticks: {
                    display: true,
                    autoSkip: false,
                  }
              },
          }
      });

    const Menubar = () => {
        return (
            <></>
        );
    };

    const isPDPParamSelectedForXAxis = () => {
        return sweepParameters.xAxis === OffConditionAnalysisParameterOLD.RPM || 
        sweepParameters.xAxis === OffConditionAnalysisParameterOLD.ALTITUDE || 
        sweepParameters.xAxis === OffConditionAnalysisParameterOLD.V_INF
    }

    return (
        <>
        {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' spacing={3}>
            
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.RESULTS}/>
                </div>
                
            
                <Stack spacing={3} style={{marginBottom:'30px'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Propellers Analysis
                    </Typography>
                    {(experiment_details_results.length > 0) && <Stack 
                        spacing={2} 
                        divider={<Divider orientation="vertical" flexItem />} 
                        flexWrap="wrap"
                        direction='row'
                        justifyContent='center'
                    >
                        {experiment_details_results.map((experiment: any, key: number) => (
                            <Stack
                                spacing={2}
                                justifyContent='center'
                            >
                                <ResultFields 
                                    key={key}
                                    title={getExperimentTitle(experiment)}
                                    efficiency={Number(_.get(experiment, 'result.efficiency', '0'))}
                                    shaftPower={Number(_.get(experiment, 'result.other.power_shaft', '0'))}
                                    thrust={Number(_.get(experiment, 'result.other.thrust_n', []).reduce((sum:number, currentValue:number)=>sum+currentValue,0) / 4.4482216)}
                                    torque={Number(_.get(experiment, 'result.other.torque_n', []).reduce((sum:number, currentValue:number)=>sum+currentValue,0) * 8.8507457673787)}
                                    finalChord={Number(_.get(experiment, 'result.chord', '0'))}
                                    finalRPM={Number(_.get(experiment, 'result.range', '0'))}
                                    maxMachValue={Number(_.get(experiment, 'result.other.max_mach', '0'))}
                                />
                                <FileSelection experimentId={experiment.id} callback={getFileSelection}/>
                            </Stack>
                        ))}

                    <Stack spacing={2} alignItems='center'>
                        <Button
                            variant='contained'
                            onClick={() => {
                                sweepWithType('Files');
                            }}
                            style={{marginLeft: '9px', width:'355px'}}
                            disabled={loadFileButtonDisabled()}
                        >
                            Load Files
                        </Button>
                    </Stack>
                </Stack>}
                    <Stack spacing={2}>
                        <Stack direction='row' spacing={2}>
                            <FormControl sx={smallSX} variant="outlined">
                                                <InputLabel
                                                    id='xAxis-label'
                                                    style={{marginLeft: '9px'}}
                                                >
                                                   X Axis
                                                </InputLabel>
                                                <Select
                                                    id='xAxis'
                                                    label='X Axis Parameter'
                                                    value={sweepParameters.xAxis.toString()}
                                                    onChange={(e: SelectChangeEvent<string>, child: React.ReactNode) => {

                                                      setSweepParamBasedOnXAxis(e.target.value)
                                                        
                                                
                                                    }}
                                                    size='small'
                                                    style={{height:'43px', marginLeft: '9px'}}
                                                >
                                                    {xAxisTypes.map((model, key) => {
                                                        return <MenuItem key={key} value={model.key}>{model.value}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <RangeStepInput
                                                            label={xAxisTypes.filter(value => value.key === sweepParameters.xAxis)[0] ? xAxisTypes.filter(value => value.key === sweepParameters.xAxis)[0].value : 'X Axis'}
                                                            lowerLimit={sweepParameters.sweep_param.range.min}
                                                            onLowerLimitChange={(newMin) => {
                                                                setSweepParameters({...sweepParameters,
                                                                sweep_param: {...sweepParameters.sweep_param, range: {...sweepParameters.sweep_param.range, min: newMin}}})
                                                            }}
                                                            upperLimit={sweepParameters.sweep_param.range.max}
                                                            onUpperLimitChange={(newMax) => {
                                                                setSweepParameters({...sweepParameters,
                                                                sweep_param: {...sweepParameters.sweep_param, range: {...sweepParameters.sweep_param.range, max: newMax}}})
                                                            }}
                                                            tooltip='Step size of the range' 
                                                            step={sweepParameters.sweep_param.range.step}
                                                            onStepChange={(newStep) => {
                                                                setSweepParameters({...sweepParameters,
                                                                sweep_param: {...sweepParameters.sweep_param, range: {...sweepParameters.sweep_param.range, step: newStep}}})
                                                            }}                                
                                                            
                                        />
                        </Stack>
                        {!isPDPParamSelectedForXAxis() && <Stack direction='row' spacing={2}>
                            <FormControl sx={smallSX} variant="outlined">
                                                <InputLabel
                                                    id='pdp-param-label-1'
                                                    style={{marginLeft: '9px'}}
                                                >
                                                   X-Axis
                                                </InputLabel>
                                                <Select
                                                    id='pdpParam'
                                                    label='X-Axis'
                                                    value={sweepParameters.pdpParam.toString()}
                                                    onChange={(e: SelectChangeEvent<string>, child: React.ReactNode) => {
                                                        setSweepParameters({...sweepParameters, pdpParam: Number(e.target.value), pdpConstantValue1: ''})

                                                    }}
                                                    size='small'
                                                    style={{height:'43px', marginLeft: '9px'}}
                                                >
                                                     {pdpTypes.map((model, key) => {
                                                        return <MenuItem key={key} value={model.key}>{model.value}</MenuItem>
                                                    })}
                                                    
                                                </Select>
                                            </FormControl>
                                            <RangeStepInput
                                                    label={pdpTypes.filter(value => value.key === sweepParameters.pdpParam)[0] ? pdpTypes.filter(value => value.key === sweepParameters.pdpParam)[0].value : 'Parameter Range'}
                                                    lowerLimit={sweepParameters.onlyPDP.range.min}
                                                    onLowerLimitChange={(newLower) => {
                                                        setSweepParameters({...sweepParameters,
                                                        onlyPDP: {...sweepParameters.onlyPDP, range: {...sweepParameters.onlyPDP.range, min: newLower}}})
                                                    }}
                                                    upperLimit={sweepParameters.onlyPDP.range.max}
                                                    onUpperLimitChange={(newUpper) => {
                                                        setSweepParameters({...sweepParameters,
                                                        onlyPDP: {...sweepParameters.onlyPDP, range: {...sweepParameters.onlyPDP.range, max: newUpper}}})
                                                    }}
                                                    tooltip='Step size of the range' 
                                                    step={sweepParameters.onlyPDP.range.step}
                                                    onStepChange={(newStep) => {
                                                        setSweepParameters({...sweepParameters,
                                                        onlyPDP: {...sweepParameters.onlyPDP, range: {...sweepParameters.onlyPDP.range, step: newStep}}})
                                                    }}/>

                                    </Stack>
                                    
                        }
                        
                        <Stack direction='row' spacing={2}>
                        {<OutlinedInput
                                            label={pdpTypes.filter(model =>
                                                model.key == sweepParameters.pdpConstantType1
                                            )[0].value.toString()}
                                            value={sweepParameters.pdpConstantValue1}
                                            onValueChange={(newValue: string) => {
                                                setSweepParameters({
                                                    ...sweepParameters, 
                                                    pdpConstantValue1: newValue
                                                });
                                            }}
                                            readOnly={false}
                                            style={{width:'180px', height: '48px', marginLeft: '10px'}}
                                            tooltip=""
                                        />}
                        {<OutlinedInput
                                            label={pdpTypes.filter(model => 
                                                model.key == sweepParameters.pdpConstantType2
                                            )[0].value.toString()}
                                            value={sweepParameters.pdpConstantValue2}
                                            onValueChange={(newValue: string) => {
                                                setSweepParameters({
                                                    ...sweepParameters, 
                                                    pdpConstantValue2: newValue
                                                });
                                            }}
                                            readOnly={false}
                                            style={{width:'180px', height: '48px', marginLeft: '10px'}}
                                            tooltip=""
                                        />}
                        </Stack>
                        <Stack alignItems='center'>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    sweepWithType('Sweep');
                                }}
                                style={{marginTop: '38px', width:'355px'}}
                            >
                                COMPARE
                            </Button>
                        </Stack>
                        
                        
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        {(sweepParameters.xAxis === OffConditionAnalysisParameterOLD.RPM || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.ALTITUDE || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.V_INF) && 
                        <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Thrust (lbf) vs ${getLabelBasedOnSweepType(sweepParameters.xAxis)}`, 
                                getLabelBasedOnSweepType(sweepParameters.xAxis), 'Thrust (lbf)')} 
                                data={handleGraphs(OffConditionAnalysisParameterOLD[sweepParameters.xAxis].toLowerCase(), 'thrust')}
                            />
                        </fieldset>}
                        
                        {(sweepParameters.xAxis === OffConditionAnalysisParameterOLD.THRUST || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.TORQUE || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.POWER) && 
                        <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`RPM vs ${getLabelBasedOnSweepType(sweepParameters.xAxis)}`, 
                                getLabelBasedOnSweepType(sweepParameters.xAxis), 'RPM')} 
                                data={handleGraphs(OffConditionAnalysisParameterOLD[sweepParameters.xAxis].toLowerCase(), 'rpm')}
                            />
                        </fieldset>}

                        {(sweepParameters.xAxis === OffConditionAnalysisParameterOLD.THRUST || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.TORQUE || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.POWER || 
                        sweepParameters.xAxis === OffConditionAnalysisParameterOLD.RPM || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.ALTITUDE || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.V_INF) && 
                        <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Efficiency vs ${getLabelBasedOnSweepType(sweepParameters.xAxis)}`, 
                                getLabelBasedOnSweepType(sweepParameters.xAxis), 'Efficiency')}
                                data={handleGraphs(OffConditionAnalysisParameterOLD[sweepParameters.xAxis].toLowerCase(), 'efficiency')}
                            />
                        </fieldset>}

                    </Stack>
                    <Stack direction='row' spacing={2}>
                    {(sweepParameters.xAxis === OffConditionAnalysisParameterOLD.RPM || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.ALTITUDE || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.V_INF) && 
                    <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Power (W) vs ${getLabelBasedOnSweepType(sweepParameters.xAxis)}`, 
                                getLabelBasedOnSweepType(sweepParameters.xAxis), 
                                'Power (W)')} 
                                data={handleGraphs(OffConditionAnalysisParameterOLD[sweepParameters.xAxis].toLowerCase(), 'power')}
                            />
                        </fieldset>}
                        {(sweepParameters.xAxis === OffConditionAnalysisParameterOLD.RPM || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.ALTITUDE || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.V_INF) && 
                        <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Torque (lbfin) vs ${getLabelBasedOnSweepType(sweepParameters.xAxis)}`, 
                                getLabelBasedOnSweepType(sweepParameters.xAxis), 
                                'Torque (lbfin)')}
                                data={handleGraphs(OffConditionAnalysisParameterOLD[sweepParameters.xAxis].toLowerCase(), 'torque')}
                            />
                        </fieldset>}
                    </Stack>
                    {(sweepParameters.pdpConstantType1 !== OffConditionAnalysisParameterOLD.RPM && sweepParameters.pdpConstantType2 !== OffConditionAnalysisParameterOLD.RPM) && 
                    <Stack direction='row' spacing={2}>
                     <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`CP vs J`, 'J', 'CP')} 
                                data={handleGraphs('j', 'cp')}
                            />
                        </fieldset>
                    <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`CT vs J`, 'J', 'CT')} 
                                data={handleGraphs('j', 'ct')}
                            />
                        </fieldset>
                   
                    </Stack>}
                    {(sweepParameters.pdpConstantType1 !== OffConditionAnalysisParameterOLD.RPM && sweepParameters.pdpConstantType2 !== OffConditionAnalysisParameterOLD.RPM) && 
                    <Stack direction='row' spacing={2}>
        
                   <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Efficiency vs J`, 'J', 'Efficiency')} 
                                data={handleGraphs('j', 'efficiency')}
                            />
                    </fieldset>
                    </Stack>}
                </Stack>
            </Stack>
        </>
    );
}

export default WithAuthenticate(SweepMultipleExperiments);

