import React from "react";
import { ArcballControls } from '@react-three/drei'

const CameraControls = (props: any) => {

   return (
      <ArcballControls
         makeDefault
         dampingFactor={1000}
         enablePan={false}
         enableZoom={true}
         enableRotate={true}
         />
   );
};

export default CameraControls;