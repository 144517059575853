import React, {useCallback, useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, TextField } from '@mui/material';
import * as _ from 'lodash';

import CircularSpinner from 'common/CircularSpinner';
import Dropzone from 'common/Dropzone';
import { post, postFile, putFile } from 'utils/api';
import { POST_API_URLS, PUT_API_URLS } from 'constants/apiUrls';
import STLDisplay from 'components/Results/STLDisplay';
import { AxiosError, AxiosResponse } from 'axios';
import { dataURLToBlob } from 'utils/utils';


export interface ICoordinate {
    x: number,
    y: number,
    z: number
}

export interface IHubAttachment {
    id: number,
    name: string,
    topRightCorner: ICoordinate, 
}

export interface AddHubProps {
    onRegistrationComplete: () => void,
    hubAttachment: IHubAttachment | null,
    hubAttachmentFileURL: string,
}

export const AddHubAttachment = (props: AddHubProps) => {
    const {onRegistrationComplete, hubAttachment, hubAttachmentFileURL} = props;

    const {enqueueSnackbar} = useSnackbar(); 
    const navigate = useNavigate();

    const [name, setName] = useState<string>('');
    const [topRightCorner, setTopRightCorner] = useState<{x: string, y: string, z: string}>({x:'0', y:'0', z:'0'});
    const [fileURL, setFileURL] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [coordinateError, setCoordinateError] = useState<{xError: string, yError: string, zError: string}>(
        {xError:'', yError:'', zError:''});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (hubAttachment !== null) {
            setName(hubAttachment.name);
            setTopRightCorner({
                x:hubAttachment.topRightCorner.x.toString(), 
                y:hubAttachment.topRightCorner.y.toString(),
                z:hubAttachment.topRightCorner.z.toString(),
            });
            setFileURL(hubAttachmentFileURL);
        }
    }, [hubAttachmentFileURL])

    const expireCallback = () => {
        navigate('/login');
    }

    const onNameTextboxBlur = () => {
        if (name === ''){
            setNameError('Please enter a unique hub attachment name');
        } else {
            setNameError('');
        }
    }

    const addHubAttachment = () => {
        if (name === '') {
            enqueueSnackbar('Please enter a unique hub attachment name', {variant: 'error'});
            return;
        } else if ((coordinateError.xError) || (coordinateError.yError) || (coordinateError.zError)){
            enqueueSnackbar(coordinateError.xError || coordinateError.yError || coordinateError.zError, {variant: 'error'});
            return;
        } else if (fileURL === '') {
            enqueueSnackbar('Please upload hub attachment file', {variant: 'error'});
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        formData.append('data', JSON.stringify({
            'name': name,
            'top_right_corner': topRightCorner
        }));
        formData.append('file_data', dataURLToBlob(fileURL));

        setIsLoading(true);
        const apiCall = hubAttachment?
            putFile(PUT_API_URLS.HUB_ATTACHMENT_UPDATE(hubAttachment.id), formData, expireCallback):
            postFile(POST_API_URLS.HUB_ATTACHMENT_ADD(), formData, expireCallback);

        apiCall.then((res:AxiosResponse) => {
            if (res.status === 200) {
                onRegistrationComplete();
                enqueueSnackbar('Uploaded the hub attachment successfully.', {variant: 'success'});
            } else {
                enqueueSnackbar('Error while uploading the hub attachment.', {variant: 'error'})
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while uploading the hub attachment.', {variant: 'error'})
        }).finally(() => {
            setIsLoading(false);
        })

    }

    const onFileDrop = useCallback((acceptedFile: Array<File>) => {
        if (acceptedFile.length !== 1) {
            enqueueSnackbar('Please upload only one hub attachment file', {variant:'error'});
            return;
        }

        const file = acceptedFile[0];
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            setIsLoading(true);
            if (event.target) {
                const _fileURL = event.target.result as string;
                if(_fileURL) {
                    setFileURL(_fileURL);
                }
            }

            setIsLoading(false);
        };

        if (file) {
            fileReader.readAsDataURL(file);
        }
    }, []);

    const sx = {m:1, width:'35ch'};

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <div 
                style={{border: '1px solid #707070', 
                boxShadow:'0px 3px 6px #00000029', borderRadius: '20px', backgroundColor: 'white', color: 'black'}}            
            >
                <form>
                    <Stack spacing={2} style={{marginLeft:'50px', marginRight: '50px', marginTop:'50px'}}>
                        <fieldset style={{width: '800px', height: '100%', border: '1px solid #6D77B0', borderRadius: '12px',
                            padding: '12px 6px 6px 6px', marginLeft:'auto', marginRight:'auto'}}
                        >
                            <legend style={{marginLeft: '17px'}}>Hub Attachment</legend>
                            <Stack spacing={2} direction='column'>
                                <TextField
                                    error={!!nameError}
                                    sx={sx}
                                    label='Hub Attachment Name'
                                    value={name}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setName(e.target.value);
                                    }}
                                    size='small'
                                    helperText={nameError}
                                    onBlur={onNameTextboxBlur}
                                    style={{marginLeft:'8px', marginBottom:'8px'}}
                                />
                                <TextField
                                    error={!!coordinateError.xError}
                                    sx={sx}
                                    label='Top Right Corner X-Coordinate'
                                    value={topRightCorner.x}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setTopRightCorner({...topRightCorner, x: e.target.value});
                                    }}
                                    size='small'
                                    helperText={coordinateError.xError}
                                    onBlur={() => {
                                        if (topRightCorner.x === '') {
                                            setCoordinateError({...coordinateError, xError:'Please enter x-coordinate of top right corner'})
                                        } else {
                                            setCoordinateError({...coordinateError, xError:''})
                                        }
                                    }}
                                    style={{marginLeft:'8px', marginBottom:'8px'}}
                                />
                                <TextField
                                    error={!!coordinateError.yError}
                                    sx={sx}
                                    label='Top Right Corner Y-Coordinate'
                                    value={topRightCorner.y}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setTopRightCorner({...topRightCorner, y: e.target.value});
                                    }}
                                    size='small'
                                    helperText={coordinateError.yError}
                                    onBlur={() => {
                                        if (topRightCorner.y === '') {
                                            setCoordinateError({...coordinateError, yError:'Please enter y-coordinate of top right corner'})
                                        } else {
                                            setCoordinateError({...coordinateError, yError:''})
                                        }
                                    }}
                                    style={{marginLeft:'8px', marginBottom:'8px'}}
                                />
                                <TextField
                                    error={!!coordinateError.zError}
                                    sx={sx}
                                    label='Top Right Corner Z-Coordinate'
                                    value={topRightCorner.z}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setTopRightCorner({...topRightCorner, z: e.target.value});
                                    }}
                                    size='small'
                                    helperText={coordinateError.zError}
                                    onBlur={() => {
                                        if (topRightCorner.z === '') {
                                            setCoordinateError({...coordinateError, zError:'Please enter z-coordinate of top right corner'})
                                        } else {
                                            setCoordinateError({...coordinateError, zError:''})
                                        }
                                    }}
                                    style={{marginLeft:'8px'}}
                                />
                                <div
                                    style={{marginLeft:'8px', marginRight: '8px'}}
                                
                                >
                                    <Dropzone onDrop={onFileDrop} accept={{'application/sla': ['.stl']}}/>
                                </div>
                                {fileURL && (
                                    <div style={{width:'790px', border:'1px solid #6D77B0', borderRadius:'12px'}}>
                                        <STLDisplay 
                                            file={fileURL} 
                                            useWireFrame={false}
                                            width='770px'
                                            height='430px'    
                                        />
                                    </div>
                                )}
                            </Stack>
                        </fieldset>

                        <Button 
                            variant='contained' 
                            type='submit'
                            onClick={(e) => {
                                e.preventDefault();
                                addHubAttachment();
                            }}
                            style={{width:'344px', height:'45px', margin:'44px auto 30px auto'}}
                        >
                            REGISTER
                        </Button>
                    </Stack>
                </form>
            </div>
        </>
    );

}

export default AddHubAttachment;