import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';

import { validateEmail } from 'utils/validation';
import CircularSpinner from 'common/CircularSpinner';
import { Button, Stack, TextField } from '@mui/material';
import { post } from 'utils/api';
import { POST_API_URLS } from 'constants/apiUrls';


interface UserRegistrationProps {
    onRegistrationComplete: () => void,
}

export const UserRegistration = (props: UserRegistrationProps) => {
    const {onRegistrationComplete} = props;

    const {enqueueSnackbar} = useSnackbar();

    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>(''); 

    const [emailError, setEmailError] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const expireCallback = () => {
        navigate('/login');
    }

    const onEmailTextboxBlur = () => {
        if ( (email==='') || !validateEmail(email)){
          setEmailError('Please enter a valid email address');
        } else {
          setEmailError('');
        }
    }; 

    const onPasswordTextboxBlur = () => {
        if (password === '') {
            setPasswordError('Please enter secure password');
        } else if (password !== confirmPassword) {
            setPasswordError('Passwords must match');
        } else {
            setPasswordError('');
        }
    }

    const registerUser = () => {
        if(!email.match("[a-zA-Z0-9_\\.]+@[a-zA-Z0-9_]+\\.[a-zA-Z0-9_]+") || passwordError || password===''){
            enqueueSnackbar('Invalid email or password', {variant: 'error'});
            return;
        }

        const data = {
            'email': email,
            'password': password,
            'retyped_password': confirmPassword
        }

        setIsLoading(true);
        post(POST_API_URLS.USER_REGISTRATION(), data, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                onRegistrationComplete();
                enqueueSnackbar('Registered the new user successfully', {variant: 'success'});
            } else {
                enqueueSnackbar('Error while registering the user', {variant: 'error'});
              }
        }).catch( (res: AxiosError) => {
            enqueueSnackbar('Error while registering the user', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        });
    } 

    const sx = {m:1, width:'35ch'};

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <div 
                style={{width: '450px', border: '1px solid #707070', 
                boxShadow:'0px 3px 6px #00000029', borderRadius: '20px', backgroundColor: 'white', color: 'black'}}            
            >
                <form>
                    <Stack spacing={2} style={{marginLeft:'50px', marginRight: '50px', marginTop:'50px'}}>
                        <fieldset style={{width: '330px', height: '100%', border: '1px solid #6D77B0', borderRadius: '12px',
                            padding: '12px 6px 6px 6px', marginLeft:'auto', marginRight:'auto'}}
                        >
                            <legend style={{marginLeft: '17px'}}>User Registration</legend>
                            <TextField
                                error={!!emailError}
                                sx={sx}
                                label='Email'
                                value={email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setEmail(e.target.value);
                                }}
                                size='small'
                                helperText={emailError}
                                onBlur={onEmailTextboxBlur}
                                autoComplete='email'
                            />
                            <TextField
                                error={!!passwordError}
                                sx={sx}
                                label='Password'
                                type='password'
                                value={password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setPassword(e.target.value);
                                }}
                                size='small'
                                helperText={passwordError}
                                onBlur={onPasswordTextboxBlur}
                                autoComplete='new-password'
                            />
                            <TextField
                                error={!!passwordError}
                                sx={sx}
                                label='Confirm Password'
                                type='password'
                                value={confirmPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setConfirmPassword(e.target.value);
                                }}
                                size='small'
                                helperText={passwordError}
                                onBlur={onPasswordTextboxBlur}
                                autoComplete='new-password'
                            />
                        </fieldset>
                        <Button 
                            variant='contained' 
                            type='submit'
                            onClick={(e) => {
                                e.preventDefault();
                                registerUser();
                            }}
                            style={{width:'344px', margin:'44px auto 30px auto'}}
                        >
                            REGISTER
                        </Button>
                    </Stack>
                </form>
            </div>
        </>
    );


}

export default UserRegistration;