import { Grid } from '@mui/material';
import Visualizer from 'components/stlVisualizer/visualizer';

interface STLDisplayProps {
    file?: string,
    useWireFrame: boolean,
    width: string,
    height: string
}

export const STLDisplay = (props: STLDisplayProps) => {
    const {file, useWireFrame, width, height} = props;

    return (
        <Grid container spacing={1}  style={{width:width, height:height, margin:'10px'}}>
            <Visualizer cameraStart={[1000, 0, 0]} file={file} useWireFrame={useWireFrame}/>
        </Grid>
    )
}

export default STLDisplay;