import React, { useEffect } from "react";
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/MenuOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LoginIcon from '@mui/icons-material/Login';
import HelpIcon from '@mui/icons-material/Help';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useNavigate } from "react-router-dom"

import AthuleBarImage from 'static/img/athule-bar.png';
import { isLoggedIn, logout } from "utils/api";
import styles from 'static/css/navBar.module.css';

import Documentation from 'static/docs/documentation.pdf';

interface NavigationBarProps {
  showMenu: boolean
}

export const NavigationBar = (props: NavigationBarProps) => {
  const navigate = useNavigate();
  const loggedIn = isLoggedIn();
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchor);

  const {showMenu} = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const toProfile = (event: React.MouseEvent) => {
    navigate("/profile");
  }

  const toChangePassword = (event: React.MouseEvent) => {
    navigate("/password-change");
  }

  const toLogout = (event: React.MouseEvent) => {
    logout();
    navigate("/login");
  }

  const help = (event: React.MouseEvent) => {
   window.open(Documentation);
  }

  const toHelp = (event: React.MouseEvent) => {
    navigate("/help");
  }

  return (
    <>
      <div style={{
        width: "100%",
        height: "64px",
        position: "relative",
        backgroundColor: "black",
        padding: "0",
        display: "grid",
        gridTemplateColumns: "95% 5%",
        gridTemplateRows: "auto",
        overflow: "clip",
        clipPath: "content-box",
        alignContent: "right",
        zIndex: '400',
      }}>
        <img
          style={{
            gridColumn: "1 / span 1",
            height: "100%",
            overflow: "clip",
            objectFit: "cover",
          }}
          src={AthuleBarImage} alt='Athule Logo Bar' />
        {showMenu && (
          <>
            <IconButton
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              className={styles.default}
              style={{
                gridColumn: "2 / span 1",
                justifySelf: "right",
              }}>
              <MenuIcon sx={{ color: "white" }} />
            </IconButton>

            <Menu
              id="basic-menu"
              anchorEl={menuAnchor}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {loggedIn ? (
                [(<MenuItem onClick={toProfile} key="profile">
                  <AccountBoxIcon className={styles.dropdownIcon} />
                  Profile
                </MenuItem>),
                (<MenuItem onClick={toChangePassword} key="password">
                  <LockResetIcon className={styles.dropdownIcon} />
                  Change Password
                </MenuItem>),
                (<MenuItem onClick={toHelp} key="Help">
                <HelpIcon className={styles.dropdownIcon} />
                  Help
                </MenuItem>),
                (<MenuItem onClick={toLogout} key="logout">
                  <LogoutIcon className={styles.dropdownIcon} />
                  Logout
                </MenuItem>),
                ]
              ) : (
                [(<MenuItem onClick={toLogout} key="login">
                  <LoginIcon className={styles.dropdownIcon} />
                  Login
                </MenuItem>)]
              )}
            </Menu>
          </>
        )}
      </div>
    </>
  );
}

export default NavigationBar;
