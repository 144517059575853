import React, { Suspense, useEffect, useState} from "react";
import { Canvas } from '@react-three/fiber';
import Loader from './Loader';
import CameraControls from './Arcball';
import STLMesh from "./stlMesh";
import { Center, OrthographicCamera } from '@react-three/drei';
import { largestDimension } from './stlMesh';

interface VisualizerProps {
    height: string,
    width: string,
    cameraStart: [number, number, number],
    file?: string,
    useWireFrame?: boolean
}

function Visualizer(props: VisualizerProps) {

    const [largestDimensionValue, setLargestDimensionValue] = useState<number>(0);
    setTimeout(() => {setLargestDimensionValue(largestDimension)},1000);
    return (
        <div style={{
            display: "flex",
            height: props.height,
            width: props.width,
            backgroundColor:'#263238',
            opacity: '0.67'
        }}>
            <Canvas shadows="basic" >
                <OrthographicCamera
                    makeDefault
                    position={props.cameraStart} 
                    zoom={1/(((-7.0084*10**(-7))*(largestDimensionValue**2)) + (0.00113398*largestDimensionValue) + 0.00000679652)} 
                    matrixWorldAutoUpdate={true}
                    getObjectsByProperty={'id'}
                />
                <pointLight position={[0, 10, 0]} />
                <pointLight position={[0, -10, 0]} />
                <pointLight position={[0, 0, 50]}intensity={0.2} />
                <pointLight position={[0, 0, -50]}intensity={0.2} />
                <CameraControls />
                <Suspense fallback={<Loader />}>
                    <Center 
                        matrixWorldAutoUpdate={true} 
                        getObjectsByProperty={'id'}
                    >
                        <STLMesh file={props.file} useWireFrame={props.useWireFrame}/>
                    </Center>
                </Suspense>
                <axesHelper args={[40]}/>
            </Canvas >
        </div>
    )
}

Visualizer.defaultProps = {
    height: "100%",
    width: "100%",
    cameraStart: [0, 0, 100]
}

export default Visualizer;
