import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { Stack, Tooltip as MUITooltip, Typography, TextField, Divider, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, Button} from '@mui/material';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ViewInArRoundedIcon from '@mui/icons-material/ViewInArRounded';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import * as _ from 'lodash';
import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from 'common/NavigationBar';
import { Page, OffConditionAnalysisParameterOLD } from 'constants/enums';
import Sidebar, { MenuButton } from 'common/Sidebar';
import STLDisplay from 'components/Results/STLDisplay';
import WithAuthenticate from 'common/hoc/WithAuthenticate';
import { useLocation } from "react-router-dom";
import OutlinedInput from 'common/OutlinedInput';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { ResultFields } from 'components/Results/ResultFields';
import { getExperimentTitle } from 'utils/utils';
import RangeStepInput from 'common/RangeStepInput';
import {get, getBinary, post, postBinary} from 'utils/api';
import { GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import { useSnackbar } from 'notistack';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

export const SweepSingleExperiment = () => {
    const navigate = useNavigate();

    const params = useParams();
    const experimentId = parseInt(params['experimentId'] || '0');

    const [experiment_detail,set_experiment_detail] = useState<any>();
    const [selectedRPMSweeps, setSelectedRPMSweeps] = useState<Array<string>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [files, setFiles] = useState<any>([]);

    const goToComparisonTablePage = () => {
        navigate('/compare/results');
    }

    const colorList = ['#2196f3', '#f44336', '#4caf50', '#b56f00', '#008080'];
    const {enqueueSnackbar} = useSnackbar();
    const [sweepResult, setSweepResult] = useState([{
        rpm: [], thrust: [], torque: [], power: [], j: [], cp: [], ct: [], efficiency: [], vinf:[], altitude: []
    }]);

    const [sweepParameters, setSweepParameters] = useState({
        xAxis: OffConditionAnalysisParameterOLD.RPM, 
        pdpParam1: OffConditionAnalysisParameterOLD.OTHER,
        pdpParam2: OffConditionAnalysisParameterOLD.V_INF,
        pdpConstantValue: '',
        pdpConstantType: OffConditionAnalysisParameterOLD.ALTITUDE,
        onlyPDP: {
            isPDP: true,
            range1: {
                min: '',
                max: '',
                step: ''
            },
            range2: {
                min: '',
                max: '',
                step: ''
            }
        },
        sweep_param: {
            range: {
                min: '',
                max: '',
                step: ''
            }
        },
        experiment: experiment_detail

    });

    const smallSX = {m:1, width: '18ch'};
    const xAxisTypes = [
        {
            key: 1,
            value: 'RPM'
        },
        {
            key: 2,
            value: 'V∞ (ft/s)'
        },
        {
            key: 3,
            value: 'Altitude (ft)'
        },
        {
            key: 4,
            value: 'Thrust (lbf)'
        },
        {
            key: 5,
            value: 'Torque (lbfin)'
        },
        {
            key: 6,
            value: 'Power (W)'
        }

    ]
    const pdpTypes = [
        {
            key: 1,
            value: 'RPM'
        },
        {
            key: 2,
            value: 'V∞ (ft/s)'
        },
        {
            key: 3,
            value: 'Altitude (ft)'
        }

    ]

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(()=>{
        get(GET_API_URLS.EXPERIMENT(experimentId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                set_experiment_detail(res.data.experiment);
                console.log(res.data.experiment)
            } else {
                enqueueSnackbar("Error while loading the experiment's result", {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar("Error while loading the experiment's result", {variant: 'error'});
        })
        loadFiles();

    }, []);

    const validationCheck = (buttonPressed:string) => {
        if (buttonPressed=='Files'){
            return true;
        }
        let check = getLabelBasedOnSweepType(sweepParameters.xAxis);
        if (sweepParameters.sweep_param.range.min === '' || sweepParameters.sweep_param.range.max === '' || 
        ((check=='RPM'||check=='Altitude (ft)'||check=='V∞ (ft/s)') &&sweepParameters.sweep_param.range.step === '')) {
            enqueueSnackbar(`Fill up all the ${getLabelBasedOnSweepType(sweepParameters.xAxis)} fields`, {variant: 'error'});
            return false;
        } else if (sweepParameters.pdpParam1 !== OffConditionAnalysisParameterOLD.OTHER && (sweepParameters.onlyPDP.range1.min === '' || 
        sweepParameters.onlyPDP.range1.max === '' || 
        sweepParameters.onlyPDP.range1.step === '')) {
            enqueueSnackbar(`Fill up all the ${getLabelBasedOnSweepType(sweepParameters.pdpParam1)} fields`, {variant: 'error'});
            return false;
        } else if (sweepParameters.onlyPDP.range2.min === '' || 
        sweepParameters.onlyPDP.range2.max === '' || 
        sweepParameters.onlyPDP.range2.step === '') {
            enqueueSnackbar(`Fill up all the ${getLabelBasedOnSweepType(sweepParameters.pdpParam2)} fields`, {variant: 'error'});
            return false;
        } else if (sweepParameters.pdpConstantValue === '') {
            enqueueSnackbar(`Fill up the ${getLabelBasedOnPDPConstantType()} field`, {variant: 'error'});
            return false;
        } else if (sweepParameters.sweep_param.range.min === '' || sweepParameters.sweep_param.range.max === '' || (!(check=='RPM'||check=='Altitude (ft)'||check=='V∞ (ft/s)') &&sweepParameters.sweep_param.range.step === '')) {
            sweepParameters.sweep_param.range.step = '1'
            return true;
        } 

        return true

    }   

    const getInputData = (buttonPressed:string) => {
        const data = {
            xAxis: sweepParameters.xAxis, 
            pdpParam1: sweepParameters.pdpParam1,
            pdpParam2: sweepParameters.pdpParam2,
            pdpConstantValue: sweepParameters.pdpConstantValue,
            pdpConstantType: pdpTypes.filter(model => model.key !== sweepParameters.pdpParam1 
                && model.key !== sweepParameters.pdpParam2 
                && model.key !== sweepParameters.xAxis)[0].key,
            onlyPDP: {
                isPDP: true,
                range1: {
                    min: sweepParameters.onlyPDP.range1.min,
                    max: sweepParameters.onlyPDP.range1.max,
                    step: sweepParameters.onlyPDP.range1.step
                },
                range2: {
                    min: sweepParameters.onlyPDP.range2.min,
                    max: sweepParameters.onlyPDP.range2.max,
                    step: sweepParameters.onlyPDP.range2.step
                }
            },
            sweep_param: {
                range: {
                    min: sweepParameters.sweep_param.range.min,
                    max: sweepParameters.sweep_param.range.max,
                    step: sweepParameters.sweep_param.range.step
                }
            },
            selectedSweeps: selectedRPMSweeps,
            button: buttonPressed,
            experiment: experiment_detail
        }
            return data
    }

    const loadFiles = () => {
        setIsLoading(true);
        get(GET_API_URLS.RESULT_FILES(experimentId), expireCallback).then((res: AxiosResponse) => {
            if (res.status === 200) {
                setFiles(res.data.result_files.filter((file:any) => (file.filetype === 'TEST_STAND_RPM_SWEEP')));
            } else {
                enqueueSnackbar('Error while loading result files.', {variant: 'error'});
            }
        }).catch((err:AxiosError) => {
            enqueueSnackbar('Error while loading result files.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    };

    const sweepWithType = (buttonPressed:string) => {

        if (validationCheck(buttonPressed)) {
            
            const data = getInputData(buttonPressed)
            setSweepParameters({...sweepParameters, pdpConstantType: pdpTypes.filter(model => model.key !== sweepParameters.pdpParam1 
                && model.key !== sweepParameters.pdpParam2 
                && model.key !== sweepParameters.xAxis)[0].key})
            setIsLoading(true);
            post(POST_API_URLS.EXPERIMENT_OFF_CONDITION_ANALYSIS(experimentId), data, expireCallback, 6000000).then((res:AxiosResponse) => {
                if (res.status === 200) {
                    
                    setSweepResult(res.data['result'])
                    
                } else {
                    enqueueSnackbar('Error while loading the sweep', {variant: 'error'});
                }
            }).catch((res:AxiosError) => {
                enqueueSnackbar('Error while loading the sweep', {variant: 'error'});
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }

    const setSweepParamBasedOnXAxis = (value: string) => {

        let pdpParam1 = OffConditionAnalysisParameterOLD.OTHER
        let pdpParam2 = OffConditionAnalysisParameterOLD.OTHER

        if (value.toString() === OffConditionAnalysisParameterOLD.ALTITUDE.toString() || value.toString() === OffConditionAnalysisParameterOLD.V_INF.toString()) {
            pdpParam1 = OffConditionAnalysisParameterOLD.OTHER
            pdpParam2 = OffConditionAnalysisParameterOLD.RPM
        } else if (value.toString() === OffConditionAnalysisParameterOLD.RPM.toString()) {
            pdpParam1 = OffConditionAnalysisParameterOLD.OTHER
            pdpParam2 = OffConditionAnalysisParameterOLD.V_INF
        } else {
            pdpParam1 = OffConditionAnalysisParameterOLD.RPM
            pdpParam2 = OffConditionAnalysisParameterOLD.V_INF
        }

        setSweepParameters({...sweepParameters, xAxis: Number(value), pdpParam1: pdpParam1, pdpParam2: pdpParam2,
            pdpConstantType: pdpTypes.filter(model => model.key !== pdpParam1 
                && model.key !== pdpParam2 
                && model.key !== Number(value))[0].key,
            sweep_param: {...sweepParameters.sweep_param, range: {...sweepParameters.sweep_param.range, min: '', max: '', step: ''}},
            onlyPDP: {...sweepParameters.onlyPDP, range1: {...sweepParameters.onlyPDP.range1, min: '', max: '', step: ''}, 
            range2: {...sweepParameters.onlyPDP.range2, min: '', max: '', step: ''}} 
            })
        setSweepResult([{
                rpm: [], thrust: [], torque: [], power: [], j: [], cp: [], ct: [], efficiency: [], vinf:[], altitude: []
            }])

    }

    const getLabelBasedOnSweepType = (type: number) => {
        return xAxisTypes.filter((model => model.key === type))[0].value
    }

    const getLabelBasedOnPDPConstantType = () => {

        if (sweepParameters.pdpParam2 === OffConditionAnalysisParameterOLD.ALTITUDE) return 'Altitude (ft)'
        else return 'V∞ (ft/s)'

    }

    const getGraphLabelBasedOnPDPConstantType = () => {

        if (sweepParameters.pdpParam2 === OffConditionAnalysisParameterOLD.ALTITUDE) return 'Altitude (ft)'
        else if (sweepParameters.pdpParam2 === OffConditionAnalysisParameterOLD.V_INF) return 'V∞ (ft/s)'
        else return 'RPM'

    }

    const getParamForLabelGraph = () => {

        if (sweepParameters.pdpParam2 === OffConditionAnalysisParameterOLD.ALTITUDE) return 'altitude'
        else if (sweepParameters.pdpParam2 === OffConditionAnalysisParameterOLD.V_INF) return 'vinf'
        else return 'rpm'

    }

    const handleGraphs = (x: string, y: string) => {
        const graphData = {datasets: sweepResult.map((value:any, index: number) => ({
            label: value[getParamForLabelGraph()],
            data: value[x].map((x_value:any, idx:number) => ({
                x:Number(x_value).toFixed(3), 
                y:(value[y][idx])
            })
        ),
        borderColor: colorList[index%5],
        pointBackgroundColor: colorList[index%5],
        showLine: true,
        }))}

        return graphData
    };

    const getGraphOptions = (title: string, xLabel: string, yLabel: string) => ({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context:any) => {
                        return context.formattedValue;
                    }
                }
            },
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: title,
            },
          },
          scales: {
              x: {
                  display: true,
                  title: {
                      display: true,
                      text: xLabel,
                  },
              },
              y: {
                  display: true,
                  title: {
                      display: true,
                      text: yLabel
                  },
                  ticks: {
                    display: true,
                    autoSkip: false,
                    callback: (val:number | string) => {
                        if ((Number(val) < 0.01 && Number(val) > -0.01 && Number(val != 0)) || Number(val) >= 1000000){
                            return Number(val).toExponential(2);
                        }
                        else if (Number(val) >= 1000){
                            const formatter = new Intl.NumberFormat('en-US');
                            const formattedNumber = formatter.format(Number(val));
                            return formattedNumber
                        }
                        else {
                            return Number(val).toFixed(2);
                        }
                    }
                  }
              },
          }
      });

    const Menubar = () => {
        return (
            <></>
        );
    };

    const isPDPParamSelectedForXAxis = () => {
        return sweepParameters.xAxis === OffConditionAnalysisParameterOLD.RPM || 
        sweepParameters.xAxis === OffConditionAnalysisParameterOLD.ALTITUDE || 
        sweepParameters.xAxis === OffConditionAnalysisParameterOLD.V_INF
    }

    const labelForPDPConstantField = () => {

        return pdpTypes.filter(model => model.key !== sweepParameters.pdpParam1 && 
            model.key !== sweepParameters.pdpParam2 &&
            model.key !== sweepParameters.xAxis)[0].value
    }

    return (
        <>
        {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' spacing={3}>
            
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.RESULTS}/>
                </div>
                
            
                <Stack spacing={3} style={{marginBottom:'30px'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Off-Condition Performance Analysis
                    </Typography>
                
                    <Stack 
                        spacing={2} 
                        divider={<Divider orientation="vertical" flexItem />} 
                        direction='row'
                        justifyContent='center'
                    >
                        <Stack
                            spacing={2}
                            justifyContent='center'
                        >
                        <ResultFields 
                            title={getExperimentTitle(experiment_detail)}
                            efficiency={Number(_.get(experiment_detail, 'result.efficiency', '0'))}
                            shaftPower={Number(_.get(experiment_detail, 'result.other.power_shaft', '0'))}
                            thrust={Number(_.get(experiment_detail, 'result.other.thrust_n', []).reduce((sum:number, currentValue:number)=>sum+currentValue,0) / 4.4482216)}
                            torque={Number(_.get(experiment_detail, 'result.other.torque_n', []).reduce((sum:number, currentValue:number)=>sum+currentValue,0) * 8.8507457673787)}
                            finalChord={Number(_.get(experiment_detail, 'result.chord', '0'))}
                            finalRPM={Number(_.get(experiment_detail, 'result.rpm', '0'))}
                            maxMachValue={Number(_.get(experiment_detail, 'result.other.max_mach', '0'))}
                        />
                        <FormControl sx={smallSX} variant="outlined" >
                            <InputLabel
                                id='rpm-sweep-file-label'
                                style={{marginLeft: '9px'}}
                                shrink={true}
                            >
                                Test Stand Results
                            </InputLabel>
                            <Select
                                id='rpm-sweep-file-select'
                                labelId='rpm-sweep-file-label'
                                label='Test Stand Results'
                                notched={true}
                                multiple = {true}
                                value={selectedRPMSweeps}
                                onChange={(e) => {
                                    const value = _.union(e.target.value,[]);
                                    setSelectedRPMSweeps(value);
                                }}
                                size='small'
                                style={{height:'43px',width:'355px'}}
                            >
                                {files.map((value:any) => {
                                    if (value['filetype'] == 'TEST_STAND_RPM_SWEEP'){
                                        return <MenuItem value={value['id']}>{value['filename'].toString()}</MenuItem>
                                    }
                                })}
                            </Select>
                        </FormControl>
                        </Stack>
                    </Stack>
                    <Stack spacing={2}>
                        <Stack spacing={2} alignItems='center'>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    sweepWithType('Files');
                                }}
                                style={{marginLeft: '9px', width:'355px'}}
                                disabled={selectedRPMSweeps.length?false:true}
                            >
                                Load Files
                            </Button>
                        </Stack>
                        <Stack direction='row' spacing={2}>
                            <FormControl sx={smallSX} variant="outlined">
                                                <InputLabel
                                                    id='xAxis-label'
                                                    style={{marginLeft: '9px'}}
                                                >
                                                   X Axis
                                                </InputLabel>
                                                <Select
                                                    id='xAxis'
                                                    label='X Axis'
                                                    value={sweepParameters.xAxis.toString()}
                                                    onChange={(e: SelectChangeEvent<string>, child: React.ReactNode) => {

                                                      setSweepParamBasedOnXAxis(e.target.value)
                                                        
                                                
                                                    }}
                                                    size='small'
                                                    style={{height:'43px', marginLeft: '9px'}}
                                                >
                                                    {xAxisTypes.filter(model => model.key !== sweepParameters.pdpParam2).map((model, key) => {
                                                        return <MenuItem key={key} value={model.key}>{model.value}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <RangeStepInput
                                                            label={xAxisTypes.filter(value => value.key === sweepParameters.xAxis)[0] ? xAxisTypes.filter(value => value.key === sweepParameters.xAxis)[0].value : 'X Axis'}
                                                            lowerLimit={sweepParameters.sweep_param.range.min}
                                                            onLowerLimitChange={(newRPMLower) => {
                                                                setSweepParameters({...sweepParameters,
                                                                sweep_param: {...sweepParameters.sweep_param, range: {...sweepParameters.sweep_param.range, min: newRPMLower}}})
                                                            }}
                                                            upperLimit={sweepParameters.sweep_param.range.max}
                                                            onUpperLimitChange={(newRPMUpper) => {
                                                                setSweepParameters({...sweepParameters,
                                                                sweep_param: {...sweepParameters.sweep_param, range: {...sweepParameters.sweep_param.range, max: newRPMUpper}}})
                                                            }}
                                                            tooltip='Step size of the range' 
                                                            step={sweepParameters.sweep_param.range.step}
                                                            onStepChange={(newRPMStep) => {
                                                                setSweepParameters({...sweepParameters,
                                                                sweep_param: {...sweepParameters.sweep_param, range: {...sweepParameters.sweep_param.range, step: newRPMStep}}})
                                                            }}                                
                                                            
                                        />
                        </Stack>
                        {!isPDPParamSelectedForXAxis() && <Stack direction='row' spacing={2}>
                            <FormControl sx={smallSX} variant="outlined">
                                                <InputLabel
                                                    id='pdp-param-label-1'
                                                    style={{marginLeft: '9px'}}
                                                >
                                                   Secondary Parameter
                                                </InputLabel>
                                                <Select
                                                    id='pdpParam1'
                                                    label='Secondary Parameter'
                                                    value={sweepParameters.pdpParam1.toString()}
                                                    onChange={(e: SelectChangeEvent<string>, child: React.ReactNode) => {
                                                        if (sweepParameters.xAxis === OffConditionAnalysisParameterOLD.RPM || sweepParameters.pdpParam2 === OffConditionAnalysisParameterOLD.RPM) {
                                                            setSweepParameters({...sweepParameters, pdpParam1: Number(e.target.value), pdpConstantValue: ''})
                                                        } else {
                                                            enqueueSnackbar('RPM should be selected as one of the parameters', {variant: 'error'});
                                                        }

                                                    }}
                                                    size='small'
                                                    style={{height:'43px', marginLeft: '9px'}}
                                                >
                                                     {pdpTypes.filter(model => model.key !== sweepParameters.pdpParam2).map((model, key) => {
                                                        return <MenuItem key={key} value={model.key}>{model.value}</MenuItem>
                                                    })}
                                                    
                                                </Select>
                                            </FormControl>
                                            <RangeStepInput
                                                    label={pdpTypes.filter(value => value.key === sweepParameters.pdpParam1)[0] ? pdpTypes.filter(value => value.key === sweepParameters.pdpParam1)[0].value : 'Parameter Range'}
                                                    lowerLimit={sweepParameters.onlyPDP.range1.min}
                                                    onLowerLimitChange={(newLower) => {
                                                        setSweepParameters({...sweepParameters,
                                                        onlyPDP: {...sweepParameters.onlyPDP, range1: {...sweepParameters.onlyPDP.range1, min: newLower}}})
                                                    }}
                                                    upperLimit={sweepParameters.onlyPDP.range1.max}
                                                    onUpperLimitChange={(newUpper) => {
                                                        setSweepParameters({...sweepParameters,
                                                        onlyPDP: {...sweepParameters.onlyPDP, range1: {...sweepParameters.onlyPDP.range1, max: newUpper}}})
                                                    }}
                                                    tooltip='Step size of the range' 
                                                    step={sweepParameters.onlyPDP.range1.step}
                                                    onStepChange={(newStep) => {
                                                        setSweepParameters({...sweepParameters,
                                                        onlyPDP: {...sweepParameters.onlyPDP, range1: {...sweepParameters.onlyPDP.range1, step: newStep}}})
                                                    }}/>

                                    </Stack>
                                    
                        }
                        {sweepParameters.onlyPDP.isPDP && <Stack direction='row' spacing={2}>
                            <FormControl sx={smallSX} variant="outlined">
                                                <InputLabel
                                                    id='pdp-param-label-2'
                                                    style={{marginLeft: '9px'}}
                                                >
                                                   Secondary Parameter
                                                </InputLabel>
                                                <Select
                                                    id='pdpParam2'
                                                    label='Secondary Parameter'
                                                    value={sweepParameters.pdpParam2.toString()}
                                                    onChange={(e: SelectChangeEvent<string>, child: React.ReactNode) => {

                                                        if (sweepParameters.xAxis === OffConditionAnalysisParameterOLD.RPM || sweepParameters.pdpParam1 === OffConditionAnalysisParameterOLD.RPM) {
                                                            setSweepParameters({...sweepParameters, pdpParam2: Number(e.target.value), pdpConstantValue: ''})
                                                        } else {
                                                            enqueueSnackbar('RPM should be selected as one of the parameters', {variant: 'error'});
                                                        }

                                                    }}
                                                    size='small'
                                                    style={{height:'43px', marginLeft: '9px'}}
                                                >
                                                     {pdpTypes.filter(model => isPDPParamSelectedForXAxis() ? model.key !== sweepParameters.xAxis : model.key !== sweepParameters.pdpParam1).map((model, key) => {
                                                        return <MenuItem key={key} value={model.key}>{model.value}</MenuItem>
                                                    })}
                                                    
                                                </Select>
                                            </FormControl>
                                            <RangeStepInput
                                                    label={pdpTypes.filter(value => value.key === sweepParameters.pdpParam2)[0] ? pdpTypes.filter(value => value.key === sweepParameters.pdpParam2)[0].value : 'Parameter Range'}
                                                    lowerLimit={sweepParameters.onlyPDP.range2.min}
                                                    onLowerLimitChange={(newLower) => {
                                                        setSweepParameters({...sweepParameters,
                                                        onlyPDP: {...sweepParameters.onlyPDP, range2: {...sweepParameters.onlyPDP.range2, min: newLower}}})
                                                    }}
                                                    upperLimit={sweepParameters.onlyPDP.range2.max}
                                                    onUpperLimitChange={(newUpper) => {
                                                        setSweepParameters({...sweepParameters,
                                                        onlyPDP: {...sweepParameters.onlyPDP, range2: {...sweepParameters.onlyPDP.range2, max: newUpper}}})
                                                    }}
                                                    tooltip='Step size of the range' 
                                                    step={sweepParameters.onlyPDP.range2.step}
                                                    onStepChange={(newStep) => {
                                                        setSweepParameters({...sweepParameters,
                                                        onlyPDP: {...sweepParameters.onlyPDP, range2: {...sweepParameters.onlyPDP.range2, step: newStep}}})
                                                    }}/>

                                    </Stack>
                                    
                        }
                        <Stack direction='row' spacing={2}>
                        {<OutlinedInput
                                            label={labelForPDPConstantField()}
                                            value={sweepParameters.pdpConstantValue}
                                            onValueChange={(newValue: string) => {
                                                setSweepParameters({
                                                    ...sweepParameters, 
                                                    pdpConstantValue: newValue
                                                });
                                            }}
                                            readOnly={false}
                                            style={{width:'180px', height: '48px', marginLeft: '10px'}}
                                            tooltip=""
                                        />}
                        </Stack>
                        <Stack alignItems='center'>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    sweepWithType('Sweep');
                                }}
                                style={{marginTop: '38px', width:'355px'}}
                            >
                                COMPARE
                            </Button>
                        </Stack>
                        
                        
                    </Stack>
                    <Stack direction='row' spacing={2}>
                    {(sweepParameters.xAxis === OffConditionAnalysisParameterOLD.RPM || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.ALTITUDE || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.V_INF) && 
                        <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Thrust (lbf) vs ${getLabelBasedOnSweepType(sweepParameters.xAxis)} for different ${getGraphLabelBasedOnPDPConstantType()}`, 
                                getLabelBasedOnSweepType(sweepParameters.xAxis), 'Thrust (lbf)')} 
                                data={handleGraphs(OffConditionAnalysisParameterOLD[sweepParameters.xAxis].toLowerCase(), 'thrust')}
                            />
                        </fieldset>}
                        
                        {(sweepParameters.xAxis === OffConditionAnalysisParameterOLD.THRUST || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.TORQUE || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.POWER) && 
                        <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`RPM vs ${getLabelBasedOnSweepType(sweepParameters.xAxis)} for different ${getGraphLabelBasedOnPDPConstantType()}`, 
                                getLabelBasedOnSweepType(sweepParameters.xAxis), 'RPM')} 
                                data={handleGraphs(OffConditionAnalysisParameterOLD[sweepParameters.xAxis].toLowerCase(), 'rpm')}
                            />
                        </fieldset>}

                        {(sweepParameters.xAxis === OffConditionAnalysisParameterOLD.THRUST || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.TORQUE || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.POWER || 
                        sweepParameters.xAxis === OffConditionAnalysisParameterOLD.RPM || sweepParameters.xAxis == OffConditionAnalysisParameterOLD.ALTITUDE || sweepParameters.xAxis == OffConditionAnalysisParameterOLD.V_INF) && 
                        <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Efficiency vs ${getLabelBasedOnSweepType(sweepParameters.xAxis)} for different ${getGraphLabelBasedOnPDPConstantType()}`, 
                                getLabelBasedOnSweepType(sweepParameters.xAxis), 'Efficiency')}
                                data={handleGraphs(OffConditionAnalysisParameterOLD[sweepParameters.xAxis].toLowerCase(), 'efficiency')}
                            />
                        </fieldset>}

                    </Stack>
                    <Stack direction='row' spacing={2}>
                    {(sweepParameters.xAxis === OffConditionAnalysisParameterOLD.RPM || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.ALTITUDE || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.V_INF) && 
                    <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Power (W) vs ${getLabelBasedOnSweepType(sweepParameters.xAxis)} for different ${getGraphLabelBasedOnPDPConstantType()}`, 
                                getLabelBasedOnSweepType(sweepParameters.xAxis), 
                                'Power (W)')} 
                                data={handleGraphs(OffConditionAnalysisParameterOLD[sweepParameters.xAxis].toLowerCase(), 'power')}
                            />
                        </fieldset>}
                        {(sweepParameters.xAxis === OffConditionAnalysisParameterOLD.RPM || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.ALTITUDE || sweepParameters.xAxis === OffConditionAnalysisParameterOLD.V_INF) && 
                        <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Torque (lbfin) vs ${getLabelBasedOnSweepType(sweepParameters.xAxis)} for different ${getGraphLabelBasedOnPDPConstantType()}`, 
                                getLabelBasedOnSweepType(sweepParameters.xAxis), 
                                'Torque (lbfin)')}
                                data={handleGraphs(OffConditionAnalysisParameterOLD[sweepParameters.xAxis].toLowerCase(), 'torque')}
                            />
                        </fieldset>}
                    </Stack>
                    {(sweepParameters.pdpParam2 !== OffConditionAnalysisParameterOLD.RPM) && <Stack direction='row' spacing={2}>
                     <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`CP vs J`, 'J', 'CP')} 
                                data={handleGraphs('j', 'cp')}
                            />
                        </fieldset>
                    <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`CT vs J`, 'J', 'CT')} 
                                data={handleGraphs('j', 'ct')}
                            />
                        </fieldset>
                   
                    </Stack>}
                    {(sweepParameters.pdpParam2 !== OffConditionAnalysisParameterOLD.RPM) && <Stack direction='row' spacing={2}>
        
                   <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Efficiency vs J`, 'J', 'Efficiency')} 
                                data={handleGraphs('j', 'efficiency')}
                            />
                    </fieldset>
                    </Stack>}
                </Stack>
            </Stack>
        </>
    );
}

export default WithAuthenticate(SweepSingleExperiment);
