import React, {useState} from 'react';
import { TextField, Tooltip } from '@mui/material';

interface OutlinedInputProps {
    label: string,
    value: string,
    onValueChange: (newValue: string) => void,
    readOnly: boolean,
    style: object,
    tooltip: string,
    onBlurChange: (newValue: string) => void,
    autoComplete: string
}

export const OutlinedInput = (props: OutlinedInputProps) => {
    const {label, value, onValueChange, onBlurChange, readOnly, style, tooltip, autoComplete} = props;

    return (
        <Tooltip title={tooltip} arrow>
            <fieldset 
                style={{
                    ...style,
                    border: '1px solid #00000040', 
                    borderRadius:'5px', 
                    padding:'0px 14px 2px 14px', 
                    }}
                >
                <legend 
                    style={{
                        marginLeft:'5px', 
                        color:'#00000099', 
                        fontSize: '14px', 
                        fontFamily:'"Roboto","Helvetica","Arial",sans-serif'
                    }}
                >
                    {label}
                </legend>
                <TextField
                    label=''
                    variant='standard'
                    value={value}
                    onChange={(e) => onValueChange(e.target.value)}
                    onBlur={(e) => onBlurChange(e.target.value)}
                    InputProps={{readOnly:readOnly}}
                    style={{width:'100%'}}
                    autoComplete={autoComplete}
                />
            </fieldset>
        </Tooltip>
    );
}
OutlinedInput.defaultProps = {
    onBlurChange: () => {},
    autoComplete: 'off'
}

export default OutlinedInput;