import React, {useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import {useNavigate, useParams} from 'react-router-dom';
import { DataGrid, GridColDef, GridComparatorFn } from '@mui/x-data-grid';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { get, isLoggedIn, put, del } from 'utils/api';
import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from "common/NavigationBar";
import { Dialog, DialogContent, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Page, UserTaskStatus } from 'constants/enums';
import Sidebar, { MenuButton } from 'common/Sidebar';
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';
import { DELETE_API_URLS, GET_API_URLS, POST_API_URLS, PUT_API_URLS } from 'constants/apiUrls';
import { AxiosError, AxiosResponse } from 'axios';
import { dateComparator } from 'utils/utils';
import { AddCFDAnalysis } from './AddCFDAnalysis';
import TaskStatus from 'common/TaskStatus';


export const CFDAnalysisDashboard = () => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const params = useParams();
    const experimentId = parseInt(params['experimentId'] || '0');

    const [cfdAnalysisTasks, setCFDAnalysisTasks] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCFDAnalysisRegistrationOpen, setIsCFDAnalysisRegistrationOpen] = useState<boolean>(false);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null); 
    const [pageSize, setPageSize] = useState<number>(25);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/login');
            return;
        }

        loadCFDAnalysisTasks(true);
        const intervalId = setInterval(() => loadCFDAnalysisTasks(false), 10000);

        return () => clearInterval(intervalId);
    }, []);

    const loadCFDAnalysisTasks = (showLoader: boolean) => {
        if (showLoader) {
            setIsLoading(true);
        }
        get(GET_API_URLS.CFD_ANALYSIS_TASKS(experimentId), expireCallback).then((res:AxiosResponse) => {
            setCFDAnalysisTasks(res.data.cfd_analysis_tasks);
        }).catch((err: AxiosError) => {
            enqueueSnackbar('Error while loading CFD analysis tasks information', {variant: 'error'});
        }).finally(() => {
            if (showLoader) {
                setIsLoading(false);
            }
        })
    }

    const cancelCFDAnalysisTask = (userTaskId: number) => {
        setIsLoading(true);
        setConfirmationProps(null);
        put(PUT_API_URLS.USER_TASK_CANCEL(userTaskId), {}, expireCallback).then((res:AxiosResponse) => {
            enqueueSnackbar('Canceled the CFD analysis task successfully', {variant: 'success'})
            loadCFDAnalysisTasks(true);
        }).catch((err: AxiosError) => {
            enqueueSnackbar('Error while loading CFD analysis tasks information', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const deleteCFDAnalysisTask = (userTaskId: number) => {
        setConfirmationProps(null);
        setIsLoading(true);
        del(DELETE_API_URLS.USER_TASK_DELETE(userTaskId), expireCallback).then((res:AxiosResponse) => {
            enqueueSnackbar('Canceled the CFD analysis task successfully', {variant: 'success'})
            loadCFDAnalysisTasks(true);
        }).catch((err: AxiosError) => {
            enqueueSnackbar('Error while loading CFD analysis tasks information', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const columns: GridColDef[] = [
        {field: 'email', headerName: 'User Email', width: 150, align: 'left', headerAlign: 'left', editable: false, type: 'string'},
        {field: 'status', headerName: 'Status', width: 90, align: 'left', headerAlign: 'left', editable: false, 
            type: 'singleSelect', valueOptions: [UserTaskStatus.PENDING, UserTaskStatus.STARTED, UserTaskStatus.SUCCESS, UserTaskStatus.FAILURE, UserTaskStatus.USER_CANCELED], 
            renderCell: (params) => {
                return (
                    <TaskStatus status={params.row.status}/>
                );
            }
        },
        {field: 'atmosphere', headerName: 'Atmosphere', width: 120, align: 'left', headerAlign: 'left', editable: false, 
            type: 'string', valueGetter: (params) => {
                if (params.row.additional_info.atmosphere === 'STANDARD') {
                    return 'Standard';
                }

                return params.row.additional_info.atmosphere;
            }},
        {field: 'altitude', headerName: 'Altitude (ft)', width: 95, align: 'left', headerAlign: 'left', editable: false, 
            type: 'number', valueGetter: params => params.row.additional_info.altitude},
        {field: 'pressure', headerName: 'Pressure (Pa)', width: 110, align: 'left', headerAlign: 'left', editable: false, 
            type: 'number', valueGetter: params => params.row.additional_info.pressure},
        {field: 'density', headerName: 'Density (kg/m^3)', width: 130, align: 'left', headerAlign: 'left', editable: false, 
            type: 'number', valueGetter: params => params.row.additional_info.density},
        {field: 'viscosity', headerName: 'Viscosity (kg/m*s)', flex: 1, align: 'left', headerAlign: 'left', editable: false, 
            type: 'number', valueGetter: params => params.row.additional_info.viscosity, valueFormatter: ({value}) => value.toString()},
        {field: 'freestream_velocity', headerName: 'V∞ (ft/s)', width: 75, align: 'left', headerAlign: 'left', editable: false, 
            type: 'number', valueGetter: params => params.row.additional_info.freestream_velocity},
        {field: 'rpm', headerName: 'RPM', width: 70, align: 'left', headerAlign: 'left', editable: false, 
            type: 'number', valueGetter: params => params.row.additional_info.rpm},
        {field: 'start_time', headerName: 'Start Time', width: 160, align: 'left', headerAlign: 'left', editable: false, 
            type: 'date', renderCell: (params) => {
                const date = params.row.start_time;
                if (date == null) {
                    return <></>;
                }

                const formattedDate = new Date(date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                });
                return ( 
                    <div>{formattedDate}</div>
                );
            }, sortComparator: dateComparator},
        {field: 'end_time', headerName: 'End Time', width: 160, align: 'left', headerAlign: 'left', editable: false, 
            type: 'date', renderCell: (params) => {
                const date = params.row.end_time;
                if (date == null) {
                    return <></>;
                }

                const formattedDate = new Date(date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                });
                return ( 
                    <div>{formattedDate}</div>
                );
            }, sortComparator: dateComparator},
        {field: 'View', align: 'left', headerAlign: 'left', headerName: 'View', width: 60, sortable: false,
            renderCell: (params) => {
                const onClick = (e:any) => {
                    e.stopPropagation();
                    navigate(`/analysis/${experimentId}/cfd/${params.row.id}/`);
                } 
                return (
                    <Tooltip title='View'>
                        <IconButton onClick={onClick}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                );
            }
        },
        {field: 'cancel_or_delete', align: 'left', headerAlign: 'left', headerName: 'Cancel/Delete', width: 110, sortable: false,
            renderCell: (params) => {
                if (['PENDING', 'STARTED'].includes(params.row.status)) {
                    const onCancel = (e:any) => {
                        e.stopPropagation();
                        setConfirmationProps({
                            title: 'Cancel CFD Analysis',
                            body: 'Do you want to cancel the CFD analysis?',
                            onCancel: () => setConfirmationProps(null),
                            onConfirm: () => cancelCFDAnalysisTask(parseInt(params.row.id))
                        });
                    }
                    
                    return (
                        <Tooltip title='Cancel'>
                            <IconButton onClick={onCancel} color='error'>
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                    );
                } else {
                    const onDelete = (e:any) => {
                        e.stopPropagation();
                        setConfirmationProps({
                            title: 'Delete CFD Analysis',
                            body: 'Do you want to delete the CFD analysis?',
                            onCancel: () => setConfirmationProps(null),
                            onConfirm: () => deleteCFDAnalysisTask(parseInt(params.row.id))
                        });
                    }
                    
                    return (
                        <Tooltip title='Delete'>
                            <IconButton onClick={onDelete} color='error'>
                                <DeleteForeverOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    );
                }
            }
        }
    ];

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop:'30px', marginBottom:'20px'}}>
                <MenuButton
                    Icon={AddCircleOutlineIcon}
                    iconColor='black'
                    label='New'
                    onClick={() => setIsCFDAnalysisRegistrationOpen(true)}
                    disabled={false}
                />
            </Stack>
        );
    };

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{width:'1550px'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.RESULTS}/>
                </div>
                <Stack spacing={3} style={{paddingLeft: '30px', width: '100%'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        CFD Analysis
                    </Typography>
                    <DataGrid
                            autoHeight
                            columns={columns}
                            rows={cfdAnalysisTasks}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
                            sx={{
                                "& .MuiDataGrid-cell": {
                                padding: '2px 10px'
                                }
                            }}
                        />
                </Stack>
            </Stack>
            <Dialog
                open={isCFDAnalysisRegistrationOpen}
                onClose={() => setIsCFDAnalysisRegistrationOpen(false)}
                PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      maxWidth: '1000px'
                    }
                }}
            >
                <DialogContent style={{backgroundColor: 'transparent'}}>
                    <AddCFDAnalysis 
                        experimentId={experimentId}
                        onRegistrationComplete={() => {
                            loadCFDAnalysisTasks(true);
                            setIsCFDAnalysisRegistrationOpen(false);
                        }}
                    />
                </DialogContent>
            </Dialog>
            {confirmationProps && <ConfirmationDialog {...confirmationProps}/>}
        </>
    );
}

export default CFDAnalysisDashboard;