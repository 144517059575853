import React, {useState} from 'react';
import { Checkbox, Stack, TextField, Typography, Tooltip } from '@mui/material';

interface RangeInputProps {
    label: string,
    lowerLimit: string,
    upperLimit: string,
    step: string,
    onLowerLimitChange: (lowerLimit:string) => void,
    onUpperLimitChange: (upperLimit:string) => void,
    onStepChange: (step:string) => void,
    tooltip: string,
}

export const RangeStepInput = (props: RangeInputProps) => {
    const {label, lowerLimit, upperLimit, onLowerLimitChange, onUpperLimitChange, onStepChange, tooltip, step} = props;

    return (
        <Tooltip title={tooltip} arrow>
            <Stack spacing={2}>
                    <fieldset 
                        style={{
                            border: '1px solid #00000040', 
                            borderRadius:'5px', 
                            padding:'0px 14px 2px 14px', 
                            width:'calc(100% - 34px)'
                            }}
                        >
                        <legend 
                            style={{
                                marginLeft:'5px', 
                                color:'#00000099', 
                                fontSize: '14px', 
                                fontFamily:'"Roboto","Helvetica","Arial",sans-serif'
                            }}
                        >
                            {label}
                        </legend>
                        
                        <Stack direction='row' spacing={2}>
                            <TextField 
                                label=''
                                variant='standard'
                                value={lowerLimit}
                                onChange={(e) => {
                                    onLowerLimitChange(e.target.value);
                                }}
                                autoComplete='off'
                            />
                            <Typography style={{marginTop:'2px'}}>to</Typography>
                            <TextField 
                                label=''
                                variant='standard'
                                value={upperLimit}
                                onChange={(e) => {
                                    onUpperLimitChange(e.target.value);
                                }}
                                autoComplete='off'
                            />
                            {(label=='RPM'||label=='Altitude (ft)'||label=='V∞ (ft/s)')&&<Typography style={{marginTop:'2px'}}>step</Typography>}
                            {(label=='RPM'||label=='Altitude (ft)'||label=='V∞ (ft/s)')&&<TextField 
                                label=''
                                variant='standard'
                                value={step}
                                onChange={(e) => {
                                    onStepChange(e.target.value);
                                }}
                                autoComplete='off'
                            />}
                        </Stack>
                        
                    </fieldset>
            </Stack>
        </Tooltip>
    );
}

export default RangeStepInput;