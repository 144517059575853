// Constants
const g = 9.80665;   // Acceleration due to gravity [m/s^2]
const R = 287.053;   // Specific gas constant for air [J/(kg*K)]
const gamma = 1.4;   // Ratio of specific heats for air
const mu0 = 1.458e-6; // Reference viscosity [Pa*s]
const p0s = 101325;  // Sea level standard pressure [Pa]
const a0 = Math.sqrt(gamma * R * 288.15); // Speed of sound at sea level standard conditions [m/s]

// Standard temperature lapse rates
const Lh = -0.0065;  // Troposphere [K/m]
const Lh2 = 0;       // Lower stratosphere [K/m]
const Lh4 = 0.003;   // Upper stratosphere and lower mesosphere [K/m]
const Lh5 = 0.0045;  // Upper mesosphere [K/m]


const T1 = 216.65;   // Tropopause temperature [K]
const T2 = 216.65;   // Lower stratosphere temperature [K]
const T3 = 228.65;   // Upper stratosphere and lower mesosphere temperature [K]
const T4 = 270.65;   // Upper mesosphere temperature [K]
const T5 = 270.65;   // Thermosphere temperature [K]

// Standard pressure levels
const p0 = 101325;   // Sea level standard pressure [Pa]
const p1 = 22632.06; // Tropopause pressure [Pa]
const p2 = 2489.64;  // Lower stratosphere pressure [Pa]
const p3 = 120.68;   // Upper stratosphere and lower mesosphere pressure [Pa]
const p4 = 1.84;     // Upper mesosphere pressure [Pa]
const p5 = 0.056;    // Thermosphere pressure [Pa]

// Calculate atmosphere properties at a given altitude
export function calculateAtmosphere(atmosphereType, h) {

  h = h / 3.2808 // converting ft to m

  // Standard temperature levels
  let T0 = 288.15;   // Sea level standard temperature [K]
  
  if (atmosphereType === 'HOT') {
    T0 = 312.75
  } else if (atmosphereType === 'COLD') {
    T0 = 222.59
  }

  console.log(h)

  // Calculate temperature
  let T;
  if (h < 11000) {
    T = T0 + Lh * (h - 0);
  } else if (h < 25000) {
    T = T1;
  } else if (h < 47000) {
    T = T2 + Lh2 * (h - 25000);
  } else if (h < 53000) {
    T = T3;
  } else if (h < 79000) {
    T = T4 - Lh4 * (h - 53000);
  } else {
    T = T5 - Lh5 * (h - 79000);
  }

  // Calculate pressure
  let p;
  if (h < 11000) {
    p = p0 * Math.pow(1 + (Lh * h) / T0, -g / (R * Lh));
  } else if (h < 25000) {
    p = p1 * Math.exp(-g * (h - 11000) / (R * T1));
  } else if (h < 47000) {
    p = p2 * Math.pow(1 + (Lh2 * (h - 25000)) / T2, -g / (R * Lh2));
  } else if (h < 53000) {
    p = p3 * Math.pow(1 + (Lh4 * (h - 47000)) / T3, -g / (R * Lh4));
  } else if (h < 79000) {
    p = p4 * Math.exp(-g * (h - 53000) / (R * T4));
  } else {
    p = p5 * Math.pow(T5 / (T5 + Lh5 * (h - 79000)), g / (R * Lh5));
  }

  // Calculate density
  const rho = p / (R * T);

  // Calculate viscosity
  const mu = (mu0 * Math.pow(T, 1.5)) / (T + 110.4);

  // Calculate speed of sound
  const a = Math.sqrt(gamma * R * T);

  return {
    pressure: p.toFixed(5),
    rho: rho.toFixed(5),
    mu: mu.toFixed(9),
    a: a.toFixed(4),
    temperature: T
  };
}