import React, {useCallback, useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, TextField } from '@mui/material';
import * as _ from 'lodash';
import { Scatter } from 'react-chartjs-2';
import { AxiosError, AxiosResponse } from 'axios';

import CircularSpinner from 'common/CircularSpinner';
import Dropzone from 'common/Dropzone';
import { post, put } from 'utils/api';
import { POST_API_URLS, PUT_API_URLS } from 'constants/apiUrls';


export interface IAirfoil {
    id: number,
    name: string,
    points: Array<IPoints>,
    interpolatedPoints: Array<IPoints>
}

export interface IPoints {
    x: number,
    y: number
}

interface AddAirfoilProps {
    onRegistrationComplete: () => void,
    airfoil: IAirfoil | null
}

export const AddAirfoil = (props: AddAirfoilProps) => {
    const {onRegistrationComplete, airfoil} = props;

    const {enqueueSnackbar} = useSnackbar(); 
    const navigate = useNavigate();

    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [originalPoints, setOriginalPoints] = useState<Array<IPoints>>([]);
    const [interpolatedPoints, setInterpolatedPoints] = useState<Array<IPoints>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (airfoil !== null) {
            setName(airfoil.name);
            setOriginalPoints(airfoil.points);
            setInterpolatedPoints(airfoil.points);
        }
    }, [airfoil]);

    const expireCallback = () => {
        navigate('/login');
    }

    const onNameTextboxBlur = () => {
        if (name === ''){
            setNameError('Please enter a unique airfoil name');
        } else {
            setNameError('');
        }
    }

    const addAirfoil = () =>  {
        if (name === '') {
            enqueueSnackbar('Please enter a unique airfoil name', {variant: 'error'});
            return;
        } else if (interpolatedPoints.length === 0) {
            enqueueSnackbar('Please upload airfoil points text file', {variant: 'error'});
            return;
        }

        setIsLoading(true);

        const data = {
            'name': name,
            'points': originalPoints.map(point => ([point.x, point.y]))
        };

        const apiCall = () => (airfoil===null)?post(POST_API_URLS.AIRFOIL_ADD(), data, expireCallback):
            put(PUT_API_URLS.AIRFOIL_UPDATE(airfoil.id), data, expireCallback)

        apiCall().then((res: AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Added the airfoil successfully', {variant: 'success'});
                onRegistrationComplete();
            } else {
                enqueueSnackbar('Error while adding the airfoil', {variant: 'error'});
            }
        }).catch((err: AxiosError) => {
            enqueueSnackbar('Error while adding the airfoil', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })

    }

    const onFileDrop = useCallback((acceptedFile: Array<File>) => {
        if (acceptedFile.length !== 1) {
            enqueueSnackbar('Please upload only one airfoil points text file', {variant:'error'});
            return;
        }

        const file = acceptedFile[0];
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            setIsLoading(true);

            if (event.target) {
                const fileText = event.target.result;
                if(fileText) {
                    let _originalPoints:Array<IPoints> = [];
                    try {
                        const lines = (fileText as string).split('\n');
                        _originalPoints = lines.map((line:string) => {
                            // const points = line.trim().replace(/\s+/g, ' ').split(' ');
                            const points = line.trim().replace(/\s+/g, '').split(',');
                            if (points.length !== 2) {
                                throw Error(`Invalid airfoil point - ${line}`);
                            }
                            return ({'x': parseFloat(points[0]), 'y': parseFloat(points[1])});
                        });
                    } catch (error) {
                        enqueueSnackbar('Error while parsing the file', {variant:'error'});
                        return;
                    }
                    setOriginalPoints(_originalPoints);

                    const data = {
                        'points': _originalPoints.map((point) => [point.x, point.y])
                    };
                    post(POST_API_URLS.AIRFOIL_INTERPOLATE_POINTS(), data, expireCallback).then((res: AxiosResponse) => {
                        if(res.status === 200){
                          const _interpolatedPoints = res.data.interpolated_points.map((point:[number,number]) => ({
                            'x': point[0],
                            'y': point[1]
                          }));

                          const _originalPoints = res.data.fixed_original_points.map((point:[number,number]) => ({
                            'x': point[0],
                            'y': point[1]
                          }));

                          setInterpolatedPoints(_interpolatedPoints);
                          setOriginalPoints(_originalPoints);
                        } else {
                          enqueueSnackbar('Error while interpolating airfoil points', {variant: 'error'});
                        }
                    }).catch((err: AxiosError) => {
                        if(err['code'] === 'ERR_BAD_REQUEST'){
                            enqueueSnackbar('Invalid airfoil points uploaded', {variant: 'error'});
                            setInterpolatedPoints([]);
                            setOriginalPoints([]);
                        } else{
                            enqueueSnackbar('Error while interpolating airfoil points', {variant: 'error'});
                        }
                    });
                }
            }

            setIsLoading(false);
        };

        if (file) {
            fileReader.readAsText(file);
        }
    }, []);

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: true,
                text: 'Airfoil Points',
            }
        },
        scales: {
            y: {
                min:-1,
                max:1
            },
            x: {
                min:-0.1,
                max:1.1
            }
        }
    };


    const sx = {m:1, width:'35ch'};

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <div 
                style={{border: '1px solid #707070', 
                boxShadow:'0px 3px 6px #00000029', borderRadius: '20px', backgroundColor: 'white', color: 'black'}}            
            >
                <form>
                    <Stack spacing={2} style={{marginLeft:'50px', marginRight: '50px', marginTop:'50px'}}>
                        <fieldset style={{width: '800px', height: '100%', border: '1px solid #6D77B0', borderRadius: '12px',
                            padding: '12px 6px 6px 6px', marginLeft:'auto', marginRight:'auto'}}
                        >
                            <legend style={{marginLeft: '17px'}}>Airfoil</legend>
                            <Stack spacing={2}>
                                <TextField
                                    error={!!nameError}
                                    sx={sx}
                                    label='Airfoil Name'
                                    value={name}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setName(e.target.value);
                                    }}
                                    size='small'
                                    helperText={nameError}
                                    onBlur={onNameTextboxBlur}
                                />
                                <div
                                    style={{marginLeft:'8px', marginRight: '8px'}}
                                
                                >
                                    <Dropzone onDrop={onFileDrop} accept={{'text/plain': ['.txt']}}/>
                                </div>
                                {(interpolatedPoints.length > 0) && (
                                    <Scatter
                                        options={options}
                                        data={{datasets: [
                                            {
                                                label: 'Original points',
                                                data: originalPoints,
                                                backgroundColor: 'rgba(255, 0, 0, 1)',
                                                showLine: true
                                            },
                                            {
                                                label: 'Interpolated points',
                                                data: interpolatedPoints,
                                                backgroundColor: 'rgba(0, 0, 255, 1)',
                                                showLine: true
                                            },
                                        ]}}
                                    />
                                )}
                            </Stack>
                        </fieldset>
                        <Button 
                            variant='contained' 
                            type='submit'
                            onClick={(e:any) => {
                                e.preventDefault();
                                addAirfoil();
                            }}
                            style={{width:'344px', height:'45px', margin:'44px auto 30px auto'}}
                        >
                            REGISTER
                        </Button>
                    </Stack>
                </form>
                
            </div>
        </>
    );


}