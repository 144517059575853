import React, {useEffect, useState} from 'react';
import { Grid, Typography, Stack, Tooltip, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridComparatorFn, GridFilterItem, GridFilterOperator, GridSelectionModel, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar} from 'notistack';
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import ExperimentStatus from './ExperimentStatus';
import { ExperimentStatus as ExperimentStatusEnum, Page } from 'constants/enums';
import CircularSpinner from 'common/CircularSpinner';
import { DELETE_API_URLS, GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import {get, del, postBinary} from 'utils/api';
import NavigationBar from 'common/NavigationBar';
import Sidebar, {MenuButton} from 'common/Sidebar';
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';
import WithAuthenticate from 'common/hoc/WithAuthenticate';
import { TOKEN_KEY, USER_EMAIL } from 'constants/constants';
import { dateComparator } from 'utils/utils';

{/*
to filter by our own emial, use: sessionStorage.getItem(USER_EMAIL)
add own custom component to toolbar, or perhaps checkbox
*/}


export const Dashboard = () => {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const [experiments, setExperiments] = useState<any>([]);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null); 
    const [pageSize, setPageSize] = useState<number>(25);
    const [checked, setChecked] = React.useState(false);
    var userFilter = false
    const reloadExperimentsTimer = 30000

    const expireCallback = () => {
        navigate('/login');
    }
    
    const CustomToolbar = () => {
        
        const handleCheck = () =>{
            userFilter = !checked;
            setChecked(!checked);
            loadExperimentsInBackground();
        };
        return (
        <GridToolbarContainer >
            <GridToolbarColumnsButton style={{ color: '#212121' }}/>
            <GridToolbarFilterButton style={{ color: '#212121' }}/>
            <GridToolbarDensitySelector style={{ color: '#212121' }}/>
            <GridToolbarExport style={{ color: '#212121' }}/>
            <label>
                <input type="checkbox"
                    checked={checked}
                    onChange={handleCheck}/>
                YOUR PROJECTS
            </label>
        </GridToolbarContainer>
        
        );
    }
    useEffect(() => {
        loadExperiments();
        const periodicExperimentUpdate = setInterval(() => {
            loadExperimentsInBackground();
          }, reloadExperimentsTimer);
          return () => {
            clearInterval(periodicExperimentUpdate);
          };    
    }, []);

    const loadExperimentsInBackground = () => {
        
        get(GET_API_URLS.EXPERIMENTS(), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                setExperiments((userFilter)?res.data.experiments.filter
                (function(x: { email: string; }) { return x.email == sessionStorage.getItem(USER_EMAIL)}): res.data.experiments);
                console.log(res.data.experiments);
            } else {
                enqueueSnackbar('Error while fetching the latest status of the experiments.', {variant: 'error'});
            }
        }).catch((err:AxiosError) => {
            enqueueSnackbar('Error while fetching the latest status of the experiments.', {variant: 'error'});
        })
    }

    const loadExperiments = () => {
        setIsLoading(true);
        get(GET_API_URLS.EXPERIMENTS(), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                
                setExperiments((userFilter)?res.data.experiments.filter
                (function(x: { email: string; }) { return x.email == sessionStorage.getItem(USER_EMAIL)}): res.data.experiments);

            } else {
                enqueueSnackbar('Error while loading experiments.', {variant: 'error'});
            }
        }).catch((err:AxiosError) => {
            enqueueSnackbar('Error while loading experiments.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const deleteExperiment = (experimentId: number) => {
        setIsLoading(true);
        del(DELETE_API_URLS.EXPERIMENT_DELETE(experimentId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Deleted the experiment successfully.', {variant: 'success'});
            } else {
                enqueueSnackbar('Error while deleting the experiment.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while deleting the experiment.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
            setConfirmationProps(null);
            loadExperiments();
        })
    };

    const exportExperiments = () => {
        if (selectionModel.length === 0) {
            enqueueSnackbar('No experiment selected for export', {variant:'info'});
            return;
        }

        const data = {experiment_ids: selectionModel};
        setIsLoading(true);
        postBinary(GET_API_URLS.EXPERIMENTS_EXPORT(), data, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const blob = new Blob([res.data], {type: 'text/stl', endings: "native"})
                let a = document.createElement("a");
                document.body.appendChild(a);

                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = `experiments.csv`;
                a.click();
                setTimeout(function() {
                    window.URL.revokeObjectURL(url);
                }, 1000);

                enqueueSnackbar('Downloaded selected experiments csv file successfully', {variant: 'success'});
            } else {
                enqueueSnackbar('Error while downloading selected experiments csv file', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while downloading selected experiments csv file', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const monthToComparableNumber = (date: String) => {
        if (date === undefined || date === null || date.length !== 10) {
          return null;
        }
        const yearNumber = Number.parseInt(date.substring(6, 10));
        const monthNumber = Number.parseInt(date.substring(3, 5));
        const dayNumber = Number.parseInt(date.substring(0, 2));
        return yearNumber * 10000 + monthNumber * 100 + dayNumber;
      };

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width:60, align: 'left', headerAlign: 'left', editable: false, type: 'number'},
        {field: 'email', headerName: 'User Email', width: 90, align: 'left', editable: false, type: 'string'},
        {field: 'title', headerName: 'Project', width:120, align: 'left', editable: false, type: 'string'},
        {field: 'created_on', headerName: 'Date', width: 90, align: 'left', editable: false, type: 'date', renderCell: (params) => {
            const date = params.row.created_on;
            const formattedDate = new Date(date).toLocaleDateString('en-US');
            return ( 
                <div>{formattedDate}</div>
            );
        }, sortComparator: dateComparator},
        {field: 'hub_break_cl', headerName: 'Hub Break', width: 90, align: 'left', headerAlign: 'left', editable: false, type: 'number', 
            renderCell: (params) => {
                return (
                    <div>{params.row.cl_design === 'CONSTANT'?'N/A':Number(params.row.hub_break_cl)}</div>
                );
        }},
        {field: 'tip_break_cl', headerName: 'Tip Break', width: 90, align: 'left', headerAlign: 'left', editable: false, type: 'number', 
            renderCell: (params) => {
                return (
                    <div>{params.row.cl_design === 'CONSTANT'?'N/A':Number(params.row.tip_break_cl)}</div>
                );
        }},
        {field: 'tip_break_radius', headerName: 'Diameter', width: 100, align: 'left', headerAlign: 'left', editable: false, type: 'number', 
            renderCell: (params) => {
                return (
                    <div>{Number(params.row.tip_break_radius * 2)}</div>
                );
        }},
        {field: 'airfoil_name', headerName: 'Airfoil', width: 200, align: 'left', headerAlign: 'left', editable: false, type: 'number', 
            renderCell: (params) => {
                return (
                    <div>{params.row.airfoil_name}</div>
                );
        }},
        {field: 'status', headerName: 'Status', editable: false, 
        type: 'singleSelect', valueOptions: Object.values(ExperimentStatusEnum), 
        width:320, align: 'left', headerAlign: 'left', renderCell: (params) => {
            return (
                <ExperimentStatus 
                    experimentId={parseInt(params.row.id)} 
                    status={ExperimentStatusEnum[params.row.status as ExperimentStatusEnum]}
                />
            );
        }},
        {field: 'edit', headerName: 'Edit', width:30, align: 'left', headerAlign: 'left', sortable: false, 
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => navigate(`/inputs/${params.row.id}`)}>
                        <EditOutlinedIcon />
                    </IconButton>
                );
            }
        },
        {field: 'delete', align: 'left', headerAlign: 'left', headerName: 'Delete', flex: 1, sortable: false,
            renderCell: (params) => {
                const onDelete = (e:any) => {
                    e.stopPropagation();
                    setConfirmationProps({
                        title: 'Delete Experiment',
                        body: 'Do you want to delete the experiment?',
                        onCancel: () => setConfirmationProps(null),
                        onConfirm: () => deleteExperiment(parseInt(params.row.id))
                    });
                } 
                return (
                    <IconButton onClick={onDelete} color='error'>
                        <DeleteForeverOutlinedIcon />
                    </IconButton>
                );
            }
        }
      ];

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop:'30px', marginBottom: '20px'}}>
                <MenuButton
                    Icon={AddCircleOutlineIcon}
                    iconColor='black'
                    label='New'
                    onClick={() => navigate('/inputs')}
                    disabled={false}
                />
                <MenuButton
                    Icon={SaveAltOutlinedIcon}
                    iconColor='black'
                    label='Export'
                    onClick={exportExperiments}
                    disabled={false}
                />
            </Stack>
        );
    } 

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{width:'1400px'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.DASHBOARD}/>
                </div>
                <Stack spacing={3} style={{paddingLeft: '30px', width: '100%'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Experiments
                    </Typography>
                    <DataGrid
                        autoHeight
                        columns={columns}
                        rows={experiments}
                        checkboxSelection
                        selectionModel={selectionModel}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
                        sx={{
                            "& .MuiDataGrid-cell": {
                            padding: '2px 10px'
                            }
                        }}
                        components={{ 
                            Toolbar: CustomToolbar
                        }}
                    />
                </Stack>
            </Stack>
            {confirmationProps && <ConfirmationDialog {...confirmationProps}/>}
        </>
    );
}

export default WithAuthenticate(Dashboard);