import { useLoader, ThreeElements } from '@react-three/fiber';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import * as THREE from 'three';
export let largestDimension = 0.04556026184973307; 

const loader = new STLLoader();

function STLMesh(props: any) {
  const stl= useLoader(STLLoader, props.file);
  
  loader.load( props.file,
    function (geometry) {
      if (geometry instanceof THREE.BufferGeometry) {
        const positionAttribute = geometry.attributes.position;
        if (positionAttribute instanceof THREE.BufferAttribute) {
          const boundingBox = new THREE.Box3().setFromBufferAttribute(positionAttribute);
          const size = new THREE.Vector3();
          boundingBox.getSize(size);

          largestDimension = Math.max(size.x, size.y, size.z);
        }
      }
    }
  );
  return (
    <mesh
      {...props}
      position={[0, 0, 0]}
      scale={[0.5, 0.5, 0.5]}
      geometry={stl}
      castShadow
      receiveShadow
    >
      <meshStandardMaterial wireframe={props.useWireFrame} metalness={0.8} roughness={0.1} side={THREE.DoubleSide}/>
    </mesh>
  );
}

export default STLMesh;