import React,  {useState} from 'react';
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CloudQueueRoundedIcon from '@mui/icons-material/CloudQueueRounded';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

import { ExperimentStatus as ExperimentStatusEnum } from 'constants/enums';
import { Button, Tooltip, Typography } from '@mui/material';

interface ExperimentStatusPropsType {
    experimentId: number,
    status: ExperimentStatusEnum
}

export const ExperimentStatus = (props: ExperimentStatusPropsType) => {
    const {experimentId, status} = props;
    const navigate = useNavigate();

    const [isHovering, setIsHovering] = useState<boolean>(false);

    const statusBarStyle = {
        width: '250px', height: '100%', display: 'flex', alignItems: 'center', color: 'white',
        backgroundColor: 'green', marginTop:'4px', marginBottom:'4px', borderRadius:'6px',
    } as React.CSSProperties;
    const iconStyle = {fontSize: '20px', marginLeft: '16px', marginRight: '12px'} as React.CSSProperties;
    const textStyle = {width:'100%', textAlign:'left', fontSize:'14px'} as React.CSSProperties;


    let LeftIcon = ErrorOutlineRoundedIcon;
    let backgroundColor= '#A82FD3';
    let mainLabel = 'Created';
    let RightIcon = SettingsSuggestOutlinedIcon;
    let hoverLabel = 'Optimizer';
    let callbackFunction = () => navigate('/dashboard');

    switch (status) {
        case ExperimentStatusEnum.CREATED: {
            LeftIcon = ErrorOutlineRoundedIcon;
            backgroundColor= '#A82FD3';
            mainLabel = 'Created';
            RightIcon = SettingsSuggestOutlinedIcon;
            hoverLabel = 'Optimizer';
            callbackFunction = () => navigate(`/optimizer/${experimentId}`);
            break;
        }
        case ExperimentStatusEnum.QUEUED: {
            LeftIcon = WarningAmberOutlinedIcon;
            backgroundColor= '#FFA726';
            mainLabel = 'In Queue';
            RightIcon = CloudQueueRoundedIcon;
            hoverLabel = 'Queue';
            callbackFunction = () => navigate('/queue');
            break;
        }
        case ExperimentStatusEnum.IN_PROGRESS: {
            LeftIcon = InfoOutlinedIcon;
            backgroundColor= '#0288D1';
            mainLabel = 'In Progress...';
            RightIcon = ArticleOutlinedIcon;
            hoverLabel = 'Log File';
            callbackFunction = () => navigate(`/logs/${experimentId}`);
            break;
        }
        case ExperimentStatusEnum.COMPLETED: {
            LeftIcon = CheckCircleOutlineOutlinedIcon;
            backgroundColor= '#2E7D32';
            mainLabel = 'Completed';
            RightIcon = AssignmentTurnedInIcon;
            hoverLabel = 'Results';
            callbackFunction = () => navigate(`/results/${experimentId}`);
            break;
        }
        case ExperimentStatusEnum.FAILED: {
            LeftIcon = CheckCircleOutlineOutlinedIcon;
            backgroundColor= '#D32F2F';
            mainLabel = 'Failed';
            RightIcon = SettingsSuggestOutlinedIcon;
            hoverLabel = 'Optimizer';
            callbackFunction = () => navigate(`/optimizer/${experimentId}`);
            break;
        }
        case ExperimentStatusEnum.STOPPED: {
            LeftIcon = ErrorOutlineRoundedIcon;
            backgroundColor= '#2FD3D3';
            mainLabel = 'Stopped';
            RightIcon = SettingsSuggestOutlinedIcon;
            hoverLabel = 'Optimizer';
            callbackFunction = () => navigate(`/optimizer/${experimentId}`);
            break;
        }
        case ExperimentStatusEnum.COMPLETED_BUT_STOPPED: {
            LeftIcon = CheckCircleOutlineOutlinedIcon;
            backgroundColor= '#2E7D32';
            mainLabel = 'Completed (Stopped)';
            RightIcon = AssignmentTurnedInIcon;
            hoverLabel = 'Results';
            callbackFunction = () => navigate(`/results/${experimentId}`);
            break;
        }
        default: {
            break;
        }

    }

    return (
        <div 
            style={{...statusBarStyle, backgroundColor: backgroundColor,}}
        >
            <LeftIcon style={iconStyle}/>
            <div style={textStyle}>{mainLabel}</div>
            <div style={{position: 'relative'}}>
                {
                    isHovering? (
                        <div 
                            onMouseLeave={() => setIsHovering(false)}
                            style={{display:'flex', cursor:'pointer', position: 'absolute', left: '-48px', top:'-16px',
                                color: 'black', backgroundColor:'rgba(0,0,0,0.1)', width: '120px', height: '28px',
                                alignItems:'center', background: '#FFFFFF 0% 0% no-repeat padding-box', opacity: '0.8', 
                                boxShadow: '3px 3px 6px #00000029', borderRadius: '2px' }}
                            onClick={callbackFunction}
                        >
                            <RightIcon style={iconStyle} />
                            <div style={{marginLeft:'5px'}}>{hoverLabel}</div>
                        </div>
                    ) : (
                    <RightIcon 
                        onMouseEnter={() => setIsHovering(true)} 
                        onClick={callbackFunction}
                        style={{...iconStyle, cursor:'pointer'}} 
                    />)
                }
            </div>
        </div>
    );
}

export default ExperimentStatus;