import React, {useState, useEffect} from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

interface CircularSpinnerProps {
    document: HTMLElement | null | undefined
}

export const CircularSpinner = (props: CircularSpinnerProps) => {
    if(props.document){ 
        let dimensions = props.document.getBoundingClientRect();
        if(dimensions['height']){
            let bottomValue = dimensions['bottom'] + window.scrollY;
            let heightValue = dimensions['height'];
            let leftValue = dimensions['left'] + window.scrollX;
            let rightValue = dimensions['right'] + window.scrollX;
            let topValue = dimensions['top'] + window.scrollY;
            let widthValue = dimensions['width'];

            return (
                <Backdrop
                    style={{opacity:'50%',position:'absolute', bottom:bottomValue,height:heightValue,left:leftValue,right:rightValue,top:topValue,width:widthValue,borderRadius: '12px'}}
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                    onClick={() => {}}
                >
                    <CircularProgress color="inherit" size={100} thickness={2}/>
                </Backdrop>
            );
        }
    }
    return (
        <Backdrop
            style={{position:'fixed', width:'100%', height:'100%'}}
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={() => {}}
        >
            <CircularProgress color="inherit" size={100} thickness={2}/>
        </Backdrop>
    );
}
CircularSpinner.defaultProps = {
    document: null
}

export default CircularSpinner;