import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { post, isLoggedIn } from "utils/api";
import { AxiosError, AxiosResponse } from "axios";
import NavigationBar from "common/NavigationBar";
import CircularSpinner from "common/CircularSpinner";
import { Button, Stack, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { POST_API_URLS } from "constants/apiUrls";
import Sidebar from "common/Sidebar";
import { Page } from "constants/enums";
import WithAuthenticate from 'common/hoc/WithAuthenticate';

export const PasswordChange = () => {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const [ currentPassword, setCurrentPassword ] = useState<string>('');
  const [ newPassword, setNewPassword ] = useState<string>('');
  const [ newPasswordConfirmation, setNewPasswordConfirmation ] = useState<string>('');
  const [ currentPasswordError, setCurrentPasswordError ] = useState<string>('');
  const [ newPasswordError, setNewPasswordError ] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const expireCallback = () => {
    navigate('/login');
}

  useEffect( () => {
    if(!isLoggedIn()){
      navigate("/login");
    }
  }, []);

  const onCurrentPasswordTextboxBlur = () => {
    currentPassword? setCurrentPasswordError(''): setCurrentPasswordError('Please enter your current password');
  }

  const onNewPasswordTextboxBlur = () => {
    if (newPassword === '') {
        setNewPasswordError('Please enter secure password');
    } else if (newPassword !== newPasswordConfirmation) {
        setNewPasswordError('Passwords must match');
    } else {
        setNewPasswordError('');
    }
}


  const changePassword = () => {

    if (currentPasswordError){
      enqueueSnackbar(currentPasswordError, {variant: 'warning'});
      return;
    } else if (newPasswordError) {
      enqueueSnackbar(newPasswordError, {variant: 'warning'});
      return;
    }


    setIsLoading(true);
    post(POST_API_URLS.USER_PASSWORD_CHANGE(), 
    {
      "password": currentPassword,
      "new_password": newPassword,
      "retyped_password": newPasswordConfirmation,
    }, expireCallback).then( (res: AxiosResponse) => {
        if(res.status === 200 ){
          enqueueSnackbar('Changed the password successfully', {variant: 'success'});
        } else {
          enqueueSnackbar('Incorrect password', {variant: 'error'});
        }
    }).catch( (res: AxiosError) => {
      enqueueSnackbar('Error while changing the password', {variant: 'error'});
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const Menubar = () => {
    return (<></>);
  }

  const sx = {m:1, width:'35ch'};

  return (
    <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{height:'100%'}}>
              <div style={{width:'50px'}}>
                <Sidebar Menubar={Menubar} ActivePage={Page.PASSWORD_CHANGE}/>
              </div>
              <div style={{paddingLeft: '30px', marginLeft:'auto', marginRight:'auto', marginTop:'150px'}}>
                <div 
                  style={{width: '450px', border: '1px solid #707070', 
                  boxShadow:'0px 3px 6px #00000029', borderRadius: '20px', backgroundColor: 'white', color: 'black'}}            
                >
                  <Stack spacing={2} style={{marginLeft:'50px', marginRight: '50px', marginTop:'50px'}}>
                      <fieldset style={{width: '330px', height: '100%', border: '1px solid #6D77B0', borderRadius: '12px',
                          padding: '12px 6px 6px 6px', marginLeft:'auto', marginRight:'auto'}}
                      >
                          <legend style={{marginLeft: '17px'}}>Change Password</legend>
                          <TextField
                              error={!!currentPasswordError}
                              sx={sx}
                              label='Current Password'
                              type='password'
                              value={currentPassword}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  setCurrentPassword(e.target.value);
                              }}
                              size='small'
                              helperText={currentPasswordError}
                              onBlur={onCurrentPasswordTextboxBlur}
                              autoComplete='new-password'
                          />
                          <TextField
                              error={!!newPasswordError}
                              sx={sx}
                              label='New Password'
                              type='password'
                              value={newPassword}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  setNewPassword(e.target.value);
                              }}
                              size='small'
                              helperText={newPasswordError}
                              onBlur={onNewPasswordTextboxBlur}
                          />
                          <TextField
                              error={!!newPasswordError}
                              sx={sx}
                              label='Confirm Password'
                              type='password'
                              value={newPasswordConfirmation}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  setNewPasswordConfirmation(e.target.value);
                              }}
                              size='small'
                              helperText={newPasswordError}
                              onBlur={onNewPasswordTextboxBlur}
                          />
                      </fieldset>
                      <Button 
                          variant='contained' 
                          onClick={() => changePassword()}
                          style={{width:'344px', margin:'44px auto 30px auto'}}
                      >
                          SAVE CHANGES
                      </Button>
                  </Stack>
                </div>
              </div>
            </Stack>
        </>  
  );
}

export default WithAuthenticate(PasswordChange);

