import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import CircularSpinner from 'common/CircularSpinner';
import { Button, IconButton, Stack, TextField, Checkbox } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import ConfirmationDialog, { ConfirmationProps } from "common/ConfirmationDialog";
import { del, get, post } from 'utils/api';
import { DELETE_API_URLS, GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';


export const ClusterManagement = () => {

    const {enqueueSnackbar} = useSnackbar();

    const navigate = useNavigate();
    
    const [clusterName, setClusterName] = useState<string>('');
    const [clusters, setClusters] = useState<Array<{id: number, name: string, status: string, isBusy: boolean}>>([]);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        loadCFDClusters();
    }, [])

    const loadCFDClusters = () => {
        setIsLoading(true);
        get(GET_API_URLS.CFD_CLUSTERS(), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                setClusters(res.data.cfd_clusters.map((_cluster:any) => ({
                    id: _cluster.id,
                    name: _cluster.name,
                    status: _cluster.status,
                    isBusy: _cluster.is_busy,
                })))
            } else {
                enqueueSnackbar(`Error while loading CFD clusters`, {variant: 'error'});    
            }
        }).catch((err) => {
            enqueueSnackbar(`Error while loading CFD clusters`, {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const createCFDCluster = () => {
        const re = /^[a-zA-Z0-9-]+$/;
        if ((clusterName.trim() === '') || (!re.test(clusterName))) {
            enqueueSnackbar('Error', {variant: 'warning'});
            return;
        }

        const data = {
            'name': clusterName.trim()
        };

        setIsLoading(true);
        post(POST_API_URLS.CFD_CLUSTER_CREATE(), data, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                loadCFDClusters();
                enqueueSnackbar(`Creating the CFD cluster`, {variant: 'info'});
            } else {
                enqueueSnackbar(`Error while creating the CFD cluster`, {variant: 'error'});
            }
        }).catch((err) => {
            enqueueSnackbar(`Error while creating the CFD cluster`, {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const deleteCFDCluster = (cfd_cluster_id: number) => {
        setIsLoading(true);
        del(DELETE_API_URLS.CFD_CLUSTERE_DELETE(cfd_cluster_id), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                loadCFDClusters();
                enqueueSnackbar(`Deleted the CFD cluster successfully`, {variant: 'success'});
            } else {
                enqueueSnackbar(`Error while deleting the CFD cluster`, {variant: 'error'});
            }
        }).catch((err) => {
            enqueueSnackbar(`Error while deleting the CFD cluster`, {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
            setConfirmationProps(undefined);
        })
    }

    const columns: GridColDef[] = [
        {field: 'name', headerName: 'Cluster Name', headerAlign: 'center', align: 'center', width: 200, editable: false, type: 'string'},
        {field: 'status', headerName: 'Status', headerAlign: 'center', align: 'center', width: 200, editable: false, type: 'string'},
        {field: 'isBusy', headerName: 'Is Busy', headerAlign: 'center', align: 'center', width: 100, editable: false, type: 'boolean'},
        {field: 'action', headerName: 'Delete', width: 90, sortable: false, headerAlign: 'center', align: 'center', renderCell: (params) => {
            const onDelete = (e:any) => {
                e.stopPropagation();
                setConfirmationProps({
                    title: 'Delete CFD Cluster',
                    body: 'Do you want to delete CFD cluster?',
                    onCancel: () => setConfirmationProps(undefined),
                    onConfirm: () => deleteCFDCluster(params.row.id)
                })
            };
            return (
                <IconButton onClick={onDelete} color='error' disabled={params.row.isBusy}>
                    <DeleteForeverOutlinedIcon/>
                </IconButton>
            );
        }}
    ];

    const sx = {m:1, width:'35ch'};

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <Stack
                style={{width: '600px', height: '450px', border: '1px solid #707070', 
                boxShadow:'0px 3px 6px #00000029', borderRadius: '20px', backgroundColor: 'white', color: 'black'}}            
            >
                <form>
                    <Stack direction='row' spacing={8}>
                        <TextField 
                            sx={sx}
                            label='Cluster name'
                            value={clusterName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setClusterName(e.target.value);
                            }}
                        />
                        <Button
                            variant='contained'
                            type='submit'
                            onClick={(e) => {
                                e.preventDefault();
                                createCFDCluster();
                            }}
                            style={{
                                margin:'8px'
                            }}
                        >
                            CREATE CLUSTER
                        </Button>
                    </Stack>
                </form>
                <DataGrid
                    columns={columns}
                    rows={clusters}
                    style={{marginBottom:'20px'}}
                />
            </Stack>
            {confirmationProps && <ConfirmationDialog {...confirmationProps} />}
        </>
    );
}

export default ClusterManagement;