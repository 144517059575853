import axios, { AxiosError, AxiosResponse } from "axios";
import * as _ from 'lodash';

import { ROOT_URL, TOKEN_KEY, USER_EMAIL, USER_ID, REQUEST_TIMEOUT } from "constants/constants";


const request = axios.create({
  baseURL: ROOT_URL,
  timeout: REQUEST_TIMEOUT,
});

const authHeader = () => {
  if(sessionStorage.getItem(TOKEN_KEY)){
    return {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem(TOKEN_KEY),
        crossdomain: true
      },
    }
  }
  return { };
}

export const isLoggedIn = () => {
  if(sessionStorage.getItem(TOKEN_KEY) == null) { 
    return false; 
  }
  return true;
}

export const logout = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(USER_ID);
  sessionStorage.removeItem(USER_EMAIL);
};

const handleAuthentication = (data: any) => {
  if (data[TOKEN_KEY]) {
    sessionStorage.setItem(TOKEN_KEY, data[TOKEN_KEY]);
    return true;
  }
  return false;
};

export const get = (endpoint: string, tokenExpireCallback: Function = () => {}) => {
  const resp = request.get(endpoint, authHeader())
  resp.then((res: AxiosResponse) => {
    handleAuthentication(res.data);
  }).catch((res: AxiosError) => {
    if (_.get(res, 'response.status', null) === 401) {
      tokenExpireCallback();
    }
  });
  return resp;
};

export const post = (endpoint: string, data = {}, tokenExpireCallback: Function= () => {}, timeout = REQUEST_TIMEOUT) => {
  const resp = request.post(endpoint, data, {
    ...authHeader(),
    timeout: timeout,
  })
  resp.then((res: AxiosResponse) => {
    handleAuthentication(res.data);
  }).catch((res: AxiosError) => {
    if (_.get(res, 'response.status', null) === 401) {
      tokenExpireCallback();
    }
  });
  return resp;
};

export const put = (endpoint: string, data = {}, tokenExpireCallback: Function= () => {}) => {
  const resp = request.put(endpoint, data, authHeader())
  resp.then((res: AxiosResponse) => {
    handleAuthentication(res.data);
  }).catch((res: AxiosError) => {
    if (_.get(res, 'response.status', null) === 401) {
      tokenExpireCallback();
    }
  });
  return resp;
};

export const del = (endpoint: string, tokenExpireCallback: Function= () => {}) => {
  const resp = request.delete(endpoint, authHeader())
  resp.then((res: AxiosResponse) => {
    handleAuthentication(res.data);
  }).catch((res: AxiosError) => {
    if (_.get(res, 'response.status', null) === 401) {
      tokenExpireCallback();
    }
  });
  return resp;
};

export const getBinary = (endpoint: string, tokenExpireCallback: Function= () => {}) => {
  const resp = request.get(endpoint, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem(TOKEN_KEY)
    }})
  resp.then((res: AxiosResponse) => {
    handleAuthentication(res.data);
  }).catch((res: AxiosError) => {
    if (_.get(res, 'response.status', null) === 401) {
      tokenExpireCallback();
    }
  });
  return resp;
};

export const postBinary = (endpoint: string, data = {}, tokenExpireCallback: Function= () => {}) => {
  
  const resp = request.post(endpoint, data, {
    responseType: "arraybuffer",
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem(TOKEN_KEY)
    }
  });
  resp.then((res: AxiosResponse) => {
    handleAuthentication(res.data);
  }).catch((res: AxiosError) => {
    if (_.get(res, 'response.status', null) === 401) {
      tokenExpireCallback();
    }
  });
  return resp;
};

export const postFile = (endpoint: string, data:FormData=new FormData(), tokenExpireCallback: Function= ()=>{}) => {
  const resp = request.post(endpoint, data, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem(TOKEN_KEY)}`,
      'Content-Type': 'multipart/form-data',
    }
  });
  resp.then((res: AxiosResponse) => {
    handleAuthentication(res.data);
  }).catch((res: AxiosError) => {
    if (_.get(res, 'response.status', null) === 401) {
      tokenExpireCallback();
    }
  });
  return resp;
}

export const putFile = (endpoint: string, data:FormData=new FormData(), tokenExpireCallback: Function= ()=>{}) => {
  const resp = request.put(endpoint, data, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem(TOKEN_KEY)}`,
      'Content-Type': 'multipart/form-data',
    }
  });
  resp.then((res: AxiosResponse) => {
    handleAuthentication(res.data);
  }).catch((res: AxiosError) => {
    if (_.get(res, 'response.status', null) === 401) {
      tokenExpireCallback();
    }
  });
  return resp;
}