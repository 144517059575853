import React, {useState, useEffect} from 'react';
import { Button, Stack, TextField } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { AxiosError, AxiosResponse } from 'axios';

import CircularSpinner from 'common/CircularSpinner';
import Sidebar from 'common/Sidebar';
import NavigationBar from 'common/NavigationBar';
import { Page } from 'constants/enums';
import { validateEmail } from 'utils/validation';
import { post, isLoggedIn } from 'utils/api';
import { POST_API_URLS } from 'constants/apiUrls';
import { TOKEN_KEY, USER_EMAIL } from 'constants/constants';
import WithAuthenticate from 'common/hoc/WithAuthenticate';


export const Profile = () => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const [ email, setEmail ] = useState<string>(sessionStorage.getItem(USER_EMAIL) || '');
    const [emailError, setEmailError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const expireCallback = () => {
      navigate('/login');
  }

    useEffect( () => {
        if(!isLoggedIn()){
          navigate("/login");
        }
    }, []);

    const onEmailTextboxBlur = () => {
        if ( (email==='') || !validateEmail(email)){
          setEmailError('Please enter a valid email address');
        } else {
          setEmailError('');
        }
    }; 


  const changeEmail = () => {

    if (emailError){
      enqueueSnackbar(emailError, {variant: 'warning'});
      return;
    }

    setIsLoading(true);
    post(POST_API_URLS.USER_EMAIL_CHANGE(), 
    {
      "email": email,
    }, expireCallback).then((res: AxiosResponse) => {
        if(res.status === 200 ){
          sessionStorage.setItem(TOKEN_KEY, res.data[TOKEN_KEY]);
          sessionStorage.setItem(USER_EMAIL, email);
          enqueueSnackbar('Changed the email successfully', {variant: 'success'});
        } else {
          enqueueSnackbar('Error while changing the email', {variant: 'error'});
        }
    }).catch( (res: AxiosError) => {
      enqueueSnackbar('Error while changing the email', {variant: 'error'});
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const Menubar = () => {
    return (<></>);
  }

  const sx = {m:1, width:'35ch'};

  return (
    <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{height:'100%'}}>
              <div style={{width:'50px'}}>
                <Sidebar Menubar={Menubar} ActivePage={Page.PROFILE}/>
              </div>
              <div style={{paddingLeft: '30px', marginLeft:'auto', marginRight:'auto', marginTop:'150px'}}>
                <div 
                  style={{width: '450px', border: '1px solid #707070', 
                  boxShadow:'0px 3px 6px #00000029', borderRadius: '20px', backgroundColor: 'white', color: 'black'}}            
                >
                  <Stack spacing={2} style={{marginLeft:'50px', marginRight: '50px', marginTop:'50px'}}>
                    <fieldset style={{width: '330px', height: '100%', border: '1px solid #6D77B0', borderRadius: '12px',
                        padding: '12px 6px 6px 6px', marginLeft:'auto', marginRight:'auto'}}
                    >
                        <legend style={{marginLeft: '17px'}}>Change Email</legend>
                        <TextField
                        error={!!emailError}
                        sx={sx}
                        label='Email'
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(e.target.value);
                        }}
                        size='small'
                        helperText={emailError}
                        onBlur={onEmailTextboxBlur}
                    />
                    </fieldset>
                    <Button 
                        variant='contained' 
                        onClick={() => changeEmail()}
                        style={{width:'344px', margin:'44px auto 30px auto'}}
                    >
                        SAVE CHANGES
                    </Button>
                  </Stack>
                </div>
              </div>
            </Stack>
        </>  
  );
}

export default WithAuthenticate(Profile);