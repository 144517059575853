import { ExperimentStatus } from "constants/enums";
export const TOKEN_KEY: string = 'access_token';
export const ROOT_URL:string = process.env.REACT_APP_API_URL || '';
export const USER_ID: string = 'user_id';
export const USER_EMAIL: string = 'user_email';
export const REQUEST_TIMEOUT: number = 600000;
export const DATETIME_FORMAT = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
}