import React, {useEffect, useState} from 'react';
import { AxiosResponse, AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { Typography, IconButton, FormControl, RadioGroup, FormControlLabel, Radio, Backdrop, SelectChangeEvent, Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Theme } from '@mui/material';
import { DataGrid, GridCallbackDetails, GridColDef, GridRowParams, GridSelectionModel, MuiEvent } from "@mui/x-data-grid";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import * as _ from 'lodash';
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';
import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from "common/NavigationBar";
import {DELETE_API_URLS, GET_API_URLS} from 'constants/apiUrls';
import { del, get, getBinary, isLoggedIn } from 'utils/api';
import Sidebar, { MenuButton } from 'common/Sidebar';
import { Page } from 'constants/enums';
import { AddAirfoil, IAirfoil } from './AddAirfoil';
import { Scatter } from 'react-chartjs-2';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import WithAuthenticate from 'common/hoc/WithAuthenticate';




export const Airfoils = () => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const [airfoils, setAirfoils] = useState<Array<any>>([]);
    const [selectedAirfoil, setSelectedAirfoil] = useState<IAirfoil|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRegistrationOpen, setIsRegistrationOpen] = useState<boolean>(false);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null); 
    const [pageSize, setPageSize] = useState<number>(25);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/login');
            return;
        }

        loadAirfoils();
    }, []);

    const loadAirfoils = () => {
        setIsLoading(true);
        get(GET_API_URLS.AIRFOILS(), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const _airfoils = res.data['airfoils'].map((airfoil:any) => ({
                    id: airfoil.id,
                    name: airfoil.name,
                }))
                setAirfoils(_airfoils);
            } else {
                enqueueSnackbar('Failed to fetch airfoils', {variant: 'error'});
            }
        }).catch((res: AxiosError) => {
            enqueueSnackbar('Failed to fetch airfoils', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const loadSelectedAirfoil = (airfoilId: number) => {
        setIsLoading(true);
        get(GET_API_URLS.AIRFOIL(airfoilId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {  
                const _airfoil = res.data.airfoil;
                setSelectedAirfoil({
                    id: _airfoil.id,
                    name: _airfoil.name,
                    points: _airfoil.points.map((point:Array<[number, number]>) => ({
                        x: point[0],
                        y: point[1]
                    })),
                    interpolatedPoints: _airfoil.interpolated_points.map((point:Array<[number, number]>) => ({
                        x: point[0],
                        y: point[1]
                    }))
                });
            } else {
                enqueueSnackbar('Failed to fetch airfoils', {variant: 'error'});    
            }
        }).catch((res: AxiosError) => {
            enqueueSnackbar('Failed to fetch airfoils', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const columns: GridColDef[] = [
        {field: 'name', headerName: 'Airfoil', headerAlign: 'center', align:'center', flex: 1, editable: false, type: 'string'},
        {field: 'edit', headerName: 'Edit', width:60, align: 'left', headerAlign: 'left', sortable: false, 
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => navigate(`/airfoils/${params.id}`)}>
                        <EditOutlinedIcon />
                    </IconButton>
                );
            }
        },
        {field: 'delete', align: 'left', headerAlign: 'left', headerName: 'Delete', width:90, sortable: false,
            renderCell: (params) => {
                const onDelete = (e:any) => {
                    e.stopPropagation();
                    setConfirmationProps({
                        title: 'Delete Airfoil',
                        body: 'Do you want to delete the airfoil?',
                        onCancel: () => setConfirmationProps(null),
                        onConfirm: () => deleteAirfoil(parseInt(params.row.id))
                    });
                } 
                return (
                    <IconButton onClick={onDelete} color='error'>
                        <DeleteForeverOutlinedIcon />
                    </IconButton>
                );
            }
        },
        {field: 'download', headerAlign: 'left', align: 'left', headerName: 'Download', flex: 1, sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton 
                        onClick={() => {
                            downloadAirfoilFile(params.row.id, params.row.name)}
                        }
                    >
                        <SaveAltOutlinedIcon/>
                    </IconButton>
                );
            }
        },
    ];

    const onAirfoilRegistrationComplete = () => {
        setIsRegistrationOpen(false);
        loadAirfoils();
        setSelectedAirfoil(null);
    }

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: true,
                text: 'Airfoil Points',
            }
        },
        scales: {
            y: {
                min:-1,
                max:1
            },
            x: {
                min:-0.1,
                max:1.1
            }
        }
    };

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop:'30px', marginBottom:'20px'}}>
                <MenuButton
                    Icon={AddCircleOutlineIcon}
                    iconColor='black'
                    label='New'
                    onClick={() => {
                        setSelectedAirfoil(null);
                        setIsRegistrationOpen(true);
                    }}
                    disabled={false}
                />
            </Stack>
        );
    }

    const deleteAirfoil = (airfoilId: number) => {
        setIsLoading(true);
        del(DELETE_API_URLS.AIRFOIL_DELETE(airfoilId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Deleted the airfoil successfully.', {variant: 'success'});
                loadAirfoils();
            } else {
                enqueueSnackbar('Error while deleting the airfoil.', {variant: 'error'});
            }
        }).catch((err) => {
            enqueueSnackbar(`${err.response?.data.unsuccessful_msg}`, {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
            setConfirmationProps(null);
        })
    };

    const downloadAirfoilFile = (airfoilId: number, airfoilName:string) => {
        setIsLoading(true);
        getBinary(GET_API_URLS.AIRFOIL_FILE(airfoilId)).then((res: AxiosResponse) => {

            const blob = new Blob([res.data], {type: 'text/csv', endings: 'native'});
            const url = window.URL.createObjectURL(blob);

            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = `${airfoilName.replaceAll(' ','')}_points.txt`;
            a.click();
             
            enqueueSnackbar('Downloaded the airfoils points successfully.', {variant: 'success'});
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while downloading the airfoils points.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{height: '100%'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.AIRFOILS}/>
                </div>
                <Stack spacing={3} style={{paddingLeft: '30px', width: '2060px'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Airfoils
                    </Typography>
                    <Stack 
                        direction='row' 
                        alignItems='stretch' 
                        justifyContent='flex-start'
                        spacing={10} 
                        style={{height: '100%'}}
                    >
                        <div style={{width:'475px', height:'800px'}}>
                            <DataGrid
                                columns={columns}
                                rows={airfoils}
                                onRowClick={(params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {
                                    if (!_.isEqual(_.get(selectedAirfoil, 'id', null), params.row.id)) {
                                        loadSelectedAirfoil(params.row.id);
                                    }
                                }}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
                                initialState={{
                                    sorting: {
                                        sortModel: [{field:'name', sort:'asc'}]
                                    }
                                }}
                            />
                        </div>
                        <div style={{width:'100%'}}>
                            <Scatter
                                options={options}
                                data={{datasets: [
                                    {
                                        label: 'Original points',
                                        data: selectedAirfoil === null?[]:selectedAirfoil.points,
                                        backgroundColor: 'rgba(255, 0, 0, 1)',
                                        showLine: true
                                    },
                                    {
                                        label: 'Interpolated points',
                                        data: selectedAirfoil === null?[]:selectedAirfoil.interpolatedPoints,
                                        backgroundColor: 'rgba(0, 0, 255, 1)',
                                        showLine: true
                                    },

                                ]}}
                                style={{width:'100%'}}
                            />
                        </div>
                        
                    </Stack>
                </Stack>
            </Stack>
            <Dialog
                open={isRegistrationOpen}
                onClose={() => setIsRegistrationOpen(false)}
                PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      maxWidth: '1000px'
                    }
                }}
            >
                <DialogContent style={{backgroundColor:'transparent'}}>
                    <AddAirfoil onRegistrationComplete={onAirfoilRegistrationComplete} airfoil={selectedAirfoil}/>
                </DialogContent>
            </Dialog>
            {confirmationProps && <ConfirmationDialog {...confirmationProps}/>}
        </>
    );
}

export default WithAuthenticate(Airfoils);