import React, {useCallback, useState, useEffect} from 'react';
import { Stack, TextField, Button, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';

import CircularSpinner from 'common/CircularSpinner';
import {get, post, put} from 'utils/api';
import { GET_API_URLS, POST_API_URLS, PUT_API_URLS } from 'constants/apiUrls';
import Dropzone from 'common/Dropzone';


export interface IReynoldsNumberDetail {
    id: number,
    reynoldsNumber: number
}

interface ReynoldsNumberPropertiesProps {
    onComplete: () => void,
    airfoilId: number
    reynoldsNumberDetail: IReynoldsNumberDetail | null
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export const ReynoldsNumberProperties = (props: ReynoldsNumberPropertiesProps) => {
    const {onComplete, airfoilId, reynoldsNumberDetail} = props;

    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const [reynoldsNumber, setReynoldsNumber] = useState<string>('');
    const [properties, setProperties] = useState<Array<{'cl':number, 'alpha':number, 'cd':number}>>([]);
    const [reynoldsNumberError, setReynoldsNumberError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fileType, setFileType] = useState<string>('CSV');
    const smallSX = {m:1, width: '18ch'};
    const fileTypes = [
        {
            key: 'CSV',
            value: 'CSV'
        },
        {
            key: 'XFLR5',
            value: 'XFLR5'
        }
    ]


    useEffect(() => {
        if (reynoldsNumberDetail !== null) {
            setIsLoading(true);
            get(GET_API_URLS.REYNOLDS_NUMBER_PROPERTIES(airfoilId, reynoldsNumberDetail.id), expireCallback).then ((res: AxiosResponse) => {
                if (res.status === 200) {
                    setReynoldsNumber(res.data.reynolds_number_properties.reynolds_number);
                    const _properties = res.data.reynolds_number_properties.properties;
                    setProperties(_properties.alpha_values.map((alpha:number, idx:number) => ({
                        cl: _properties.cd_values[idx],
                        alpha: alpha,
                        cd: _properties.cd_values[idx]
                    })));
                } else {
                    enqueueSnackbar('Error while retrieving the reynolds number properties', {variant: 'error'});
                }
            }).catch((error: AxiosError) => {
                enqueueSnackbar('Error while retrieving the reynolds number properties', {variant: 'error'});
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [reynoldsNumberDetail]);

    const expireCallback = () => {
        navigate('/login');
    }

    const addReynoldsNumberProperties = () => {
        if (reynoldsNumber === '') {
            enqueueSnackbar('Please enter a valid Reynolds number', {variant: 'error'});
            return;
        } else if (properties.length === 0) {
            enqueueSnackbar('Please upload Reynolds number properties text file', {variant: 'error'});
            return;
        }

        const data = {
            'reynolds_number': reynoldsNumber,
            'properties': properties
        }

        const apiCall = () =>  (reynoldsNumberDetail===null)?post(POST_API_URLS.REYNOLDS_NUMBER_PROPERTIES_ADD(airfoilId), data, expireCallback):
            put(PUT_API_URLS.REYNOLDS_NUMBER_PROPERTIES_UPDATE(airfoilId, reynoldsNumberDetail.id), data, expireCallback);

        apiCall().then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar(`${reynoldsNumberDetail?'Updated':'Added'} the Reynolds number properties successfully`, {variant: 'success'});
                onComplete();
            } else {
                enqueueSnackbar(`Error while ${reynoldsNumberDetail?'updating':'adding'} the Reynolds number properties`, {variant: 'error'});
            }
        }).catch((err: AxiosError) => {
            enqueueSnackbar(`Error while ${reynoldsNumberDetail?'updating':'adding'} the Reynolds number properties`, {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const onFileDrop = (acceptedFile: Array<File>) => {
        if (acceptedFile.length !== 1) {
            enqueueSnackbar('Please upload only one reynolds number properties text file', {variant: 'error'});
            return;
        }

        const file = acceptedFile[0];
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
            setIsLoading(true);

            if (event.target) {
                const fileText = event.target.result;
                if (fileText) {
                    let _properties:Array<{'cl':number, 'alpha':number, 'cd':number}> = [];
                    try {
                        const lines = (fileText as string).split('\n');

                        if (fileType === 'CSV') {
                            _properties = lines.map((line:string) => {
                                const splits = line.trim().split(',');
                                if (splits.length !== 3) {
                                    throw Error(`Could not find all properties - ${line}`);
                                }
    
                                return ({
                                    'alpha': parseFloat(splits[0]), 
                                    'cl': parseFloat(splits[1]), 
                                    'cd': parseFloat(splits[2])
                                });
                            });
                        } else {
                            const isValidLine = (line:string) => (
                                (line !== null) && (line !== '') && (line !== '\r')
                            );

                            const filteredLines = lines.slice(11).filter(isValidLine);

                            _properties = filteredLines.map((line:string) => {
                                const splits = line.trim().split(' ');
                                const filteredSplits = splits.filter(isValidLine);
                                const finalSplits = filteredSplits.slice(0, 3);
                                if (finalSplits.length !== 3) {
                                    throw Error(`Could not find all properties - ${line}`);
                                }

                                return ({
                                    'alpha': parseFloat(finalSplits[0]), 
                                    'cl': parseFloat(finalSplits[1]), 
                                    'cd': parseFloat(finalSplits[2])
                                });
                            })
                        }
                    } catch (error) {
                        enqueueSnackbar('Error while parsing the file', {variant:'error'})
                    }

                    setProperties(_properties);
                }
            }

            setIsLoading(false);
        };

        if (file) {
            fileReader.readAsText(file)
        }
    }

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        interaction: {
          mode: 'index' as const,
          intersect: false,
        },
        stacked: false,
        plugins: {
          title: {
            display: true,
            text: '(CL vs Alpha) & (CL vs CD)',
          },
          legend: {
            display: true,
          }
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'CL',
                }
            },
            y: {
                type: 'linear' as const,
                display: true,
                title: {
                    display: true,
                    text: 'Alpha (Degree)',
                },
                position: 'left' as const,
            },
            y1: {
                type: 'linear' as const,
                display: true,
                title: {
                    display: true,
                    text: 'CD',
                },
                position: 'right' as const,
                grid: {
                drawOnChartArea: false,
                },
            },
        },
    };

    const graphData = {
        labels: properties.map(property => property.cl),
        datasets: [
            {
                label: 'Alpha (Degree)',
                data: properties.map(property => property.alpha),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y',
            }, 
            {
                label: 'CD',
                data: properties.map(property => property.cd),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                yAxisID: 'y1',
            }
        ]
    }

    const sx = {m:1, width:'35ch'};
    return (
        <>
            {isLoading && <CircularSpinner/>}
            <div 
                style={{border: '1px solid #707070', 
                boxShadow:'0px 3px 6px #00000029', borderRadius: '20px', backgroundColor: 'white', color: 'black'}}            
            >
                <form>
                    <Stack spacing={2} style={{marginLeft:'50px', marginRight: '50px', marginTop:'50px'}}>
                        <fieldset style={{width: '800px', height: '100%', border: '1px solid #6D77B0', borderRadius: '12px',
                            padding: '12px 6px 6px 6px', marginLeft:'auto', marginRight:'auto'}}
                        >
                            <legend style={{marginLeft: '17px'}}>Reynolds Number Properties</legend>
                            <Stack spacing={2}>
                                <TextField
                                    error={!!reynoldsNumberError}
                                    sx={sx}
                                    label='Reynolds Number'
                                    value={reynoldsNumber}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = e.target.value;
                                        if (value==='' || (/^[0-9]*$/.test(value))) {
                                            setReynoldsNumber(value);
                                        }
                                    }}
                                    size='small'
                                    helperText={reynoldsNumberError}
                                    onBlur={() => {
                                        if ((reynoldsNumber === '') || (parseInt(reynoldsNumber) === 0)) {
                                            setReynoldsNumberError('Please enter a valid reynolds number');
                                        } else {
                                            setReynoldsNumberError('');
                                        }
                                    }}
                                />
                                <FormControl sx={smallSX} variant="outlined">
                                            <InputLabel
                                                id='airfoil-file-label'
                                                style={{marginLeft: '9px'}}
                                            >
                                                File Type
                                            </InputLabel>
                                            <Select
                                                id='fileType'
                                                label='File Type'
                                                value={fileType}
                                                onChange={(e: SelectChangeEvent<string>, child: React.ReactNode) => {
                                                    setFileType(e.target.value);
                                                }}
                                                size='small'
                                                style={{height:'43px', marginLeft: '9px'}}
                                            >
                                                {fileTypes.map((file, key) => {
                                                    return <MenuItem key={key} value={file.key}>{file.value}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                <div
                                    style={{marginLeft:'8px', marginRight:'8px'}}
                                >
                                    <Dropzone onDrop={onFileDrop} accept={{'text/plain': ['.txt']}}/>
                                </div>
                                {(properties.length > 0) && (
                                    <Line options={options} data={graphData}/>    
                                )}
                            </Stack>
                        </fieldset>
                        <Button 
                            variant='contained'
                            type='submit'
                            onClick={(e) =>{
                                e.preventDefault();
                                addReynoldsNumberProperties();
                            }}
                            style={{width:'344px', height: '45px', margin:'44px auto 30px auto'}}
                        >
                            {reynoldsNumberDetail?'Update':'Add'}
                        </Button>
                    </Stack>
                </form>
            </div>
        </>
    );
}

export default ReynoldsNumberProperties