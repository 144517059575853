import React, {useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridComparatorFn } from '@mui/x-data-grid';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { del, get, isLoggedIn, post, put } from 'utils/api';
import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from "common/NavigationBar";
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Page, UserTaskStatus } from 'constants/enums';
import Sidebar from 'common/Sidebar';
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';
import { DELETE_API_URLS, GET_API_URLS, POST_API_URLS, PUT_API_URLS } from 'constants/apiUrls';
import { AxiosError, AxiosResponse } from 'axios';
import { dateComparator } from 'utils/utils';
import TaskStatus from 'common/TaskStatus';


export const BackgroundTasks = () => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [backgroundTasks, setBackgroundTasks] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null); 
    const [pageSize, setPageSize] = useState<number>(25);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/login');
            return;
        }

        loadBackgroundTasks();
    }, []);

    const loadBackgroundTasks = () => {
        setIsLoading(true);
        get(GET_API_URLS.USER_TASKS(), expireCallback).then((res:AxiosResponse) => {
            setBackgroundTasks(res.data.user_tasks);
        }).catch((err: AxiosError) => {
            enqueueSnackbar('Error while loading background tasks information.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
        
    }

    const cancelBackgroundTask = (backgroundTaskId: number) => {
        setIsLoading(true);
        setConfirmationProps(null);
        put(PUT_API_URLS.USER_TASK_CANCEL(backgroundTaskId), {}, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Canceled the background task successfully', {variant: 'success'});
                loadBackgroundTasks();
            } else {
                enqueueSnackbar('Error while canceling the background task', {variant: 'error'});
            }
        }).catch((err: AxiosError) => {
            enqueueSnackbar('Error while canceling the background task', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const deleteBackgroundTask = (userTaskId: number) => {
        setIsLoading(true);
        del(DELETE_API_URLS.USER_TASK_DELETE(userTaskId), expireCallback).then((res:AxiosResponse) => {
            enqueueSnackbar('Canceled the CFD analysis task successfully', {variant: 'success'})
            setConfirmationProps(null);
            loadBackgroundTasks();
        }).catch((err: AxiosError) => {
            enqueueSnackbar('Error while loading CFD analysis tasks information', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    
    const columns: GridColDef[] = [
        {field: 'task_type', headerName: 'Task Type', width: 160, align: 'left', headerAlign: 'left', editable: false, 
            type: 'singleSelect', valueOptions: ['CFD', 'OFF_CONDITION_ANALYSIS'], renderCell: (params) => {
                switch (params.row.task_type) {
                    case 'CFD':
                        return 'CFD';
                    case 'OFF_CONDITION_ANALYSIS':
                        return 'Off-Condition Analysis'
                    default:
                        return 'Unknown Task'
                }
            }
        },
        {field: 'email', headerName: 'User Email', width: 150, align: 'left', headerAlign: 'left', editable: false, type: 'string'},
        {field: 'status', headerName: 'Status', width: 90, align: 'left', headerAlign: 'left', editable: false, 
            type: 'singleSelect', 
            valueOptions: [UserTaskStatus.PENDING, UserTaskStatus.STARTED, UserTaskStatus.SUCCESS, UserTaskStatus.FAILURE, UserTaskStatus.USER_CANCELED],
            renderCell: (params) => {
                return (
                    <TaskStatus status={params.row.status}/>
                );
            }
        },
        {field: 'additional_info', headerName: 'Additional Information', width: 580, align: 'left', headerAlign: 'left', 
            editable: false, type: 'string', renderCell: (params) => {
                const additional_info = JSON.stringify(params.row.additional_info)
                return (
                    <Tooltip title={additional_info} style={{width: '100%'}}>
                        <div>
                            {additional_info}
                        </div>
                    </Tooltip>
                );
            }
        },
        {field: 'start_time', headerName: 'Start Time', width: 160, align: 'left', headerAlign: 'left', editable: false, 
            type: 'date', renderCell: (params) => {
                const date = params.row.start_time;
                if (date == null) {
                    return <></>;
                }

                const formattedDate = new Date(date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                });
                return ( 
                    <div>{formattedDate}</div>
                );
            }, sortComparator: dateComparator},
        {field: 'end_time', headerName: 'End Time', width: 160, align: 'left', headerAlign: 'left', editable: false, 
            type: 'date', renderCell: (params) => {
                const date = params.row.end_time;
                if (date == null) {
                    return <></>;
                }

                const formattedDate = new Date(date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                });
                return ( 
                    <div>{formattedDate}</div>
                );
            }, sortComparator: dateComparator},
            {field: 'cancel_or_delete', align: 'left', headerAlign: 'left', headerName: 'Cancel/Delete', width: 110, sortable: false,
                renderCell: (params) => {
                    if (['PENDING', 'STARTED'].includes(params.row.status)) {
                        const onCancel = (e:any) => {
                            e.stopPropagation();
                            setConfirmationProps({
                                title: 'Cancel CFD Analysis',
                                body: 'Do you want to cancel the CFD analysis?',
                                onCancel: () => setConfirmationProps(null),
                                onConfirm: () => cancelBackgroundTask(parseInt(params.row.id))
                            });
                        }
                        
                        return (
                            <Tooltip title='Cancel'>
                                <IconButton onClick={onCancel} color='error'>
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        );
                    } else {
                        const onDelete = (e:any) => {
                            e.stopPropagation();
                            setConfirmationProps({
                                title: 'Delete CFD Analysis',
                                body: 'Do you want to delete the CFD analysis?',
                                onCancel: () => setConfirmationProps(null),
                                onConfirm: () => deleteBackgroundTask(parseInt(params.row.id))
                            });
                        }
                        
                        return (
                            <Tooltip title='Delete'>
                                <IconButton onClick={onDelete} color='error'>
                                    <DeleteForeverOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        );
                    }
                }
            }
    ];

    const Menubar = () => {
        return (
            <></>
        );
    };

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{width:'1500px'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.BACKGROUND_TASKS}/>
                </div>
                <Stack spacing={3} style={{paddingLeft: '30px', width: '100%'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Background Tasks
                    </Typography>
                    <DataGrid
                            autoHeight
                            columns={columns}
                            rows={backgroundTasks}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
                            sx={{
                                "& .MuiDataGrid-cell": {
                                padding: '2px 10px'
                                }
                            }}
                        />
                </Stack>
            </Stack>
            {confirmationProps && <ConfirmationDialog {...confirmationProps}/>}
        </>
    );

}

export default BackgroundTasks;