import React from 'react'
import { Stack, TextField } from "@mui/material"

import OutlinedInput from 'common/OutlinedInput';


interface ResultFieldsProps {
    title: string;
    efficiency: number,
    shaftPower: number,
    thrust: number,
    torque: number,
    finalChord: number,
    finalRPM: number,
    maxMachValue: number
}

export const ResultFields= (props: ResultFieldsProps) => {
    const {title, efficiency, shaftPower, thrust, torque, finalChord, finalRPM, maxMachValue} = props;
    
    const sx ={m:1, width: '25ch'};

    return (
        <>
        
        <fieldset style={{ width:'330px', border: '1px solid #6D77B0', borderRadius: '12px', padding: '5px 6px 14px 14px'}}>
                            <legend style={{marginLeft: '17px'}}>{title}</legend>
                            <Stack spacing={2}>
                                <OutlinedInput 
                                    label='Efficiency'
                                    value={efficiency.toFixed(2)}
                                    onValueChange={(newEfficiency) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip='Propulsive efficiency of the propeller η = Thrust Power / Shaft Power'
                                />
                                <OutlinedInput 
                                    label='Shaft Power (W)'
                                    value={shaftPower.toFixed(2)}
                                    onValueChange={(newShaftPower) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip='Estimated power needed to spin the shaft at the RPM under given loadings'
                                />
                                <OutlinedInput
                                    label='Thrust (lbf)'
                                    value={thrust.toFixed(3)}
                                    onValueChange={(newThrust) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip='Thrust generated by the blade'
                                />
                                <OutlinedInput
                                    label='Torque (lbf-in)'
                                    value={torque.toFixed(3)}
                                    onValueChange={(newTorque) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip='Torque of the blade'
                                />
                                <OutlinedInput
                                    label='Final Chord (in)'
                                    value={finalChord.toFixed(3)}
                                    onValueChange={(newFinalChord) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip={`Chord after optimization`}
                                />
                                <OutlinedInput
                                    label='Final RPM'
                                    value={finalRPM.toFixed(2)}
                                    onValueChange={(newFinalRPM) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip={`RPM after optimization`}
                                />
                                <OutlinedInput
                                    label='Max Mach Value'
                                    value={maxMachValue.toFixed(3)}
                                    onValueChange={(newMaxMachValue) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip='Maximum Mach value of airflow at any point on the blade. Mach > .7 increases error in calculations'
                                />
                            </Stack>
                        </fieldset>
        
        </>


    )
}