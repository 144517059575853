import { UserTaskStatus } from "constants/enums";

export interface TaskStatusProps {
    status: UserTaskStatus,
}
export const TaskStatus = (props: TaskStatusProps) => {
    const {status} = props;

    let color = '#f9a825';
    let label = 'Unknown Status';

    if (status === UserTaskStatus.PENDING) {
        color = '#FFA726';
        label = 'Pending';
    } else if (status === UserTaskStatus.STARTED) {
        color = '#0288D1';
        label = 'Started';
    } else if (status === UserTaskStatus.SUCCESS) {
        color = '#2E7D32';
        label = 'Success';
    } else if (status === UserTaskStatus.FAILURE) {
        color = '#D32F2F';
        label = 'Failure';
    } else if (status === UserTaskStatus.USER_CANCELED) {
        color = '#2FD3D3';
        label = 'Canceled';
    }

    return (
        <div style={{
            width: '100%', 
            height:'100%', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            color: 'white', 
            backgroundColor: color, 
            borderRadius: '6px'}}
        >
            {label}
        </div>
    );
}

export default TaskStatus;