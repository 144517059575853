import React, {useEffect, useState} from 'react';
import { Grid, Typography, Stack, Tooltip, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar} from 'notistack';
import { createSearchParams, useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { ExperimentStatus as ExperimentStatusEnum, Page } from 'constants/enums';
import CircularSpinner from 'common/CircularSpinner';
import { DELETE_API_URLS, GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import {get, del, postBinary} from 'utils/api';
import NavigationBar from 'common/NavigationBar';
import Sidebar, {MenuButton} from 'common/Sidebar';
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';
import WithAuthenticate from 'common/hoc/WithAuthenticate';
import { ExperimentStatus } from 'components/Dashboard/ExperimentStatus';
import { CompressOutlined } from '@mui/icons-material';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import { Link } from 'react-router-dom'


export const SweepAnalysis = () => {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const [experiments, setExperiments] = useState<any>([]);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [standardSelectionModel, setStandardSelectionModel] = useState<GridSelectionModel>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isTableDisabled, setIsTableDisabled] = useState<boolean>(false);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null); 
    const [pageSize, setPageSize] = useState<number>(15);

    const expireCallback = () => {
        navigate('/login');
    }

    const [standardPropellersData, setStandardPropellersData] = useState<Array<{
        id:number, title: string, hubRadius: number, tipRadius: number, reynoldsRef: number, desiredThrust: number,
        chord: number}>>([]);

    const goToDetailComparison = () => {

        if (standardSelectionModel.length + selectionModel.length < 2) {
            enqueueSnackbar('Select at least 2 experiments', {variant: 'error'});
            return;
        }

        if (standardSelectionModel.length === 0) {
            navigate("/analysis/compare/experiments", {
                state: {
                    experiment_details: experiments.filter((_item: { id: GridRowId; }) => selectionModel.includes(_item.id))
                }
              });
        } else {
            navigate("/analysis/compare/standard-propellers-experiments", {
                state: {
                    experiment_details: experiments.filter((_item: { id: GridRowId; }) => selectionModel.includes(_item.id)),
                    standard_propeller_details: standardPropellersData.filter((_item: { id: GridRowId; }) => standardSelectionModel.includes(_item.id))
                }
              });
        }

        
    }

    useEffect(() => {
        loadExperiments(); 
    }, []);

    const loadExperiments = () => {
        setIsLoading(true);
        get(GET_API_URLS.EXPERIMENTS_COMPLETED(), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                setExperiments(res.data.experiments);
            } else {
                enqueueSnackbar('Error while loading experiments.', {variant: 'error'});
            }
        }).catch((err:AxiosError) => {
            enqueueSnackbar('Error while loading experiments.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
            loadStandardPropellersData()
        })
    }
    
    const loadStandardPropellersData = () => {
        setIsLoading(true);
        get(GET_API_URLS.STANDARD_PROPELLERS_DATA(), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const _standardPropellersData = res.data['standard_propellers_data'].map((propellerData:any) => ({
                    id: propellerData.id,
                    title: propellerData.title,
                    hubRadius: propellerData.hub_radius,
                    tipRadius: propellerData.tip_radius,
                    reynoldsRef: propellerData.reynolds_ref,
                    desiredThrust: propellerData.desired_thrust,
                    chord: propellerData.chord
                }));
                setStandardPropellersData(_standardPropellersData);
            } else {
                enqueueSnackbar('Failed to fetch standard propellers data', {variant: 'error'});
            }
        }).catch((res: AxiosError) => {
            enqueueSnackbar('Failed to fetch standard propellers data', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width:60, editable: false, type: 'number'},
        {field: 'title', headerName: 'Project', width:150, editable: false, type: 'string'},
        {field: 'created_on', headerName: 'Date', width: 100, editable: false, type: 'date', renderCell: (params) => {
            const date = params.row.created_on;
            const formattedDate = new Date(date).toLocaleDateString('en-US');
            return ( 
                <div>{formattedDate}</div>
            );
        }},
        {field: 'hub_break_cl_lower', headerName: 'Hub Break', width: 100, editable: false, type: 'number', 
            renderCell: (params) => {
                return (
                    <div>{params.row.cl_design === 'CONSTANT'?'N/A':Number(params.row.hub_break_cl_lower)}</div>
                );
        }},
        {field: 'tip_break_cl_lower', headerName: 'Tip Break', width: 100, editable: false, type: 'number', 
            renderCell: (params) => {
                return (
                    <div>{params.row.cl_design === 'CONSTANT'?'N/A':Number(params.row.tip_break_cl_lower)}</div>
                );
        }},
        {field: 'tip_break_radius', headerName: 'Diameter', width: 100, editable: false, type: 'number', 
            renderCell: (params) => {
                return (
                    <div>{params.row.cl_design === 'CONSTANT'?'N/A':Number(params.row.tip_break_radius * 2)}</div>
                );
        }},
        {field: 'airfoil_name', headerName: 'Airfoil', width: 100, editable: false, type: 'number', 
            renderCell: (params) => {
                return (
                    <div>{params.row.airfoil_name}</div>
                );
        }},
      ];

      const standardPropellarscolumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width:60, editable: false, type: 'number'},
        {field: 'title', headerName: 'Title', headerAlign: 'center', align:'center', flex:1, editable: false, type: 'string'},
        {field: 'tipRadius', headerName: 'Diameter', headerAlign: 'center', align:'center', width: 120, editable: false, 
            type: 'number', renderCell: (params) => {
                return (
                    <div>{params.row.tipRadius?params.row.tipRadius * 2: ''}</div>
                );
            } 
        },
        {field: 'reynoldsRef', headerName: 'Reynolds Reference', headerAlign: 'center', align:'center', width: 180, editable: false, type: 'number'},
        {field: 'desiredThrust', headerName: 'Thrust', headerAlign: 'center', align:'center', width: 120, editable: false, type: 'number'},
        {field: 'chord', headerName: 'Chord', headerAlign: 'center', align:'center', width: 120, editable: false, type: 'number'}
    ];

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop:'30px', marginBottom: '33px'}}>
                <MenuButton 
                    Icon={CompareOutlinedIcon}
                    iconColor='black'
                    label='Compare'
                    onClick={goToDetailComparison}
                    disabled={false}
                />
            </Stack>
        );
    } 

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start'>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.SWEEP}/>
                </div>
                <Stack spacing={3} style={{paddingLeft: '30px', width: '100%'}} direction='column'>

                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Propeller's Selection
                    </Typography>
                    <Stack spacing={3} style={{paddingLeft: '5px', width: '100%'}} direction='row'>
                        <Stack spacing={3} style={{paddingLeft: '5px', width: '100%'}} direction='column'>
                            <Typography 
                                    style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                                >
                                    PDP Experiments
                                </Typography>
                                <DataGrid
                                    autoHeight
                                    columns={columns}
                                    rows={experiments}
                                    checkboxSelection
                                    selectionModel={selectionModel}
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);                          
                                    }}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
                                    sx={{
                                        "& .MuiDataGrid-cell": {
                                        padding: '2px 10px'
                                        }
                                    }}
                                    initialState={{
                                        sorting: {
                                            sortModel: [{field:'id', sort:'desc'}]
                                        }
                                    }}
                                />
                            </Stack>
                            <Stack spacing={3} style={{paddingLeft: '5px', paddingRight: '20px', width: '100%'}} direction='column'>
                            <Typography 
                                    style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                                >
                                    Standard Propeller Data
                                </Typography>
                                <DataGrid
                                    autoHeight
                                    columns={standardPropellarscolumns}
                                    rows={standardPropellersData}
                                    checkboxSelection
                                    selectionModel={standardSelectionModel}
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setStandardSelectionModel(newSelectionModel);                          
                                    }}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
                                    sx={{
                                        "& .MuiDataGrid-cell": {
                                        padding: '2px 10px'
                                        }
                                    }}
                                    initialState={{
                                        sorting: {
                                            sortModel: [{field:'id', sort:'desc'}]
                                        }
                                    }}
                                    //isRowSelectable={}
                                />
                            </Stack>
                    
                </Stack>
                    
                </Stack>
            </Stack>
            {confirmationProps && <ConfirmationDialog {...confirmationProps}/>}
        </>
    );
}

export default WithAuthenticate(SweepAnalysis);