import React, {useState, useCallback, useEffect} from 'react';
import {  FormControl, FormControlLabel, FormLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { Stack, Button, IconButton, Box, Switch, FormGroup } from '@mui/material';
import { Close as IconClose } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import * as _ from 'lodash';

import Dropzone from 'common/Dropzone';
import CircularSpinner from "common/CircularSpinner";
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';
import {postBinary, postFile, del, getBinary } from "utils/api";
import { DELETE_API_URLS, GET_API_URLS, POST_API_URLS } from "constants/apiUrls";
import { upload } from '@testing-library/user-event/dist/upload';
import { RotationDirection, Page } from 'constants/enums';
import STLDisplay from 'components/Results/STLDisplay';
import OutlinedInput from 'common/OutlinedInput';
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface MonolithicPropellerStlProps {
    experimentTitle: string,
    bladeCount: string | number,
    outerHubRadiusUpperLimit: string | number, 
    outerHubRadiusLowerLimit: string | number, 
}

export const MonolithicPropellerStl = (props: MonolithicPropellerStlProps) => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const params = useParams();
    const experimentId = parseInt(params['experimentId'] || '0');

    const {experimentTitle, bladeCount, outerHubRadiusLowerLimit, outerHubRadiusUpperLimit} = props;

    const [outerHubRadius, setOuterHubRadius] = useState<string>(`${(Number(outerHubRadiusUpperLimit) * (2/3)).toFixed(2)}`);
    const [innerHubRadius, setInnerHubRadius] = useState<string>(`${(Number(outerHubRadiusUpperLimit) * (1/3)).toFixed(2)}`);
    const [selectedRotationDirection, setSelectedRotationDirection] = useState<string>(RotationDirection.CLOCKWISE);
    const [stlFileURL, setSTLFileURL] = useState<string | null>(null);
    const [useWireframe, setUseWireframe] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const expireCallback = () => {
        navigate('/login');
    }

    
    const validationCheck = () => {
        if(Number(innerHubRadius) && Number(outerHubRadius) &&
        innerHubRadius != '' && outerHubRadius != '' &&
        Number(innerHubRadius) > 0 && Number(outerHubRadius) > 0 &&
        Number(outerHubRadius) <= Number(outerHubRadiusUpperLimit) &&
        Number(outerHubRadius) >= Number(outerHubRadiusLowerLimit) &&
        Number(innerHubRadius) < Number(outerHubRadius)){
            return true
        }
        return false
    }

    
    const theme = createTheme({
        components: {
          MuiSwitch: {
            styleOverrides: {
              switchBase: {
                // Controls default (unchecked) color for the thumb
                color: "#ccc"
              },
              colorPrimary: {
                "&.Mui-checked": {
                  // Controls checked color for the thumb
                  color: "#e0e0e0"
                }
              },
              track: {
                // Controls default (unchecked) color for the track
                opacity: 0.2,
                backgroundColor: "#e0e0e0",
                ".Mui-checked.Mui-checked + &": {
                  // Controls checked color for the track
                  opacity: 0.7,
                  backgroundColor: "#e0e0e0"
                }
              }
            }
          }
        }
    });
    
    
    const generatePropeller = () => {
        setIsLoading(true);
        const inputData = {
            blade_count: Number(bladeCount),
            outer_hub_radius: Number(outerHubRadius),
            inner_hub_radius: Number(innerHubRadius),
            counter_clockwise_rotation: (selectedRotationDirection === RotationDirection.COUNTER_CLOCKWISE)
        };
        postBinary(POST_API_URLS.EXPERIMENT_PROPELLER_STL_EXPORT(experimentId), inputData, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/sla', endings: "native" });
                const url = window.URL.createObjectURL(blob);
                setSTLFileURL(url);
            } else {
                enqueueSnackbar('Error while loading the Propeller STL file.', { variant: 'error' });
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while loading the Propeller STL file.', { variant: 'error' });
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(()=>{
        if (validationCheck()){
            generatePropeller();
        }
    },[outerHubRadius,innerHubRadius,selectedRotationDirection])


    const exportSTLFile = () => {
        setIsLoading(true);
        if (validationCheck()){
            if (stlFileURL) {
                const a = document.createElement("a");
                document.body.appendChild(a);
                a.href = stlFileURL;
                a.download = `${experimentTitle}_Monolithic_Propeller.stl`;
                a.click();
            }  
        } else{
            enqueueSnackbar('Invalid Input', { variant: 'error' });
        }
        setIsLoading(false);       
    };


    return (
        <>
            {
                isLoading && <CircularSpinner />
            }
            <div 
                style={{width: '650px', border: '1px solid #707070', boxShadow:'0px 3px 6px #00000029', 
                borderRadius: '20px', backgroundColor: 'white', color: 'black', padding: '10px', overflow: 'visible'}}            
            >
                <Stack spacing={2}>
                    <Stack alignItems='center'>
                        {stlFileURL && 
                            <STLDisplay 
                                file={stlFileURL} 
                                useWireFrame={useWireframe} 
                                width='600px'
                                height='350px'
                            />
                        } 
                    </Stack>
                    {!stlFileURL &&
                        <div style={{width:'500px', height:'350px'}}></div>
                    }
                    <Stack position={"absolute"} marginLeft={'20px'} marginTop={'10px'} style={{paddingLeft: '30px'}}>
                        <ThemeProvider theme={theme}>
                            <FormGroup>
                                <FormControl sx={{m:1, width: '22ch'}} variant="outlined" style={{marginTop:'20px'}}>
                                    <InputLabel
                                        id='rotation-direction-label'
                                        style={{fontSize:'17px'}}
                                    >
                                        Rotation Direction
                                    </InputLabel>
                                    <Select
                                        id='rotation-direction'
                                        labelId='rotation-direction-label'
                                        label='Rotation Direction'
                                        value={selectedRotationDirection}
                                        onChange={(e) => {
                                            setSelectedRotationDirection(e.target.value as string);
                                        }}
                                        size='small'
                                        style={{height:'43px'}}
                                    >
                                        <MenuItem value={RotationDirection.CLOCKWISE}>{RotationDirection.CLOCKWISE}</MenuItem>
                                        <MenuItem value={RotationDirection.COUNTER_CLOCKWISE}>{RotationDirection.COUNTER_CLOCKWISE}</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControlLabel 
                                    label={<Box component="div" fontSize={12}>Wireframe</Box>}
                                    color={'#212121'}
                                    control={<Switch checked={useWireframe} onChange={e=>setUseWireframe(e.target.checked)}/>}
                                    style={{marginLeft:'0px'}}
                                />
                            </FormGroup>
                        </ThemeProvider>
                    </Stack>
                    <Stack spacing={2} direction='row' justifyContent='center'>
                        <OutlinedInput
                            label='Outer Hub Radius'
                            value={outerHubRadius}
                            onValueChange={(newOuterHubRadius:string) => setOuterHubRadius(newOuterHubRadius)}
                            readOnly={false}
                            style={{width:'250px', height: '60px'}}
                            tooltip={`Distance from axis of rotation to the outer face of the hub. Outer Radius Range = [${Number(outerHubRadiusLowerLimit).toFixed(3)},${Number(outerHubRadiusUpperLimit).toFixed(3)}]`}
                        />
                        <OutlinedInput
                            label='Inner Hub Radius'
                            value={innerHubRadius}
                            onValueChange={(newInnerHubRadius:string) => setInnerHubRadius(newInnerHubRadius)}
                            readOnly={false}
                            style={{width:'250px', height: '60px'}}
                            tooltip='Distance from axis of rotation to the inner face of the hub.'
                        />
                    </Stack>
                    <Button
                        variant='contained'
                        onClick={() => exportSTLFile()}
                        style={{marginTop: '30px'}}
                        disabled={!validationCheck()}
                    >
                        DOWNLOAD
                    </Button>
                </Stack>            
            </div>
        </>
    );
}

export default MonolithicPropellerStl;