import React, {useState, useEffect} from 'react';
import { AxiosResponse, AxiosError } from "axios";
import { useSnackbar} from 'notistack';
import { Grid, Stack, Tooltip } from '@mui/material';
import CloudQueueRoundedIcon from '@mui/icons-material/CloudQueueRounded';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { useNavigate, useParams } from "react-router-dom";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CycloneIcon from '@mui/icons-material/Cyclone';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssignmentIcon from '@mui/icons-material/Assignment';

import {get} from 'utils/api';
import { GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import { Page } from 'constants/enums';


interface SidebarProps {
    Menubar: React.ElementType,
    ActivePage: Page
}

interface MenuButtonProps {
    Icon: React.ElementType,
    iconColor: string,
    label: string,
    onClick: () => void,
    disabled: boolean
}

export const MenuButton = (props: MenuButtonProps) => {
    const {Icon, iconColor, label, onClick, disabled} = props;

    const [isHovering, setIsHovering] = useState<boolean>(false);

    return (
        <div 
            style={{ position:'relative', cursor: 'default', marginTop: '10px' }}
            onMouseLeave={() => setIsHovering(false)}
            onMouseEnter={() => setIsHovering(true)}
        >
            <Icon
                style={{fontSize: '20px', color:(disabled?'#E9E9E9':iconColor)}} 
                onClick={() => disabled || onClick()}
            />
            { isHovering && !disabled && (
                <div 
                    style={{display:'flex', cursor:'pointer', position: 'absolute', left: '1px', top:'-4px',
                        color: 'black', backgroundColor:'rgba(0,0,0,0.1)', width: '225px', height: '28px',
                        alignItems:'center', background: '#FFFFFF 0% 0% no-repeat padding-box', opacity: '0.8', 
                        boxShadow: '3px 3px 6px #00000029', borderRadius: '2px', zIndex: '2' }}
                    onClick={() => disabled||onClick()}
                >
                    <Icon style={{fontSize:'20px', color: iconColor, marginLeft: '14px'}}/>
                    <div style={{marginLeft:'20px'}}>{label}</div>
                </div>    
            )}
        </div>
    );
}

export const Sidebar = (props: SidebarProps) => {
    const {Menubar, ActivePage} = props;

    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const experimentId = params['experimentId'];

    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [experimentResult,setExperimentResult] = useState<string | null>(null);

    const expireCallback = () => {
        navigate('/login');
    }
    
    useEffect(() => {
        get(GET_API_URLS.ADMIN_CHECK(), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                setIsAdmin(res.data.is_admin);
            } else {
                enqueueSnackbar("Error while verifying user's role.", {variant: 'error'});
            }
        }).catch((err:AxiosError) => {
            enqueueSnackbar("Error while verifying user's role.", {variant: 'error'});
        })
        if(experimentId){
            get(GET_API_URLS.EXPERIMENT(Number(experimentId)), expireCallback).then((res:AxiosResponse) => {
                if (res.status === 200) {
                    setExperimentResult(res.data.experiment['result']);
                } else {
                    enqueueSnackbar('Error while loading the experiment.', {variant: 'error'});    
                }
            }).catch((res:AxiosError) => {
                enqueueSnackbar('Error while loading the experiment.', {variant: 'error'});
            })
        }
    }, []);

    const navigateExperiments = () => {
        navigate('/dashboard');
    }

    const navigateInputs = () => {
        navigate(`/inputs/${experimentId}`);
    }

    const navigateOptimizer = () => {
        navigate(`/optimizer/${experimentId}`);
    }

    const navigateResults = () => {
        navigate(`/results/${experimentId}`);
    }

    const navigateQueue = () => {
        navigate('/queue');
    }

    const navigateAnalysis = () => {
        navigate('/analysis');
    }

    const navigateAirfoil = () => {
        navigate('/airfoils');
    }

    const navigateHubAttachment = () => {
        navigate('/hub-attachments');
    }

    const navigateStandardPropellerData = () => {
        navigate('/standard-propellers-data');
    }

    const navigateBackgroundTasks = () => {
        navigate('/background-tasks')
    }

    const navigateAdmin = () => {
        navigate('/admin');
    }
    return (
        <Stack direction='row' style={{width: '50px', height: '100%'}}>
            <Stack style={{width: '50px', height: '100%', }}>
                <Menubar />
                <hr style={{width:'100%', marginBottom:'15px'}} />
                <MenuButton 
                    Icon={AnalyticsOutlinedIcon}  
                    iconColor={ActivePage===Page.DASHBOARD?'#FFA726':'black'} 
                    label='Experiments' 
                    onClick={navigateExperiments} 
                    disabled={false}
                />
                <MenuButton 
                    Icon={ModeOutlinedIcon}  
                    iconColor={ActivePage===Page.INPUTS?'#FFA726':'black'} 
                    label='Inputs' 
                    onClick={navigateInputs} 
                    disabled={!experimentId}
                />
                <MenuButton 
                    Icon={SettingsSuggestOutlinedIcon}  
                    iconColor={ActivePage===Page.OPTIMIZER?'#FFA726':'black'} 
                    label='Optimizer' 
                    onClick={navigateOptimizer} 
                    disabled={!experimentId}
                />
                <MenuButton 
                    Icon={AssignmentTurnedInIcon}  
                    iconColor={ActivePage===Page.RESULTS?'#FFA726':'black'} 
                    label='Results' 
                    onClick={navigateResults} 
                    disabled={!experimentId || experimentResult==null}
                />
                <MenuButton 
                    Icon={CloudQueueRoundedIcon}  
                    iconColor={ActivePage===Page.QUEUE?'#FFA726':'black'} 
                    label='Queue' 
                    onClick={navigateQueue} 
                    disabled={false}
                />
                <MenuButton
                    Icon={CompareArrowsIcon
                    }
                    iconColor={ActivePage===Page.SWEEP?'#FFA726':'black'} 
                    label='Propeller Analysis' 
                    onClick={navigateAnalysis} 
                    disabled={false}
                />
                <MenuButton
                    Icon={DriveFolderUploadOutlinedIcon}
                    iconColor={ActivePage===Page.AIRFOILS?'#FFA726':'black'} 
                    label='Airfoil' 
                    onClick={navigateAirfoil} 
                    disabled={false}
                />
                <MenuButton
                    Icon={CycloneIcon}
                    iconColor={ActivePage===Page.HUB_ATTACHMENTS?'#FFA726':'black'} 
                    label='Hub Attachment' 
                    onClick={navigateHubAttachment} 
                    disabled={false}
                />
                <MenuButton
                    Icon={InventoryIcon}
                    iconColor={ActivePage===Page.STANDARD_PROPELLERS_DATA?'#FFA726':'black'} 
                    label='Standard Propellers' 
                    onClick={navigateStandardPropellerData} 
                    disabled={false}
                />
                <MenuButton
                    Icon={AssignmentIcon}
                    iconColor={ActivePage===Page.BACKGROUND_TASKS?'#FFA726':'black'} 
                    label='Background Tasks' 
                    onClick={navigateBackgroundTasks} 
                    disabled={false}
                />
                { isAdmin && (
                    <MenuButton 
                        Icon={AdminPanelSettingsOutlinedIcon}  
                        iconColor={ActivePage===Page.ADMIN?'#FFA726':'black'} 
                        label='Admin' 
                        onClick={navigateAdmin} 
                        disabled={false}
                    />            
                )}
            </Stack>
            <hr style={{left:'50px',width: '0px', bottom:'0', height: '100vh', position:'fixed'}}/>
            <hr style={{left:'50px',width: '0px', top:'0', height: '100vh', position:'fixed'}}/>
        </Stack>
    );
}

export default Sidebar;