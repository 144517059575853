import React from "react";
import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";
import Login from "components/User/Login";
import PasswordChange from "components/User/PasswordChange";
import Visualizer from "components/stlVisualizer/visualizer";
import Dashboard from "components/Dashboard";
import ExperimentInputs from "components/ExperimentInputs";
import OptimizerSettings from "components/OptimizerSettings";
import Results from "components/Results";
import Admin from "components/User/Admin";
import ExperimentLogs from "components/ExperimentLogs";
import ExperimentsQueue from "components/ExperimentsQueue";
import Profile from "components/User/Profile";
import CompareResults from "components/Results/CompareResults";
import DetailResultsComparison from "components/Results/CompareResults/DetailResultsComparison";
import Airfoils from "components/Airfoils";
import AirfoilDetail from "components/Airfoils/AirfoilDetail";
import SweepAnalysis from "components/OffConditionAnalysis";
import SweepSingleExperiment from "components/OffConditionAnalysis/SweepSingleExperiment";
import SweepMultipleExperiments from "components/OffConditionAnalysis/SweepMultipleExperiments";
import HubAttachments from "components/HubAttachments";
import StandardPropellersData from "components/StandardPropellersData";
import AddStandardPropellerData from "components/StandardPropellersData/AddStandardPropellerData";
import SweepStandardMultipleExperiments from "components/OffConditionAnalysis/SweepStandardMultipleExperiments";
import Help from "components/Help";
import BackgroundTasks from "components/BackgroundTasks";
import CFDAnalysisDashboard from "components/Results/CFDAnalysisDashboard";
import CFDAnalysisDetail from "components/Results/CFDAnalysisDashboard/CFDAnalysisDetail";

export default function RouterConfig() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/inputs" element={<ExperimentInputs/>}/>
        <Route path="/inputs/:experimentId" element={<ExperimentInputs/>}/>
        <Route path="/optimizer/:experimentId" element={<OptimizerSettings/>}/>
        <Route path="/logs/:experimentId" element={<ExperimentLogs/>}/>
        <Route path="/queue" element={<ExperimentsQueue/>}/>
        <Route path="/results/:experimentId" element={<Results/>}/>
        <Route path="/airfoils" element={<Airfoils/>}/>
        <Route path="/airfoils/:airfoilId" element={<AirfoilDetail/>}/>
        <Route path="/hub-attachments" element={<HubAttachments/>}/>
        <Route path="/standard-propellers-data" element={<StandardPropellersData/>}/>
        <Route path="/standard-propellers-data/new" element={<AddStandardPropellerData/>}/>
        <Route path="/standard-propellers-data/:standardPropellerDataId" element={<AddStandardPropellerData/>}/>
        <Route path="/admin" element={<Admin/>}/>
        <Route path="/profile" element={<Profile/>}/>
        <Route path="/password-change" element={<PasswordChange />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
        <Route path="/compare/results/:experimentId" element={<CompareResults />} />
        <Route path="/compare/results" element={<CompareResults />} />
        <Route path="/compare/results/detail" element={<DetailResultsComparison />} />
        <Route path="/analysis/:experimentId" element={<SweepSingleExperiment />} />
        <Route path="/analysis/:experimentId/cfd" element={<CFDAnalysisDashboard/>}/>
        <Route path="/analysis/:experimentId/cfd/:cfdAnalysisId" element={<CFDAnalysisDetail/>}/>
        <Route path="/analysis" element={<SweepAnalysis />} />
        <Route path="/analysis/compare/experiments" element={<SweepMultipleExperiments />} />
        <Route path="/analysis/compare/standard-propellers-experiments" element={<SweepStandardMultipleExperiments />} />
        <Route path="/background-tasks" element={<BackgroundTasks/>}/>
        <Route path="/help" element={<Help/>} />
      </Routes>
    </BrowserRouter>
  );
}
