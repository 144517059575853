import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useSnackbar} from 'notistack';
import * as _ from 'lodash';
import { Button, Checkbox, FormControlLabel, IconButton, Stack, Typography } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Close, SaveAsOutlined } from '@mui/icons-material';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {ReactGrid, Column, Row, CellChange, TextCell} from '@silevis/reactgrid';

import Sidebar, { MenuButton } from 'common/Sidebar';
import NavigationBar from 'common/NavigationBar';
import CircularSpinner from 'common/CircularSpinner';
import { Page } from 'constants/enums';
import OutlinedInput from 'common/OutlinedInput';
import { isNumeric } from 'utils/utils';
import Dropzone from 'common/Dropzone';
import { del, get, getBinary, postFile, putFile } from 'utils/api';
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';
import { DELETE_API_URLS, GET_API_URLS, POST_API_URLS, PUT_API_URLS } from 'constants/apiUrls';
import { AxiosError, AxiosResponse } from 'axios';
import WithAuthenticate from 'common/hoc/WithAuthenticate';


interface IStaticTestData {
    rpm: string,
    thrust: string,
    torque: string,
    power: string,
}


export const AddStandardPropellerData = () => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const params = useParams();

    const standardPropellerDataId = params['standardPropellerDataId']?parseInt(params['standardPropellerDataId']):undefined;

    const [title, setTitle] = useState<string>('');
    const [designParameters, setDesignParameters] = useState({
        hubRadius: '',
        tipRadius: '', 
        bladesCount: '',
        sectionsCount: '',
        tipType: '',
        thrust: '',
        reynoldsRef: '',
        maxCL: '',
        minCL: '',
        clDesign: '',
        hubBreak: '',
        tipBreak: '',
        airfoil: '',
        chord: '',
        isHybridBlade: false,
        hybridBladeDetail: {
            hubAirfoil: '',
            tipAirfoil: '',
            transition_rOverRadius: '',
            transitionSmoothing_rOverRadius: ''
        },
    });
    const [operationalParameters, setOperationalParameters] = useState({
        atmosphere: '',
        altitude: '',
        density: '',
        viscosity: '',
        soundSpeed: '',
        freestreamVelocity: '',
    });
    const [additionalDetails, setAdditionalDetails] = useState<Array<{field:string, value: string}>>([]);
    const [staticTestData, setStaticTestData] = useState<IStaticTestData[]>([
        {rpm: '', thrust: '', torque: '', power: ''},
    ]);

    const [uploadedFiles, setUploadedFiles] = useState<Array<{filename:string, mime:string}>>([]);
    const [uploadCandidateFiles, setUploadCandidateFiles] = useState<Array<File>>([]);

    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        if (standardPropellerDataId !== undefined) {
            loadStandardPropellerData();
        }
    }, [])

    const addAdditionalDetail = () => {
        setAdditionalDetails([...additionalDetails, {field:'', value:''}]);
    }

    const onAdditionalDetailChange = (index: number, key: 'field' | 'value', newValue: string) => {
        let _additionalDetails = [...additionalDetails];
        _additionalDetails[index][key] = newValue;

        setAdditionalDetails(_additionalDetails);
    }

    const onAdditonalDetailDelete = (index:number) => {
        const _additionalDetails = additionalDetails.filter((additionalDetail, idx) => index!==idx);

        setAdditionalDetails(_additionalDetails);
    }

    const staticTestDataColumns = [
        {columnId: 'rpm', width: 180},
        {columnId: 'thrust', width: 180},
        {columnId: 'torque', width: 180},
        {columnId: 'power', width: 180},
    ];

    const staticTestDataHeaderRow: Row = {
        rowId: 'header',
        cells: [
            {type: 'header', text: 'Propeller Speed (RPM)'},
            {type: 'header', text: 'Thrust (lbf)'},
            {type: 'header', text: 'Torque (in-lbf)'},
            {type: 'header', text: 'Mechanical Power (W)'},
        ]
    };

    const getStaticTestDataRow = (_staticTestData: IStaticTestData[]) => ([
        staticTestDataHeaderRow,
        ..._staticTestData.map<Row>((data, idx) => ({
            rowId: idx,
            cells: [
                {type:  'text', text: data.rpm},
                {type:  'text', text: data.thrust},
                {type:  'text', text: data.torque},
                {type:  'text', text: data.power},
            ]
        }))
    ]);

    const handleStaticTestDataChanges = (cellChanges: CellChange[]) => {
        const newStaticTestData = _.cloneDeep(staticTestData);
        let hasInvalidInput = false;
        cellChanges.forEach(change => {
            const staticTestDataIdx = change.rowId as number;
            const staticTestDataFieldName = change.columnId as keyof IStaticTestData;
            let newValue = (change.newCell as TextCell).text.trim();
            if (newValue && !isNumeric(newValue)) {
                hasInvalidInput = true;
            } else {
                newStaticTestData[staticTestDataIdx][staticTestDataFieldName] = newValue;
            }
        });
        
        if (hasInvalidInput) {
            enqueueSnackbar('Please enter number or leave it blank', {variant: 'error'});
        }
        setStaticTestData(newStaticTestData);
    }

    const  onDrop = (acceptedFiles: Array<File>) => {
        if (acceptedFiles.length === 0) {
            enqueueSnackbar('Please upload at least one file', {variant: 'error'})
            return;
        }


        const isFileDuplicate = (file:File) => (uploadCandidateFiles.filter(uploadCandidateFile => (
            (uploadCandidateFile.name === file.name) && (uploadCandidateFile.type === file.type))).length > 0) || (
                uploadedFiles.filter(uploadedFile => (
                    (uploadedFile.filename === file.name) && (uploadedFile.mime === file.type))).length > 0
            );
        

        const validFiles = acceptedFiles.filter(acceptedFile => !isFileDuplicate(acceptedFile));

        if (validFiles.length !== acceptedFiles.length) {
            enqueueSnackbar('Duplicate files were found', {variant:'warning'});
        }

        setUploadCandidateFiles([...uploadCandidateFiles, ...validFiles]);
    }

    const loadStandardPropellerData = () => {
        setIsLoading(true);
        get(GET_API_URLS.STANDARD_PROPELLER_DATA(standardPropellerDataId as number)).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const data=res.data['standard_propeller_data'];
                setTitle(data.title?data.title:'');
                setDesignParameters({
                    hubRadius: data['hub_radius']?`${Number(data['hub_radius'])}`: '',
                    tipRadius:data['tip_radius']?`${Number(data['tip_radius'])}`: '', 
                    bladesCount: data['num_of_blades']?`${Number(data['num_of_blades'])}`: '',
                    sectionsCount: data['num_of_sections']?`${Number(data['num_of_sections'])}`: '',
                    tipType: data['tip_type'],
                    thrust: data['desired_thrust']?`${Number(data['desired_thrust'])}`: '',
                    reynoldsRef: data['reynolds_ref']?`${Number(data['reynolds_ref'])}`: '',
                    maxCL: data['max_cl']?`${Number(data['max_cl'])}`: '',
                    minCL: data['min_cl']?`${Number(data['min_cl'])}`: '',
                    clDesign: data['cl_design'],
                    hubBreak: data['hub_break']?`${Number(data['hub_break'])}`: '',
                    tipBreak: data['tip_break']?`${Number(data['tip_break'])}`: '',
                    airfoil: data['airfoil'],
                    chord: data['chord']?`${Number(data['chord'])}`: '',
                    isHybridBlade: data['is_hybrid_blade'],
                    hybridBladeDetail: data['hybrid_blade_detail'],
                });
                setOperationalParameters({
                    atmosphere: data['atmosphere'],
                    altitude: data['altitude']?`${Number(data['altitude'])}`: '',
                    density: data['air_density']?`${Number(data['air_density'])}`: '',
                    viscosity: data['air_viscosity']?`${Number(data['air_viscosity'])}`: '',
                    soundSpeed: data['sound_speed']?`${Number(data['sound_speed'])}`: '',
                    freestreamVelocity: data['freestream_velocity']?`${Number(data['freestream_velocity'])}`: '',
                });
                setAdditionalDetails(data['additional_details']?data['additional_details']:[]);
                setStaticTestData([
                    ...(data['static_test_data']?data['static_test_data']:[]), 
                    {rpm: '', thrust: '', torque: '', power: ''}
                ]);

                loadStandardPropellerDataFiles();
            } else {
                enqueueSnackbar('Error while loading the standard propeller data', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while loading the standard propeller data', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const getFormattedStandardPropellerData = () => ({
        title: title.trim(),
        hub_radius: parseFloat(designParameters.hubRadius.trim()),
        tip_radius: parseFloat(designParameters.tipRadius.trim()),
        desired_thrust: parseFloat(designParameters.thrust.trim()),
        num_of_blades: parseFloat(designParameters.bladesCount.trim()),
        reynolds_ref: parseFloat(designParameters.reynoldsRef.trim()),
        num_of_sections: parseFloat(designParameters.sectionsCount.trim()),
        tip_type: designParameters.tipType.trim(),
        max_cl: parseFloat(designParameters.maxCL.trim()),
        min_cl: parseFloat(designParameters.minCL.trim()),
        cl_design: designParameters.clDesign.trim(),
        hub_break: parseFloat(designParameters.hubBreak.trim()),
        tip_break: parseFloat(designParameters.tipBreak.trim()),
        chord: parseFloat(designParameters.airfoil.trim()),
        airfoil: designParameters.airfoil.trim(),
        is_hybrid_blade: designParameters.isHybridBlade,
        hybrid_blade_detail: designParameters.hybridBladeDetail,
        atmosphere: operationalParameters.atmosphere.trim(),
        altitude: parseFloat(operationalParameters.altitude.trim()),
        freestream_velocity: parseFloat(operationalParameters.freestreamVelocity.trim()),
        air_density: parseFloat(operationalParameters.density.trim()),
        air_viscosity: parseFloat(operationalParameters.viscosity.trim()),
        sound_speed: parseFloat(operationalParameters.soundSpeed.trim()),
        additional_details: additionalDetails.filter(additionalDetail => (
            (additionalDetail.field.trim().length !== 0) || (additionalDetail.value.trim().length !== 0))),
        static_test_data: staticTestData.filter(data => (
            (data.rpm.trim().length !== 0) || 
            (data.thrust.trim().length !== 0) || 
            (data.torque.trim().length !== 0) || 
            (data.power.trim().length !== 0)
        ))
    })

    const addStandardPropellerData = () => {
        setIsLoading(true);
        const formData = new FormData();
        const formattedStandardPropellerData = getFormattedStandardPropellerData();
        formData.append('data', JSON.stringify(formattedStandardPropellerData));
        uploadCandidateFiles.forEach(file => formData.append('files', file));

        postFile(POST_API_URLS.STANDARD_PROPELLER_DATA_ADD(), formData, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Added the standard propeller data successfully.', {variant: 'success'});
                navigate(`/standard-propellers-data/${res.data.standard_propeller_data_id}`);
            } else {
                enqueueSnackbar('Error while adding the standard propeller data.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while adding the standard propeller data.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const updateStandardPropellerData = () => {
        setIsLoading(true);
        const formData = new FormData();
        const formattedStandardPropellerData = getFormattedStandardPropellerData();
        formData.append('data', JSON.stringify(formattedStandardPropellerData));
        uploadCandidateFiles.forEach(file => formData.append('files', file));

        putFile(PUT_API_URLS.STANDARD_PROPELLER_DATA_UPDATE(standardPropellerDataId as number), formData, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Updated the standard propeller data successfully.', {variant: 'success'});
                loadStandardPropellerData();
                loadStandardPropellerDataFiles();
                setUploadCandidateFiles([]);
            } else {
                enqueueSnackbar('Error while updating the standard propeller data.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while updating the standard propeller data.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const loadStandardPropellerDataFiles = () => {
        setIsLoading(true);
        get(GET_API_URLS.STANDARD_PROPELLER_DATA_FILES(standardPropellerDataId as number), expireCallback).then((res: AxiosResponse) => {
            if (res.status === 200) {
                setUploadedFiles(res.data['standard_propeller_data_files']);
            } else {
                enqueueSnackbar('Error while loading the standard propeller data files.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while loading the standard propeller data files.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const onFileDownload = (fileId: number, filename: string, mime: string) => {
        setIsLoading(true);
        getBinary(GET_API_URLS.STANDARD_PROPELLER_DATA_FILE(standardPropellerDataId as number, fileId)).then((res: AxiosResponse) => {
            const blob = new Blob([res.data], {type: mime, endings: 'native'});
            const url = window.URL.createObjectURL(blob);

            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = filename;
            a.click();
             
            enqueueSnackbar('Downloaded the file successfully.', {variant: 'success'});
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while downloading the file.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const deleteFile = (fileId: number) => {
        setIsLoading(true);
        del(DELETE_API_URLS.STANDARD_PROPELLER_DATA_FILE_DELETE(standardPropellerDataId as number, fileId)).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Deleted the file successfully.', {variant: 'success'});
            } else {
                enqueueSnackbar('Error while deleting the file.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while deleting the file.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
            setConfirmationProps(null);
            loadStandardPropellerData();
        })
    }

    const uploadedFilesColumns: GridColDef[] = [
        {field: 'filename', headerName: 'Filename', headerAlign: 'center', align: 'center', flex: 1, editable: false, type: 'string'},
        {field: 'download', headerName: 'Download', headerAlign: 'center', align: 'center', width: 90, sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton 
                        onClick={() => {
                            onFileDownload(params.row.id, params.row.filename, params.row.mime)}
                        }
                    >
                        <SaveAltOutlinedIcon/>
                    </IconButton>
                );
            }
        },
        {field: 'delete', headerName: 'Delete', headerAlign: 'center', align: 'center', width: 90, sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton 
                        onClick={() => {
                            setConfirmationProps({
                                title: 'Delete File',
                                body: 'Do you want to delete the file?',
                                onCancel: () => setConfirmationProps(null),
                                onConfirm: () => deleteFile(params.row.id)
                            })
                        }}
                        color='error'
                    >
                        <DeleteForeverOutlinedIcon/>
                    </IconButton>
                );
            }
        }
    ]

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop:'20px', marginBottom: '15px'}}>
                <MenuButton
                    Icon={standardPropellerDataId?SaveOutlinedIcon:SaveAsOutlined}
                    iconColor='black'
                    label='Save'
                    onClick={() => standardPropellerDataId?updateStandardPropellerData():addStandardPropellerData()}
                    disabled={false}
                />
            </Stack>
        );
    };

    const smallSX = {m:1, width: '18ch'};

    return (
        <>
            {isLoading && <CircularSpinner />}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{height:'100%'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.STANDARD_PROPELLERS_DATA}/>
                </div>

                <div style={{minWidth: '1196px', maxWidth: '1196px', paddingLeft:'30px'}}>
                    <Stack spacing={3} style={{minWidth: '1210px', maxWidth: '1210px'}}>
                        <Typography style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}>
                            Standard Propeller Data
                        </Typography>
                        <Stack spacing={2}>
                            <Stack direction='row' spacing={13}>
                                <Stack spacing={2} justifyContent='space-between'>
                                    <fieldset className='input-grouping'>
                                        <legend className='input-grouping-label'>Project</legend>
                                            <OutlinedInput
                                                label='Title'
                                                value={title}
                                                onValueChange={(newTitle) => setTitle(newTitle)}
                                                readOnly={false}
                                                style={{width:'552px', height: '60px'}}
                                                tooltip='title'
                                            />
                                    </fieldset>
                                    <fieldset className='input-grouping'>
                                        <legend className='input-grouping-label'>Geometry</legend>
                                        <Stack spacing={2}>
                                            <Stack direction='row' spacing={2}>
                                                <OutlinedInput
                                                    label='Radius @ Hub r_h (in)'
                                                    value={designParameters.hubRadius}
                                                    onValueChange={(newHubRadius) => {
                                                        setDesignParameters({...designParameters, hubRadius: newHubRadius});
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='Distance from axis of rotation to first airfoil section'
                                                />
                                                <OutlinedInput
                                                    label='Radius @ Tip r_t (in)'
                                                    value={designParameters.tipRadius}
                                                    onValueChange={(newTipRadius) => {
                                                        setDesignParameters({...designParameters, tipRadius: newTipRadius});
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='Distance from axis of rotation to last airfoil section'
                                                />
                                                <OutlinedInput
                                                    label='Blade Number'
                                                    value={designParameters.bladesCount}
                                                    onValueChange={(newBladesCount) => {
                                                        setDesignParameters({...designParameters, bladesCount: newBladesCount});
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='Blade number'
                                                />
                                            </Stack>
                                            <Stack direction='row' spacing={2}>
                                                <OutlinedInput
                                                    label='Number of Sections'
                                                    value={designParameters.sectionsCount}
                                                    onValueChange={(newSectionsCount) => {
                                                        setDesignParameters({...designParameters, sectionsCount: newSectionsCount});
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='How many sections are used to calculate the performance of the blade'
                                                />
                                                <OutlinedInput
                                                    label='Tip Type'
                                                    value={designParameters.tipType}
                                                    onValueChange={(newTipType) => {
                                                        setDesignParameters({...designParameters, tipType: newTipType});
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='Tip Type'
                                                />
                                                <OutlinedInput
                                                    label='Chord Length (in)'
                                                    value={designParameters.chord}
                                                    onValueChange={(newChord) => {
                                                        setDesignParameters({...designParameters, chord: newChord});
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='The propeller chord length at 0.75 r/R'
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>

                                <Stack spacing={2}>
                                    <fieldset className='input-grouping'>
                                        <legend className='input-grouping-label'>Atmospheric Conditions</legend>
                                        <Stack spacing={2}>
                                            <Stack direction='row' spacing={2}>
                                                <OutlinedInput
                                                    label='Standard Atmosphere'
                                                    value={operationalParameters.atmosphere}
                                                    onValueChange={(newAtmosphere) => {
                                                        setOperationalParameters({...operationalParameters, atmosphere: newAtmosphere});
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'170px', height: '60px'}}
                                                    tooltip='Standard Atmosphere'
                                                />
                                                <OutlinedInput
                                                    label='Altitude (ft)'
                                                    value={operationalParameters.altitude}
                                                    onValueChange={(newAltitude) => {
                                                        setOperationalParameters({ ...operationalParameters, altitude: newAltitude});
                                                    } }
                                                    style={{ width: '170px', height: '60px' }} 
                                                    readOnly={false}
                                                    tooltip='Altitude at design point'
                                                />
                                            </Stack>

                                            <Stack direction='row' spacing={2}>
                                                <OutlinedInput
                                                    label='Density &rho; (kg/m^3)'
                                                    value={operationalParameters.density}
                                                    onValueChange={(density) => {
                                                        setOperationalParameters({ ...operationalParameters, density: density });
                                                    } }
                                                    style={{ width: '170px', height: '60px' }} 
                                                    readOnly={false}
                                                    tooltip='Density of design point'
                                                />
                                                <OutlinedInput
                                                    label='Viscosity &mu; (kg/m*s)'
                                                    value={operationalParameters.viscosity}
                                                    onValueChange={(viscosity) => {
                                                        setOperationalParameters({ ...operationalParameters, viscosity: viscosity });
                                                    } }
                                                    style={{ width: '170px', height: '60px' }} 
                                                    readOnly={false}
                                                    tooltip='Viscosity of design point'
                                                />
                                                <OutlinedInput
                                                    label='Speed of Sound &alpha; (m/s)'
                                                    value={operationalParameters.soundSpeed}
                                                    onValueChange={(speedOfSound) => {
                                                        setOperationalParameters({ ...operationalParameters, soundSpeed: speedOfSound });
                                                    } }
                                                    style={{ width: '170px', height: '60px' }} 
                                                    readOnly={false} 
                                                    tooltip='Speed of sound of design point'
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>

                                    <fieldset className='input-grouping'>
                                        <legend className='input-grouping-label'>Operation Conditions/Requirements</legend>
                                        <Stack>
                                            <Stack direction='row' spacing={2}>
                                                <OutlinedInput
                                                    label='V∞ (ft/s)'
                                                    value={operationalParameters.freestreamVelocity}
                                                    onValueChange={(newFreestreamVelocity) => {
                                                        setOperationalParameters({...operationalParameters, freestreamVelocity: newFreestreamVelocity});
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='Freestream velocity (Airflow speed into propeller system)'
                                                />
                                                <OutlinedInput
                                                    label="T (lbf)"
                                                    value={designParameters.thrust}
                                                    onValueChange={(newThrust) => {
                                                        setDesignParameters({...designParameters, thrust: newThrust});
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='Thrust required by propeller. T = Weight of platform / # of motors Not per blade'
                                                />
                                                <OutlinedInput
                                                    label="Reynolds Reference"
                                                    value={designParameters.reynoldsRef}
                                                    onValueChange={(newReynoldsRef) => {
                                                        setDesignParameters({...designParameters, reynoldsRef: newReynoldsRef});
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='Property of flow that defines flow behavior. Impacts airfoil performance significantly. Typical Guess: Re # = (Density*V*Cord) / Viscosity where V = (3/4)*r_t*RPM*(π/30)'
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                            </Stack>

                            <fieldset className='input-grouping' style={{width:'1318px'}}>
                                <legend className='input-grouping-label'>Lift Distribution/Design</legend>
                                <Stack spacing={2}>
                                    <Stack direction='row' spacing={2}>
                                        <OutlinedInput
                                            label="Max Design CL"
                                            value={designParameters.maxCL}
                                            onValueChange={(newMaxCL) => {
                                                setDesignParameters({...designParameters, maxCL: newMaxCL});
                                            }}
                                            readOnly={false}
                                            style={{width:'155px', height: '60px'}}
                                            tooltip='Max coefficient of lift for propeller'
                                        />
                                        <OutlinedInput
                                            label="Min CL"
                                            value={designParameters.minCL}
                                            onValueChange={(newMinCL) => {
                                                setDesignParameters({...designParameters, minCL: newMinCL});
                                            }}
                                            readOnly={false}
                                            style={{width:'155px', height: '60px'}}
                                            tooltip='Only for double break. Specifies what the CL will change to at tip break'
                                        />
                                        <OutlinedInput
                                            label="CL Design"
                                            value={designParameters.clDesign}
                                            onValueChange={(newCLDesign) => {
                                                setDesignParameters({...designParameters, clDesign: newCLDesign});
                                            }}
                                            readOnly={false}
                                            style={{width:'155px', height: '60px'}}
                                            tooltip='CL Design'
                                        />
                                        <OutlinedInput
                                            label="Hub Break"
                                            value={designParameters.hubBreak}
                                            onValueChange={(newHubBreak) => {
                                                setDesignParameters({...designParameters, hubBreak: newHubBreak});
                                            }}
                                            readOnly={false}
                                            style={{width:'155px', height: '60px'}}
                                            tooltip='Location where CL starts to change from max CL to min CL'
                                        />
                                        <OutlinedInput
                                            label="Tip Break"
                                            value={designParameters.tipBreak}
                                            onValueChange={(newTipBreak) => {
                                                setDesignParameters({...designParameters, tipBreak: newTipBreak});
                                            }}
                                            readOnly={false}
                                            style={{width:'155px', height: '60px'}}
                                            tooltip='Location where CL distribution reaches min CL'
                                        />
                                        <FormControlLabel 
                                            label="Hybrid Blade" 
                                            control={
                                                <Checkbox 
                                                    name="isHybridBlade" 
                                                    checked={designParameters.isHybridBlade}           
                                                    onChange={(e) => {
                                                        setDesignParameters({
                                                            ...designParameters, 
                                                            isHybridBlade: e.target.checked
                                                        });
                                                    }}
                                                />
                                            }
                                            style={{width:'140px'}} 
                                        />

                                        {!designParameters.isHybridBlade && (
                                            <OutlinedInput
                                                label="Airfoil"
                                                value={designParameters.airfoil}
                                                onValueChange={(newAirfoil) => {
                                                    setDesignParameters({
                                                        ...designParameters, 
                                                        airfoil: newAirfoil,
                                                    });
                                                }}
                                                readOnly={false}
                                                style={{width:'155px', height: '60px'}}
                                                tooltip='Cross section of propeller blade that has different performance characteristics'
                                            />
                                        )}
                                    </Stack>

                                    {designParameters.isHybridBlade && (
                                        <fieldset>
                                            <legend>Hybrid Blade Design</legend>
                                            <Stack direction='row' spacing={2}>
                                                <OutlinedInput
                                                    label="Airfoil @ Hub"
                                                    value={designParameters.hybridBladeDetail.hubAirfoil}
                                                    onValueChange={(newHubAirfoil) => {
                                                        setDesignParameters({
                                                            ...designParameters, 
                                                            hybridBladeDetail: {
                                                                ...designParameters.hybridBladeDetail,
                                                                hubAirfoil: newHubAirfoil
                                                            }
                                                        });
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='Airfoil near hub'
                                                />
                                                <OutlinedInput
                                                    label="Airfoil @ Tip"
                                                    value={designParameters.hybridBladeDetail.tipAirfoil}
                                                    onValueChange={(newTipAirfoil) => {
                                                        setDesignParameters({
                                                            ...designParameters, 
                                                            hybridBladeDetail: {
                                                                ...designParameters.hybridBladeDetail,
                                                                tipAirfoil: newTipAirfoil
                                                            }
                                                        });
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='Airfoil near tip'
                                                />
                                                <OutlinedInput
                                                    label='Transition r/R'
                                                    value={designParameters.hybridBladeDetail.transition_rOverRadius}
                                                    onValueChange={(newTransition_rOverRadius) => {
                                                        setDesignParameters({
                                                            ...designParameters, 
                                                            hybridBladeDetail: {...designParameters.hybridBladeDetail, transition_rOverRadius: newTransition_rOverRadius}
                                                        });
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'180px', height: '60px', marginLeft: '10px'}}
                                                    tooltip="All sections from hub to transition r/R will be filled with hub airfoil's alpha and cd values, and remaining sections after the transition r/R section will be filled with tip airfoil's alpha and cd values"
                                                />
                                                <OutlinedInput
                                                    label='Transition smoothing ±r/R'
                                                    value={designParameters.hybridBladeDetail.transitionSmoothing_rOverRadius}
                                                    onValueChange={(newTransitionSmoothing_rOverRadius) => {
                                                        setDesignParameters({
                                                            ...designParameters, 
                                                            hybridBladeDetail: {...designParameters.hybridBladeDetail, transitionSmoothing_rOverRadius: newTransitionSmoothing_rOverRadius}
                                                        });
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'180px', height: '60px', marginLeft: '20px'}}
                                                    tooltip='All sections within the given range (transition r/R - transition smoothing r/R, transition r/R + transition smoothing r/R) will be used for beta value smoothing and weighted airfoil transition smoothing'
                                                />
                                            </Stack>
                                        </fieldset>
                                    )}
                                </Stack>
                            </fieldset>

                            <fieldset className='input-grouping' style={{width: '856px'}}>
                                <legend className='input-grouping-label'>Additional Detail</legend>
                                <Stack spacing={2}>
                                    {additionalDetails.map((additionalDetail, index) => (
                                        <Stack key={index} direction='row' spacing={2}>
                                            <OutlinedInput 
                                                label='Field'
                                                value={additionalDetail.field}
                                                onValueChange={(newField) => onAdditionalDetailChange(index, 'field', newField)}
                                                style={{width: '350px', height: '60px'}}
                                                readOnly={false}
                                                tooltip='Additional Detail Field'
                                            />
                                            <OutlinedInput 
                                                label='Value'
                                                value={additionalDetail.value}
                                                onValueChange={(newValue) => onAdditionalDetailChange(index, 'value', newValue)}
                                                style={{width: '350px', height: '60px'}}
                                                readOnly={false}
                                                tooltip='Additional Detail Value'
                                            />

                                            <IconButton 
                                                onClick={() => onAdditonalDetailDelete(index)} 
                                                style={{width:'60px'}}
                                                color='error'
                                            >
                                                <DeleteForeverOutlinedIcon/>
                                            </IconButton>

                                        </Stack>
                                    ))}
                                    <div style={{alignItems:'center'}}>
                                        <Button onClick={addAdditionalDetail} color="primary" variant="contained" style={{width: '200px'}}>
                                            Add
                                        </Button>
                                    </div>
                                </Stack>
                            </fieldset>

                            <fieldset className='input-grouping' style={{width: '856px'}}>
                                <legend className='input-grouping-label'>Static Test Data</legend>
                                <Stack spacing={2}>
                                    <ReactGrid 
                                        rows={getStaticTestDataRow(staticTestData)}
                                        columns={staticTestDataColumns}
                                        onCellsChanged={handleStaticTestDataChanges}
                                        stickyTopRows={0}
                                        enableFillHandle
                                    />

                                    <div style={{alignItems:'center'}}>
                                        <Button 
                                            onClick={() => setStaticTestData([
                                                ...staticTestData, 
                                                {rpm: '', thrust: '', torque: '', power: ''}
                                            ])}
                                            color="primary" 
                                            variant="contained" 
                                            style={{width: '200px'}}
                                        >
                                            Add Row
                                        </Button>
                                    </div>
                                </Stack>
                            </fieldset>
                            <fieldset className='input-grouping' style={{width: '856px'}}>
                                <legend className='input-grouping-label'>Files</legend>
                                <Stack spacing={2}>
                                    <Dropzone
                                        onDrop={onDrop}
                                        accept={{}}
                                        description="Drag 'n' drop the file here, or click to select the file. If the file already exists, then it'll be overwritten by the new file."
                                        maxFiles={10}
                                    />
                                    <Stack spacing={1} style={{marginTop:'20px'}}>
                                        {uploadCandidateFiles.map((uploadCandidateFile, index) => (
                                            <Stack key={index} direction='row' spacing={2}>
                                                <div
                                                    style={{alignSelf:'center', fontSize:'17px', marginBottom:'5px'}}
                                                >
                                                    {uploadCandidateFile.name}
                                                </div>
                                                <IconButton onClick={()=> {
                                                    setUploadCandidateFiles(uploadCandidateFiles.filter(
                                                        file => !((file.name === uploadCandidateFile.name) && (file.type === uploadCandidateFile.type))))
                                                }}>
                                                    <Close/>
                                                </IconButton>
                                            </Stack>
                                        ))}
                                    </Stack>
                                    <DataGrid
                                        autoHeight
                                        columns={uploadedFilesColumns}
                                        rows={uploadedFiles}
                                    />
                                </Stack>
                            </fieldset>
                        </Stack>
                    </Stack>
                </div>
            </Stack>
            {confirmationProps && <ConfirmationDialog {...confirmationProps}/>}
        </>
    );
}

export default WithAuthenticate(AddStandardPropellerData);