import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { Stack, Tooltip as MUITooltip, Typography, TextField, Divider, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, Button} from '@mui/material';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ViewInArRoundedIcon from '@mui/icons-material/ViewInArRounded';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import * as _ from 'lodash';
import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from 'common/NavigationBar';
import { Page, OffConditionAnalysisParameterOLD } from 'constants/enums';
import Sidebar, { MenuButton } from 'common/Sidebar';
import STLDisplay from 'components/Results/STLDisplay';
import WithAuthenticate from 'common/hoc/WithAuthenticate';
import { useLocation } from "react-router-dom";
import OutlinedInput from 'common/OutlinedInput';
import FileSelection from 'components/OffConditionAnalysis/FileSelection';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { ResultFields } from 'components/Results/ResultFields';
import { getExperimentTitle } from 'utils/utils';
import RangeStepInput from 'common/RangeStepInput';
import { POST_API_URLS } from 'constants/apiUrls';
import { post } from 'utils/api';
import { useSnackbar } from 'notistack';
import { Download } from '@mui/icons-material';
import { StandardPropellerFields } from '../StandardPropellerFields';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

export const SweepStandardMultipleExperiments = () => {
    const navigate = useNavigate();

    const experiment_details = useLocation();
    const experiment_details_results = Object.is(experiment_details.state,null)?[]: experiment_details.state.experiment_details
    const standard_propeller_results = Object.is(experiment_details.state,null)?[]: experiment_details.state.standard_propeller_details
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedRPMSweeps, setSelectedRPMSweeps] = useState<any>([]);

    const colorList = ['#2196f3', '#f44336', '#4caf50', '#b56f00', '#008080'];
    const {enqueueSnackbar} = useSnackbar();
    const [sweepResult, setSweepResult] = useState([{
        rpm: [], thrust: [], torque: [], power: [], j: [], cp: [], ct: [], efficiency: [], vinf:[]
    }]);

    useEffect(() =>{
        if(experiment_details_results.length == 0){
            sweepWithType('Sweep');
        }
        if(!(standard_propeller_results.length > 0)){
            navigate('/analysis');
        }
    }, []);
    const [sweepParameters, setSweepParameters] = useState({
        
        vinf: '0.0',
        altitude: '0.0',
        experiments: experiment_details_results,
        standardPropellers: standard_propeller_results

    });

    const smallSX = {m:1, width: '18ch'};
    const expireCallback = () => {
        navigate('/login');
    }

    const getFileSelection = (selectedFiles:Array<number>, experimentId:number) => {
        setSelectedRPMSweeps({...selectedRPMSweeps, [experimentId]:selectedFiles});
    }

    const validationCheck = (buttonPressed:string) => {
        if (buttonPressed == 'Files'){
            return true;
        }
        if (experiment_details_results.length > 0 && sweepParameters.vinf === '' ) {
            enqueueSnackbar(`Fill up the V∞ (ft/s) field`, {variant: 'error'});
            return false;
        } else if (experiment_details_results.length > 0 && sweepParameters.altitude === '' ) {
            enqueueSnackbar(`Fill up the Altitude (ft) field`, {variant: 'error'});
            return false;
        }

        return true

    }   

    const getInputData = (buttonPressed:string) => {
        const data = {
            
            vinf: sweepParameters.vinf,
            altitude: sweepParameters.altitude,
            standard_propellers: sweepParameters.standardPropellers,
            selectedSweeps: selectedRPMSweeps, 
            button: buttonPressed,
            experiments: experiment_details_results
        }
            return data
    }
    

    const sweepWithType = (buttonPressed:string) => {

        if (validationCheck(buttonPressed)) {
            
            const data = getInputData(buttonPressed)
            setIsLoading(true);
            post(POST_API_URLS.SWEEP_STANDARD_MULTIPLE_EXPERIMENTS(),  data, expireCallback).then((res:AxiosResponse) => {
                if (res.status === 200) {
                    
                    setSweepResult(res.data['result'])
                } else {
                    enqueueSnackbar('Error while loading the sweep', {variant: 'error'});
                }
            }).catch((res:AxiosError) => {
                enqueueSnackbar('Error while loading the sweep', {variant: 'error'});
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }

    const handleGraphs = (x_axis: string, y_axis: string) => {
        const graphData = {datasets: sweepResult.map((value:any, index: number) => {
            const label = index<experiment_details_results.length?
                getExperimentTitle(experiment_details_results[index]): 
                standard_propeller_results[experiment_details_results.length===0?index:index%experiment_details_results.length]['title']  
            return ({
                label: label,
                data: value[x_axis].map((x_value:any, idx:number) => ({
                    x:Number(x_value).toFixed(3), 
                    y:Number(value[y_axis][idx]).toFixed(3)
                })
        ),
        borderColor: colorList[index%5],
        pointBackgroundColor: colorList[index%5],
        showLine: true,
        })})}

        return graphData
    };

    function loadFileButtonDisabled(){
        for (let keys in selectedRPMSweeps){
            if (selectedRPMSweeps[keys].length > 0) {
                return false;
            }
        }
        return true;
    }

    const getGraphOptions = (title: string, xLabel: string, yLabel: string) => ({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context:any) => {
                        return context.formattedValue;
                    }
                }
            },
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: title,
            },
          },
          scales: {
              x: {
                  display: true,
                  title: {
                      display: true,
                      text: xLabel,
                  },
              },
              y: {
                  display: true,
                  title: {
                      display: true,
                      text: yLabel
                  },
                  ticks: {
                    display: true,
                    autoSkip: false,
                  }
              },
          }
      });

    const Menubar = () => {
        return (
            <></>
        );
    };

    return (
        <>
        {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            {standard_propeller_results.length > 0 && <Stack direction='row' spacing={3}>
            
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.RESULTS}/>
                </div>
                
            
                <Stack spacing={3} style={{marginBottom:'30px'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Propellers Analysis
                    </Typography>
                
                    <Stack 
                        spacing={2} 
                        divider={<Divider orientation="vertical" flexItem />} 
                        direction='row'
                        justifyContent='center'
                    >
                        {experiment_details_results.map((experiment: any, key: number) => (
                            <Stack
                            spacing={2}
                            justifyContent='center'
                            >
                                <ResultFields 
                                    key={key}
                                    title={getExperimentTitle(experiment)}
                                    efficiency={Number(_.get(experiment, 'result.efficiency', '0'))}
                                    shaftPower={Number(_.get(experiment, 'result.other.power_shaft', '0'))}
                                    thrust={Number(_.get(experiment, 'result.other.thrust_n', []).reduce((sum:number, currentValue:number)=>sum+currentValue,0) / 4.4482216)}
                                torque={Number(_.get(experiment, 'result.other.torque_n', []).reduce((sum:number, currentValue:number)=>sum+currentValue,0) * 8.8507457673787)}
                                    finalChord={Number(_.get(experiment, 'result.chord', '0'))}
                                    finalRPM={Number(_.get(experiment, 'result.range', '0'))}
                                    maxMachValue={Number(_.get(experiment, 'result.other.max_mach', '0'))}
                                />
                                <FileSelection experimentId={experiment.id} callback={getFileSelection}/>
                            </Stack>
                        ))}
                        {standard_propeller_results.map((standardPropeller: any, key: number) => (
                            <StandardPropellerFields 
                                key={key}
                                title={standardPropeller['title']}
                                chord={Number(standardPropeller['chord'])}
                                height={(experiment_details_results.length > 0)?'433px':'72px'}
                            />
                        ))}
                    </Stack>
                
                    {(experiment_details_results.length > 0) && <Stack spacing={2} alignItems='center'>
                        <Button
                            variant='contained'
                            onClick={() => {
                                sweepWithType('Files');
                            }}
                            style={{marginLeft: '9px', width:'355px'}}
                            disabled={loadFileButtonDisabled()}
                        >
                            Load Files
                        </Button>
                    </Stack>}
                    
                    <Stack spacing={2}>
                        <Stack direction='row' spacing={2}>
                        {experiment_details_results.length > 0 && <OutlinedInput
                                            label={'V∞ (ft/s)'}
                                            value={sweepParameters.vinf}
                                            onValueChange={(newValue: string) => {
                                                setSweepParameters({
                                                    ...sweepParameters, 
                                                    vinf: newValue
                                                });
                                            }}
                                            readOnly={false}
                                            style={{width:'180px', height: '48px', marginLeft: '10px'}}
                                            tooltip=""
                                        />}
                        {experiment_details_results.length > 0 && <OutlinedInput
                                            label={'Altitude (ft)'}
                                            value={sweepParameters.altitude}
                                            onValueChange={(newValue: string) => {
                                                setSweepParameters({
                                                    ...sweepParameters, 
                                                    altitude: newValue
                                                });
                                            }}
                                            readOnly={false}
                                            style={{width:'180px', height: '48px', marginLeft: '10px'}}
                                            tooltip=""
                                        />}
                        </Stack>
                        {(experiment_details_results.length > 0) && <Stack alignItems='center'>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    sweepWithType('Sweep');
                                }}
                                style={{marginTop: '38px', width:'355px'}}
                            >
                                COMPARE
                            </Button>
                        </Stack>}
                        
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        
                    <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Thrust (lbf) vs RPM`, 
                                'RPM', 'Thrust (lbf)')} 
                                data={handleGraphs('rpm', 'thrust')}
                            />
                        </fieldset>
                        <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Torque (lbfin) vs RPM`, 
                                'RPM', 'Torque (lbfin)')} 
                                data={handleGraphs('rpm', 'torque')}
                            />
                        </fieldset>
                        

                    </Stack>
                    <Stack direction='row' spacing={2}>
                        <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions(`Power (W) vs RPM`, 
                                'RPM', 'Power (W)')} 
                                data={handleGraphs('rpm', 'power')}
                            />
                        </fieldset>
                    </Stack>
                     
                </Stack>
            </Stack>}
        </>
    );
}

export default WithAuthenticate(SweepStandardMultipleExperiments);

