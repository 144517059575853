import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { DataGrid, GridColDef, GridRowParams} from '@mui/x-data-grid';
import { IconButton, Stack, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { AxiosError, AxiosResponse } from 'axios';

import { del, get, isLoggedIn } from 'utils/api';
import Sidebar, { MenuButton } from 'common/Sidebar';
import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from 'common/NavigationBar';
import { Page } from 'constants/enums';
import { DELETE_API_URLS, GET_API_URLS } from 'constants/apiUrls';
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';
import WithAuthenticate from 'common/hoc/WithAuthenticate';


export const StandardPropellersData = () => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const [standardPropellersData, setStandardPropellersData] = useState<Array<{
        id:number, title: string, hubRadius: number, tipRadius: number, reynoldsRef: number, desiredThrust: number,
        chord: number}>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null); 
    
    const [pageSize, setPageSize] = useState<number>(25);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/login');
            return;
        }

        loadStandardPropellersData();
    }, []);

    const loadStandardPropellersData = () => {
        setIsLoading(true);
        get(GET_API_URLS.STANDARD_PROPELLERS_DATA(), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const _standardPropellersData = res.data['standard_propellers_data'].map((propellerData:any) => ({
                    id: propellerData.id,
                    title: propellerData.title,
                    hubRadius: propellerData.hub_radius,
                    tipRadius: propellerData.tip_radius,
                    reynoldsRef: propellerData.reynolds_ref,
                    desiredThrust: propellerData.desired_thrust,
                    chord: propellerData.chord
                }));
                setStandardPropellersData(_standardPropellersData);
            } else {
                enqueueSnackbar('Failed to fetch standard propellers data', {variant: 'error'});
            }
        }).catch((res: AxiosError) => {
            enqueueSnackbar('Failed to fetch standard propellers data', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const deleteStandardPropellerData = (standardPropellerDataId:number) => {
        setIsLoading(true);
        del(DELETE_API_URLS.STANDARD_PROPELLER_DATA_DELETE(standardPropellerDataId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Deleted the standard propeller data successfully.', {variant: 'success'});
                loadStandardPropellersData();
            } else {
                enqueueSnackbar('Error while deleting the standard propeller data.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while deleting the standard propeller data.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
            setConfirmationProps(null);
            loadStandardPropellersData();
        })
    }

    const columns: GridColDef[] = [
        {field: 'title', headerName: 'Title', headerAlign: 'center', align:'center', flex:1, editable: false, type: 'string'},
        {field: 'tipRadius', headerName: 'Diameter', headerAlign: 'center', align:'center', width: 120, editable: false, 
            type: 'number', renderCell: (params) => {
                return (
                    <div>{params.row.tipRadius?params.row.tipRadius * 2: ''}</div>
                );
            } 
        },
        {field: 'reynoldsRef', headerName: 'Reynolds Reference', headerAlign: 'center', align:'center', width: 180, editable: false, type: 'number'},
        {field: 'desiredThrust', headerName: 'Thrust', headerAlign: 'center', align:'center', width: 120, editable: false, type: 'number'},
        {field: 'chord', headerName: 'Chord', headerAlign: 'center', align:'center', width: 120, editable: false, type: 'number'},
        {field: 'edit', headerName: 'Edit', width:60, headerAlign: 'center', align: 'center', sortable: false, 
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => navigate(`/standard-propellers-data/${params.id}`)}>
                        <EditOutlinedIcon />
                    </IconButton>
                );
            }
        },
        {field: 'delete', align: 'center', headerAlign: 'center', headerName: 'Delete', width: 60, sortable: false,
            renderCell: (params) => {
                const onDelete = (e:any) => {
                    e.stopPropagation();
                    setConfirmationProps({
                        title: 'Delete Standard Propeller Data',
                        body: 'Do you want to delete the standard propeller data?',
                        onCancel: () => setConfirmationProps(null),
                        onConfirm: () => deleteStandardPropellerData(parseInt(params.row.id))
                    });
                } 
                return (
                    <IconButton onClick={onDelete} color='error'>
                        <DeleteForeverOutlinedIcon />
                    </IconButton>
                );
            }
        }
    ];

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop: '20px', marginBottom: '20px'}}>
                <MenuButton 
                    Icon={AddCircleOutlineIcon}
                    iconColor='black'
                    label='New'
                    onClick={() => navigate('/standard-propellers-data/new')}
                    disabled={false}
                />
            </Stack>
        );
    }

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{height: '100%'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.STANDARD_PROPELLERS_DATA}/>
                </div>
                <Stack spacing={3} style={{paddingLeft: '30px', width: '2060px'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Standard Propellers Data
                    </Typography>
                    <Stack 
                        direction='row' 
                        alignItems='stretch' 
                        justifyContent='flex-start'
                        spacing={10} 
                        style={{height: '100%'}}
                    >
                        <div style={{width:'1000px', height:'800px'}}>
                            <DataGrid
                                columns={columns}
                                rows={standardPropellersData}
                                onRowClick={(params: GridRowParams) => {
                                    navigate(`/standard-propellers-data/${params.id}`);
                                }}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
                            />
                        </div>
                    </Stack>
                </Stack>
            </Stack>
            {confirmationProps && <ConfirmationDialog {...confirmationProps}/>}
        </>
    );
} 

export default WithAuthenticate(StandardPropellersData);