import React, {useState} from 'react';

import WithAuthenticate from 'common/hoc/WithAuthenticate';

export const Help = () => {
    return (
        <div>
            <iframe 
                title='documentation'
                src={`${process.env.PUBLIC_URL}/documentation.pdf`} 
                style={{
                    display: 'block',  /* iframes are inline by default */   
                    height: '100vh',  /* Set height to 100% of the viewport height */   
                    width: '100vw',  /* Set width to 100% of the viewport width */     
                    border: 'none', /* Remove default border */
                    background: 'lightyellow'
                }}>
            
            </iframe>
        </div>
    )
}

export default Help;