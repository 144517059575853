import React from 'react'
import { Stack, TextField } from "@mui/material"

import OutlinedInput from 'common/OutlinedInput';


interface StandardPropellerFieldsProps {
    title: string,
    chord: number,
    height:string 
}

export const StandardPropellerFields = (props: StandardPropellerFieldsProps) => {
    const {title, chord, height} = props;
    
    const sx ={m:1, width: '25ch'};

    return (
        <>
        
        <fieldset style={{ width:'330px', height:height, border: '1px solid #6D77B0', borderRadius: '12px', padding: '5px 6px 14px 14px'}}>
                            <legend style={{marginLeft: '17px'}}>{title}</legend>
                            <Stack spacing={2}>
                                <OutlinedInput
                                    label='Chord (in)'
                                    value={chord.toFixed(3)}
                                    onValueChange={(newFinalChord) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip={`Chord after optimization`}
                                />
                            </Stack>
                        </fieldset>
        
        </>


    )
}

StandardPropellerFields.defaultProps = {
    height: '72px',
}
