import React, {useState, useEffect} from 'react';
import { useSnackbar} from 'notistack';
import { Box, Container, Tooltip as MUITooltip, FormControl, FormHelperText, Grid, Stack, InputAdornment, InputLabel, MenuItem,
     Select, SelectChangeEvent, TextField, Typography, IconButton, Button, Checkbox, Autocomplete } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import {useNavigate, useParams} from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import * as _ from 'lodash';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from 'common/NavigationBar';
import { ExperimentStatus, Page } from 'constants/enums';
import {get, post} from 'utils/api';
import { GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import Sidebar, {MenuButton} from 'common/Sidebar';
import './css/styles.css';
import WithAuthenticate from 'common/hoc/WithAuthenticate';
import TipType_Oval2 from 'static/img/TipType_Oval2.png';
import TipType_Square from 'static/img/TipType_Square.png';
import RangeInput from 'common/RangeInput';
import OutlinedInput from 'common/OutlinedInput';
import { calculateAtmosphere } from './utilityOtherNew';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Scatter } from 'react-chartjs-2';
import { formatNumberWithCommas } from 'utils/utils';
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

enum TIPTYPE {
    OVAL2 = "OVAL2",
    SQUARE = "SQUARE"
}

export interface IAirfoilsPropertiesDistribution {
    rOverRadius_n: Array<number>,
    airfoilsProperties: Array<{clValues:Array<number>, alphaValues:Array<number>, cdValues:Array<number>}>
}

export const ExperimentInputs = () => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const params = useParams();

    const [experimentId, setExperimentId] = useState(params['experimentId']?parseInt(params['experimentId']):undefined);
    const [airfoils, setAirfoils] = useState<Array<{'id':number, 'name':string, 'reynoldsNumbers': Array<number>}>>([]);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null);
    const [company, setCompany] = useState('');
    const [platform, setPlatform] = useState('');
    const [designParameters, setDesignParameters] = useState({
        hubRadiusLower:'0',
        hubRadiusUpper:'0',
        tipRadiusLower:'0',
        tipRadiusUpper:'0',
        bladesCountLower:'0',
        bladesCountUpper:'0',
        sectionsCount:'0',
        tipType:'OVAL2',
        thrust:'0',
        reynoldsRef:'',
        maxCLLower:'0',
        maxCLUpper:'0',
        minCL:'0.000001',
        clDesign: 'CONSTANT',
        hubBreakCLLower:'0',
        hubBreakCLUpper:'0',
        tipBreakCLLower:'0',
        tipBreakCLUpper:'0',
        airfoilId:'0',
        airfoil:'CLARK_Y',
        isHybridBlade: false,
        hybridBladeDetail: {
            hubAirfoilId: '0',
            tipAirfoilId: '0',
            transition_rOverRadius: '0',
            transitionSmoothing_rOverRadius: '0'
        },
    });

    const [isInputRange, setIsInputRange] = useState({
        hubRadius: false,
        tipRadius: false,
        bladesCount: false,
        maxCL: false,
        hubBreak: false,
        tipBreak: false,
    });

    const [operationalParameters, setOperationalParameters] = useState({
        atmosphere: 'STANDARD',
        altitude: '5000',
        density: '0',
        viscosity: '0',
        soundSpeed: '0',
        freestreamVelocity: '0',
    });
    const [airfoilsPropertiesDistribution, setAirfoilsPropertiesDistribution] = useState<IAirfoilsPropertiesDistribution|null>(null);

    const getGraphOptions = (title: string, xLabel: string, yLabel: string) => ({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: title,
            },
          },
          scales: {
              x: {
                  display: true,
                  title: {
                      display: true,
                      text: xLabel,
                  },
              },
              y: {
                  display: true,
                  title: {
                      display: true,
                      text: yLabel
                  },
              },
          }
      });

    const [experimentStatus, setExperimentStatus] = useState<ExperimentStatus>(ExperimentStatus.NEW);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingNewProps, setIsLoadingNewProps] = useState<boolean>(false);
    const [tipHover, setTipHover] = useState(TipType_Oval2);
    const [maxAirfoilCLValue,setMaxAirfoilCLValue] = useState<number>(1);
    const [minAirfoilCLValue,setMinAirfoilCLValue] = useState<number>(0);

    const updateToolTip = (type: String) => {
        switch(type) {
            case TIPTYPE.OVAL2: setTipHover(TipType_Oval2); break;
            case TIPTYPE.SQUARE: setTipHover(TipType_Square); break;
        }
    };

    const expireCallback = () => {
        navigate('/login');
    }

    const alphaDistribution = {
        datasets: airfoilsPropertiesDistribution?[
            {
                label: airfoils.filter(airfoil => (airfoil.id===Number(designParameters.hybridBladeDetail.hubAirfoilId)))[0].name,
                data: airfoilsPropertiesDistribution.rOverRadius_n.map((r_over_R:number, sectionIdx:number) => ({
                    x:r_over_R.toFixed(3), 
                    y:airfoilsPropertiesDistribution.airfoilsProperties[0].alphaValues[sectionIdx]
                })),
                borderColor: '#42a5f5',
                pointBackgroundColor: '#42a5f5',
                showLine: true,
            },
            {
                label: airfoils.filter(airfoil => (airfoil.id===Number(designParameters.hybridBladeDetail.tipAirfoilId)))[0].name,
                data: airfoilsPropertiesDistribution.rOverRadius_n.map((r_over_R:number, sectionIdx:number) => ({
                    x:r_over_R.toFixed(3), 
                    y:airfoilsPropertiesDistribution.airfoilsProperties[1].alphaValues[sectionIdx]
                })),
                borderColor: '#ef5350',
                pointBackgroundColor: '#ef5350',
                showLine: true,
            }
        ]:[]   
    };

    const cdDistribution = {
        datasets: airfoilsPropertiesDistribution?[
            {
                label: airfoils.filter(airfoil => (airfoil.id===Number(designParameters.hybridBladeDetail.hubAirfoilId)))[0].name,
                data: airfoilsPropertiesDistribution.rOverRadius_n.map((r_over_R:number, sectionIdx:number) => ({
                    x:r_over_R.toFixed(3), 
                    y:airfoilsPropertiesDistribution.airfoilsProperties[0].cdValues[sectionIdx]
                })),
                borderColor: '#42a5f5',
                pointBackgroundColor: '#42a5f5',
                showLine: true,
            },
            {
                label: airfoils.filter(airfoil => (airfoil.id===Number(designParameters.hybridBladeDetail.tipAirfoilId)))[0].name,
                data: airfoilsPropertiesDistribution.rOverRadius_n.map((r_over_R:number, sectionIdx:number) => ({
                    x:r_over_R.toFixed(3), 
                    y:airfoilsPropertiesDistribution.airfoilsProperties[1].cdValues[sectionIdx]
                })),
                borderColor: '#ef5350',
                pointBackgroundColor: '#ef5350',
                showLine: true,
            }
        ]:[]   
    };

    const loadAirfoilsPropertiesDistribution = _.debounce(() => {
        if (!isEligibleForHybridBlade() || !designParameters.isHybridBlade) {
            return;
        }
        
        const {hubAirfoilId, tipAirfoilId} = designParameters.hybridBladeDetail; 
        if (!hubAirfoilId || !tipAirfoilId || (hubAirfoilId === '0') || (tipAirfoilId === '0')  || 
            (hubAirfoilId === tipAirfoilId)){
            return;
        }

        setIsLoading(true);
        const data = {
            'hub_radius': designParameters.hubRadiusLower,
            'tip_radius': designParameters.tipRadiusLower,
            'num_of_sections': designParameters.sectionsCount,
            'max_cl': designParameters.maxCLLower,
            'min_cl': designParameters.minCL,
            'cl_design': designParameters.clDesign,
            'hub_break_def': designParameters.hubBreakCLLower,
            'tip_break_def': designParameters.tipBreakCLLower,
            're_ref': designParameters.reynoldsRef,
            'airfoil_detail_ids': [hubAirfoilId, tipAirfoilId]
        }
        post(POST_API_URLS.AIRFOILS_PROPERTIES_DISTRIBUTION(), data, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const properties_distribution = res.data.airfoils_properties_distribution;
                setAirfoilsPropertiesDistribution({
                    rOverRadius_n: properties_distribution.r_over_radius_n,
                    airfoilsProperties: properties_distribution.airfoils_properties.map((property:any) =>({
                        clValues: property.cl_values,
                        alphaValues: property.alpha_values,
                        cdValues: property.cd_values
                    }))
                })
            } else {
                enqueueSnackbar('Error while loading the airfoils properties distribution', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while loading the airfoils properties distribution', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }, 200)


    useEffect(() => {
        loadAirfoilsPropertiesDistribution();
    }, [designParameters.hybridBladeDetail.hubAirfoilId, designParameters.hybridBladeDetail.tipAirfoilId, 
        designParameters.hubRadiusLower, designParameters.tipRadiusLower, designParameters.sectionsCount,
        designParameters.maxCLLower, designParameters.minCL, designParameters.clDesign, 
        designParameters.hubBreakCLLower, designParameters.tipBreakCLLower, designParameters.reynoldsRef, 
        isInputRange.hubRadius, isInputRange.tipRadius, isInputRange.maxCL, isInputRange.hubBreak, isInputRange.tipBreak
    ])

    useEffect(() => {
        const result = calculateAtmosphere(operationalParameters.atmosphere, operationalParameters.altitude)
        setOperationalParameters({...operationalParameters, atmosphere: operationalParameters.atmosphere, density: result.rho.toString(), viscosity: result.mu.toString(), soundSpeed: result.a.toString()});

        setIsLoading(true);
        get(GET_API_URLS.AIRFOILS(), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const _airfoils = res.data.airfoils
                    .filter((airfoil:any) => airfoil.reynolds_numbers.length > 0)
                    .map((airfoil:any) => ({
                        'id': airfoil.id,
                        'name': airfoil.name,
                        'reynoldsNumbers': airfoil.reynolds_numbers.sort((a:number,b:number) => (a-b))
                }))
                setAirfoils(_airfoils);
                if ((res.data.airfoils.length > 0) && (experimentId === undefined)) {
                    const defaultAirfoilId = res.data.airfoils[0].id.toString();
                    setDesignParameters({...designParameters, airfoilId: defaultAirfoilId, hybridBladeDetail: {...designParameters.hybridBladeDetail, hubAirfoilId: defaultAirfoilId}});
                }
             } else {
                    enqueueSnackbar('Error while loading the airfoils', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while loading the airfoils', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
            if (experimentId !== undefined) {
                loadExperimentInput(experimentId);
            }
        })
    }, []);

    useEffect(() => {
        if (designParameters.airfoilId && designParameters.airfoilId !== '0') {
            const reynoldsRef = designParameters.reynoldsRef;
            const airfoil = airfoils.filter(airfoil => (airfoil.id === Number(designParameters.airfoilId)))[0];
            if (!airfoil.reynoldsNumbers.includes(Number(reynoldsRef))) {
                if (reynoldsNumberOptions.length > 0) {
                    setDesignParameters({...designParameters, reynoldsRef: airfoil.reynoldsNumbers[0].toString()});
                } else {
                    setDesignParameters({...designParameters, reynoldsRef:''});
                }
            }
        }
    }, [designParameters.airfoilId]);

    useEffect(() => {
        if (reynoldsNumberOptions.map(reynoldsNumber => reynoldsNumber.toString()).includes(designParameters.reynoldsRef)){
            if (!isMaxCLValid()) {
                enqueueSnackbar('Max CL value is out of the airfoil CL range', {variant: 'error'});
            }

            if (!isMinCLValid()) {
                enqueueSnackbar('Min CL value is out of the airfoil CL range', {variant: 'error'});
            }
        }
    },[maxAirfoilCLValue, minAirfoilCLValue]);

    const loadExperimentInput = (experimentId: number) => {
        setIsLoading(true);
        get(GET_API_URLS.EXPERIMENT(experimentId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const experiment = res.data.experiment;
                setCompany(experiment.company);
                setPlatform(experiment.platform);


                const _airfoilIds = _.get(experiment.hybrid_blade_detail, 'airfoil_ids', [])
                const transitions_rOverRadius = _.get(experiment.hybrid_blade_detail, 'transition_r_over_Rs', [])
                const transitionSmoothings_rOverRadius = _.get(experiment.hybrid_blade_detail, 'transition_smoothing_r_over_Rs', [])

                setDesignParameters({
                    hubRadiusLower:`${Number(experiment.hub_break_radius_lower)}`,
                    hubRadiusUpper:`${Number(experiment.hub_break_radius_upper)}`,
                    tipRadiusLower:`${Number(experiment.tip_break_radius_lower)}`,
                    tipRadiusUpper:`${Number(experiment.tip_break_radius_upper)}`,
                    bladesCountLower:`${Number(experiment.num_of_blades_lower)}`,
                    bladesCountUpper:`${Number(experiment.num_of_blades_upper)}`,
                    sectionsCount:`${Number(experiment.num_of_sections)}`,
                    tipType:experiment.tip_type,
                    thrust:`${Number(experiment.desired_thrust)}`,
                    reynoldsRef:`${parseInt(experiment.reynolds_ref)}`,
                    maxCLLower:`${Number(experiment.max_cl_lower)}`,
                    maxCLUpper:`${Number(experiment.max_cl_upper)}`,
                    minCL:`${Number(experiment.min_cl)}`,
                    clDesign: experiment.cl_design,
                    hubBreakCLLower:`${Number(experiment.hub_break_cl_lower)}`,
                    hubBreakCLUpper:`${Number(experiment.hub_break_cl_upper)}`,
                    tipBreakCLLower:`${Number(experiment.tip_break_cl_lower)}`,
                    tipBreakCLUpper:`${Number(experiment.tip_break_cl_upper)}`,
                    airfoilId:`${Number(experiment.airfoil_id)}`,
                    airfoil:experiment.airfoil,
                    isHybridBlade: experiment.is_hybrid_blade,
                    hybridBladeDetail: {
                        hubAirfoilId: _airfoilIds.length>0?_airfoilIds[0]:`${Number(experiment.airfoil_id)}`,
                        tipAirfoilId: _airfoilIds.length>0?_airfoilIds[1]:'0',
                        transition_rOverRadius: `${Number(transitions_rOverRadius.length>0?transitions_rOverRadius[0]:'0')}`,
                        transitionSmoothing_rOverRadius: `${Number(transitionSmoothings_rOverRadius.length>0?transitionSmoothings_rOverRadius[0]:'0')}`
                    }
                });

                setOperationalParameters({
                    atmosphere: _.get(experiment, 'atmosphere', 'STANDARD'),
                    altitude: `${Number(_.get(experiment, 'altitude', '5000'))}`,
                    density: `${Number(experiment.air_density)}`,
                    viscosity: `${Number(experiment.air_viscosity)}`,
                    soundSpeed: `${Number(experiment.sound_speed)}`,
                    freestreamVelocity: `${Number(experiment.freestream_velocity)}`,
                });

                setIsInputRange({
                    hubRadius:Number(experiment.hub_break_radius_lower) !== Number(experiment.hub_break_radius_upper),
                    tipRadius:Number(experiment.tip_break_radius_lower) !== Number(experiment.tip_break_radius_upper),
                    bladesCount:Number(experiment.num_of_blades_lower) < Number(experiment.num_of_blades_upper),
                    maxCL:Number(experiment.max_cl_lower) !== Number(experiment.max_cl_upper),
                    hubBreak:Number(experiment.hub_break_cl_lower) !== Number(experiment.hub_break_cl_upper),
                    tipBreak:Number(experiment.tip_break_cl_lower) !== Number(experiment.tip_break_cl_upper),
                });
                setExperimentStatus(ExperimentStatus[experiment['status'] as ExperimentStatus]);
                updateToolTip(experiment.tip_type)
                getReynoldsNumberProperties(`${Number(experiment.airfoil_id)}`,`${parseInt(experiment.reynolds_ref)}`);

            } else {
                enqueueSnackbar('Error while loading the experiment input', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while loading the experiment input', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const getInputData = () => {
        const data = {
            'company': company,
            'platform': platform,
            'design_parameter': {
                'hub_break_radius_lower': designParameters.hubRadiusLower,
                'hub_break_radius_upper': isInputRange.hubRadius?designParameters.hubRadiusUpper: designParameters.hubRadiusLower,
                'tip_break_radius_lower': designParameters.tipRadiusLower,
                'tip_break_radius_upper': isInputRange.tipRadius?designParameters.tipRadiusUpper: designParameters.tipRadiusLower,
                'desired_thrust': designParameters.thrust,
                'num_of_blades_lower': designParameters.bladesCountLower,
                'num_of_blades_upper': isInputRange.bladesCount?designParameters.bladesCountUpper: designParameters.bladesCountLower,
                'reynolds_ref': designParameters.reynoldsRef,
                'num_of_sections': designParameters.sectionsCount,
                'tip_type': designParameters.tipType,
                'airfoil_id': designParameters.airfoilId,
                'max_cl_lower': designParameters.maxCLLower,
                'max_cl_upper': isInputRange.maxCL?designParameters.maxCLUpper: designParameters.maxCLLower,
                'min_cl': designParameters.minCL,
                'cl_design': designParameters.clDesign,
                'hub_break_cl_lower': designParameters.hubBreakCLLower,
                'hub_break_cl_upper': isInputRange.hubBreak?designParameters.hubBreakCLUpper: designParameters.hubBreakCLLower,
                'tip_break_cl_lower': designParameters.tipBreakCLLower,
                'tip_break_cl_upper': isInputRange.tipBreak?designParameters.tipBreakCLUpper: designParameters.tipBreakCLLower,
                'is_hybrid_blade': designParameters.isHybridBlade,
                'hybrid_blade_detail': {
                    'airfoil_ids': [designParameters.hybridBladeDetail.hubAirfoilId, designParameters.hybridBladeDetail.tipAirfoilId],
                    'transition_r_over_Rs': [designParameters.hybridBladeDetail.transition_rOverRadius],
                    'transition_smoothing_r_over_Rs': [designParameters.hybridBladeDetail.transitionSmoothing_rOverRadius]
                }
            },
            'operational_parameter': {
                'atmosphere': operationalParameters.atmosphere,
                'altitude': operationalParameters.altitude,
                'freestream_velocity': operationalParameters.freestreamVelocity,
                'air_density': operationalParameters.density,
                'sound_speed': operationalParameters.soundSpeed,
                'air_viscosity': operationalParameters.viscosity
            },
        }

        return data;
    }

    let reynoldsNumberOptions = (designParameters.airfoilId && designParameters.airfoilId !== '0' && airfoils.length > 0)?
        airfoils.filter(airfoil => (airfoil.id===parseInt(designParameters.airfoilId)))[0].reynoldsNumbers:[];

    const isReynoldsNumberValid = () => {
        if (designParameters.reynoldsRef && designParameters.reynoldsRef !== '0') {
            if (reynoldsNumberOptions.map(reynoldsNumber => reynoldsNumber.toString()).includes(designParameters.reynoldsRef)){
                return true;
            }
        }

        return false;
    }

    const isMaxCLValid = () => {
        if (isInputRange.maxCL) {
            if (designParameters.maxCLLower && designParameters.maxCLLower) {
                const maxCLLower = Number(designParameters.maxCLLower);
                const maxCLUpper = Number(designParameters.maxCLUpper);
                if ((minAirfoilCLValue <= maxCLLower) && (maxCLLower <= maxAirfoilCLValue) && 
                (minAirfoilCLValue <= maxCLUpper) && (maxCLUpper <= maxAirfoilCLValue)) {
                    return true;
                }
            }
        } else {
            if (designParameters.maxCLLower) {
                const maxCL = Number(designParameters.maxCLLower);
                if ((minAirfoilCLValue <= maxCL) && (maxCL <= maxAirfoilCLValue)) {
                    return true;
                }
            }
        }

        return false;
    }

    const isMinCLValid = () => {
        if (designParameters.minCL) {
            const minCL = Number(designParameters.minCL);
            if ((minAirfoilCLValue <= minCL) && (minCL <= maxAirfoilCLValue)) {
                return true;
            }
        } 

        return false;
    }
    
    const isEligibleForHybridBlade = () => {
        try {
            const {hubRadiusLower, tipRadiusLower, sectionsCount, maxCLLower, minCL, reynoldsRef, clDesign, 
                hubBreakCLLower, tipBreakCLLower} = designParameters;
            
            const isNumeric = (number:string) => !_.isNaN(Number(number))

            if (!(isNumeric(hubRadiusLower) && isNumeric(tipRadiusLower) && isNumeric(sectionsCount) && 
            isNumeric(maxCLLower) && isNumeric(minCL) && isNumeric(reynoldsRef))) {
                return false;
            }

            if (clDesign === 'DOUBLE_BREAK' && !(isNumeric(hubBreakCLLower) && isNumeric(tipBreakCLLower))) {
                return false;
            }
            
            if ((parseFloat(hubRadiusLower) <= 0) || 
                (parseFloat(tipRadiusLower) <= 0) ||
                (parseInt(sectionsCount) <= 0) ||
                (parseFloat(maxCLLower) <= parseFloat(minCL)) ||
                (parseInt(reynoldsRef) <= 0) ||
                ((clDesign === 'DOUBLE_BREAK') && (
                 (parseFloat(hubBreakCLLower) <=0) || (parseFloat(hubBreakCLLower)>=100) ||
                 (parseFloat(tipBreakCLLower) <=0) || (parseFloat(tipBreakCLLower)>=100) || 
                 (parseFloat(hubBreakCLLower) >= parseFloat(tipBreakCLLower))
                 ))
                ) {
                return false;
            }

            return true;
        } catch (error) {
            return false;
        }
    }

    const tipAirfoilOptions = airfoils.filter(airfoil => (designParameters.reynoldsRef && airfoil.reynoldsNumbers.includes(Number(designParameters.reynoldsRef)) && 
        airfoil.id.toString() !== designParameters.hybridBladeDetail.hubAirfoilId.toString()));

    const onSaveAsNew = () => {
        if ((isInputRange.hubRadius && (Number(designParameters.hubRadiusLower) > Number(designParameters.hubRadiusUpper))) || 
            (isInputRange.tipRadius && (Number(designParameters.tipRadiusLower) > Number(designParameters.tipRadiusUpper))) || 
            (isInputRange.bladesCount && (Number(designParameters.bladesCountLower) > Number(designParameters.bladesCountUpper))) || 
            (isInputRange.maxCL && (Number(designParameters.maxCLLower) > Number(designParameters.maxCLUpper))) || 
            ((designParameters.clDesign === 'DOUBLE_BREAK') && 
             ((isInputRange.hubBreak && (Number(designParameters.hubBreakCLLower) > Number(designParameters.hubBreakCLUpper))) || 
              (isInputRange.tipBreak && (Number(designParameters.tipBreakCLLower) > Number(designParameters.tipBreakCLUpper)))))) {
            enqueueSnackbar('Invalid range.', {variant: 'error'});
            return;
        }
        if (!isReynoldsNumberValid()) {
            enqueueSnackbar('Invalid Reynolds Reference.', {variant: 'error'});
            return;
        }

        if (isInputRange.maxCL) {
            const maxCLLower = Number(designParameters.maxCLLower);
            const maxCLUpper = Number(designParameters.maxCLUpper);

            if (maxCLLower < minAirfoilCLValue) {
                enqueueSnackbar('Lower bound for max CL is lower than the minimum airfoil CL value');
                return;
            } else if (maxCLUpper > maxAirfoilCLValue) {
                enqueueSnackbar('Upper bound for max CL is lower than the maximum airfoil CL value');
                return;
            }
        } else {
            const maxCL = Number(designParameters.maxCLLower);
            if ((maxCL < minAirfoilCLValue) || (maxAirfoilCLValue < maxCL)) {
                enqueueSnackbar('Max CL is out of airfoil CL range')
                return;
            }
        }

        const minCL = Number(designParameters.minCL);
        if ((minCL < minAirfoilCLValue) || (maxAirfoilCLValue < minCL)) {
            enqueueSnackbar('Min CL is out of airfoil CL range');
            return;
        }

        const data = getInputData();

        setIsLoading(true);
        post(POST_API_URLS.EXPERIMENT_CREATE(), data, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Saved the experiment input successfully.', {variant: 'success'});
                setExperimentId(res.data.experiment_id);
                setExperimentStatus(ExperimentStatus.NEW)
                navigate(`/inputs/${res.data.experiment_id}`)
            } else {
                enqueueSnackbar('Error while saving the experiment input.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while saving the experiment input.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const onUpdate = () => {
        if ((isInputRange.hubRadius && (Number(designParameters.hubRadiusLower) > Number(designParameters.hubRadiusUpper))) || 
            (isInputRange.tipRadius && (Number(designParameters.tipRadiusLower) > Number(designParameters.tipRadiusUpper))) || 
            (isInputRange.bladesCount && (Number(designParameters.bladesCountLower) > Number(designParameters.bladesCountUpper))) || 
            (isInputRange.maxCL && (Number(designParameters.maxCLLower) > Number(designParameters.maxCLUpper))) || 
            ((designParameters.clDesign === 'DOUBLE_BREAK') && 
             ((isInputRange.hubBreak && (Number(designParameters.hubBreakCLLower) > Number(designParameters.hubBreakCLUpper))) || 
              (isInputRange.tipBreak && (Number(designParameters.tipBreakCLLower) > Number(designParameters.tipBreakCLUpper)))))) {
            enqueueSnackbar('Invalid range.', {variant: 'error'});
            return;
        }

        if (!isReynoldsNumberValid()) {
            enqueueSnackbar('Invalid Reynolds Reference.', {variant: 'error'});
            return;
        }

        if (!isMaxCLValid()) {
            enqueueSnackbar('Max CL value is out of the airfoil CL range', {variant: 'error'});
            return;
        }

        if (!isMinCLValid()) {
            enqueueSnackbar('Min CL value is out of the airfoil CL range', {variant: 'error'});
            return;
        }

        const data = getInputData();
        setIsLoading(true);
        post(POST_API_URLS.EXPERIMENT_UPDATE(experimentId || 0), data, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Updated the experiment input successfully.', {variant: 'success'})
            } else {
                enqueueSnackbar('Error while updating the experiment input.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while updating the experiment input.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const getReynoldsNumberProperties = (airfoilId = designParameters.airfoilId, reynoldsRef = designParameters.reynoldsRef) => {
        get(GET_API_URLS.AIRFOIL_PROPERTIES_BY_REYNOLDS_NUMBER(Number(airfoilId),Number(reynoldsRef)), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                setMaxAirfoilCLValue(Math.max(...res.data.airfoil_properties.cl_values));
                setMinAirfoilCLValue(Math.min(...res.data.airfoil_properties.cl_values));
            } else if (res.status === 204){
                onNewReynoldNumber();
            } else {
                enqueueSnackbar('Error while loading the airfoil properties', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while loading the airfoils properties', {variant: 'error'});
        })
    };
    
    const onNewReynoldNumber = () => {
        setConfirmationProps({
            title: 'New Reynolds Number',
            body: 'This is  a new reynolds number. It might take some time (~1 minute) to compute the properties. Do you want to proceed?',
            onCancel: () => setConfirmationProps(null),
            onConfirm: () => newReynoldNumberCreateProps()
        });
    };
    
    const newReynoldNumberCreateProps = () => {
        if(Number(designParameters.airfoilId)!==0 && !(reynoldsNumberOptions.map(reynoldsNumber => reynoldsNumber.toString()).includes(designParameters.reynoldsRef))
             && Number(designParameters.reynoldsRef)!= 0 && designParameters.reynoldsRef != ''){
            setIsLoadingNewProps(true);
            get(GET_API_URLS.CREATE_REYNOLDS_NUMBER_PROPERTIES(Number(designParameters.airfoilId),Number(designParameters.reynoldsRef)), expireCallback).then((res:AxiosResponse) => {
                if (res.status === 200) {
                    setMaxAirfoilCLValue(Math.max(...res.data.airfoil_properties.cl_values));
                    setMinAirfoilCLValue(Math.min(...res.data.airfoil_properties.cl_values));
                    enqueueSnackbar('Successfully created the airfoil properties', {variant: 'success'});
                } else {
                    enqueueSnackbar('Error while creating the airfoil properties', {variant: 'error'});
                }
            }).catch((res:AxiosError) => {
                enqueueSnackbar('Error while creating the airfoil properties', {variant: 'error'});
            }).finally(() => {
                setIsLoadingNewProps(false);
                setConfirmationProps(null);
            })
        } else{
            enqueueSnackbar('Invalid Reynolds Number', {variant: 'error'});
        }
    };

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop:'20px', marginBottom: '15px'}}>
                <MenuButton
                    Icon={SaveAsOutlinedIcon}
                    iconColor='black'
                    label='Save As New'
                    onClick={onSaveAsNew}
                    disabled={false}
                />
                {experimentId && (
                    <MenuButton
                        Icon={SaveOutlinedIcon}
                        iconColor='black'
                        label='Update'
                        onClick={onUpdate}
                        disabled={[ExperimentStatus.COMPLETED, ExperimentStatus.COMPLETED_BUT_STOPPED].includes(
                            experimentStatus)}
                    />
                )}
            </Stack>
        );
    };

    const findNameById = (option:number|string) => {
        const airfoil = airfoils.find(airfoils => Number(airfoils.id) === Number(option));
        return airfoil ? airfoil.name : '';
    };

    const findIdByName = (option:number|string) => {
        const airfoil = airfoils.find(airfoils => (airfoils.name).toString() === (option).toString());
        return airfoil ? airfoil.id : 0;
    };

    const smallSX = {m:1, width: '18ch'};

    return (
        <>
        {isLoading && <CircularSpinner />}
        {isLoadingNewProps && <CircularSpinner />}
        <NavigationBar showMenu={true}/>
        <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{height:'100%'}}>
            <div style={{width:'50px'}}>
                <Sidebar Menubar={Menubar} ActivePage={Page.INPUTS}/>
            </div>
        
            <div style={{minWidth: '1196px', maxWidth: '1196px', paddingLeft:'30px'}}>
                <Stack spacing={3} style={{minWidth: '1210px', maxWidth: '1210px'}}>
                    <Typography style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}>Inputs</Typography>
                    <Stack spacing={2}>
                        <Stack direction='row' spacing={4}>
                            <Stack spacing={2} justifyContent='space-between'>
                                <fieldset className='input-grouping'>
                                    <legend className='input-grouping-label'>Project</legend>
                                    <Stack direction='row' spacing={2}>
                                        <OutlinedInput
                                            label='Company'
                                            value={company}
                                            onValueChange={(newCompany) => setCompany(newCompany)}
                                            readOnly={false}
                                            style={{ width: '258px', height: '60px' }} 
                                            tooltip='Company'
                                            autoComplete='organization'
                                        />
                                        <OutlinedInput
                                            label='Platform'
                                            value={platform}
                                            onValueChange={(newPlatform) => setPlatform(newPlatform)}
                                            readOnly={false}
                                            style={{width:'258px', height: '60px'}}
                                            tooltip='Platform'
                                        />
                                    </Stack>
                                </fieldset>
                                <fieldset className='input-grouping'>
                                    <legend className='input-grouping-label'>Geometry</legend>
                                    <Stack spacing={2}>
                                        <Stack direction='row' spacing={2}>
                                            <RangeInput
                                                label='Radius @ Hub r_h (in)'
                                                lowerLimit={designParameters.hubRadiusLower}
                                                onLowerLimitChange={(newHubRadiusLower) => {
                                                    setDesignParameters((oldDesignParamters) => ({...oldDesignParamters, hubRadiusLower: newHubRadiusLower}));
                                                }}
                                                upperLimit={designParameters.hubRadiusUpper}
                                                onUpperLimitChange={(newHubRadiusUpper) => {
                                                    setDesignParameters((oldDesignParamters) => ({...oldDesignParamters, hubRadiusUpper: newHubRadiusUpper}));
                                                }}
                                                isRange={isInputRange.hubRadius}
                                                setIsInputRangeType={(value) => setIsInputRange({...isInputRange, hubRadius: value})}
                                                tooltip='Distance from axis of rotation to first airfoil section'
                                            />
                                            <RangeInput
                                                label='Radius @ Tip r_t (in)'
                                                lowerLimit={designParameters.tipRadiusLower}
                                                onLowerLimitChange={(newTipRadiusLower) => {
                                                    setDesignParameters((oldDesignParamters) => ({...oldDesignParamters, tipRadiusLower: newTipRadiusLower}));
                                                }}
                                                upperLimit={designParameters.tipRadiusUpper}
                                                onUpperLimitChange={(newTipRadiusUpper) => {
                                                    setDesignParameters((oldDesignParamters) => ({...oldDesignParamters, tipRadiusUpper: newTipRadiusUpper}));
                                                }}
                                                isRange={isInputRange.tipRadius}
                                                setIsInputRangeType={(value) => setIsInputRange({...isInputRange, tipRadius: value})}
                                                tooltip='Distance from axis of rotation to last airfoil section'
                                            />
                                            <RangeInput
                                                label='Blade Number'
                                                lowerLimit={designParameters.bladesCountLower}
                                                onLowerLimitChange={(newBladesCountLower)=>{
                                                    setDesignParameters((oldDesignParamters) => ({...oldDesignParamters, bladesCountLower: newBladesCountLower}))
                                                }}
                                                upperLimit={designParameters.bladesCountUpper}
                                                onUpperLimitChange={(newBladesCountUpper) => {
                                                    setDesignParameters((oldDesignParamters) => ({...oldDesignParamters, bladesCountUpper: newBladesCountUpper}))
                                                }}
                                                isRange={isInputRange.bladesCount}
                                                setIsInputRangeType={(value) => setIsInputRange({...isInputRange, bladesCount: value})}
                                                tooltip='Blade number'
                                            />
                                        </Stack>
                                        <Stack direction='row' spacing={2}>
                                            <OutlinedInput
                                                label='Number of Sections'
                                                value={designParameters.sectionsCount}
                                                onValueChange={(newSectionsCount) => {
                                                    setDesignParameters({...designParameters, sectionsCount: newSectionsCount});
                                                }}
                                                readOnly={false}
                                                style={{width:'155px', height: '60px'}}
                                                tooltip='How many sections are used to calculate the performance of the blade'
                                            />
                                            
                                            <FormControl sx={smallSX} variant="outlined" style={{marginTop: '8px', marginLeft:'18px'}}>
                                                <InputLabel id="tip-type">Tip Type</InputLabel>
                                                <MUITooltip 
                                                    title = {
                                                        <React.Fragment>
                                                            <img src={tipHover} alt='Tip type' height={200} width={200} style={{backgroundColor:'white'}}/>
                                                        </React.Fragment>
                                                    }
                                                    placement="right"
                                                >
                                                        <Select
                                                            id='tip-type'
                                                            label="Tip Type"
                                                            value={designParameters.tipType}
                                                            onChange={(e:SelectChangeEvent<typeof designParameters.tipType>) => {
                                                                updateToolTip(designParameters.tipType);
                                                                setDesignParameters({...designParameters, tipType: e.target.value})
                                                            }}
                                                            style={{height:'56px'}}
                                                        >
                                                            <MenuItem value={TIPTYPE.OVAL2}
                                                                onMouseOver={
                                                                    () => updateToolTip(TIPTYPE.OVAL2)
                                                                }
                                                                onMouseLeave={
                                                                    () => updateToolTip(designParameters.tipType)
                                                                }
                                                            >Oval 2</MenuItem>
                                                            <MenuItem value={TIPTYPE.SQUARE}
                                                                onMouseOver={
                                                                    () => updateToolTip(TIPTYPE.SQUARE)
                                                                }
                                                                onMouseLeave={
                                                                    () => updateToolTip(designParameters.tipType)
                                                                }
                                                            >Square</MenuItem>
                                                        </Select>
                                                    </MUITooltip>
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                </fieldset>
                            </Stack>

                            <Stack spacing={2}>
                                <fieldset className='input-grouping'>
                                    <legend className='input-grouping-label'>Atmospheric Conditions</legend>
                                    <Stack spacing={2}>
                                        <Stack direction='row' spacing={7}>
                                            <MUITooltip title='Standard Atmosphere' arrow placement='top'>
                                                <FormControl sx={smallSX}>
                                                    <InputLabel id="atmosphere" sx={{m:'3'}}>
                                                        Standard Atmosphere
                                                    </InputLabel>
                                                    <Select
                                                        id='atmosphere'
                                                        label='Standard Atmosphere'
                                                        value={operationalParameters.atmosphere}
                                                        onChange={(e) => {
                                                            const result = calculateAtmosphere(e.target.value, operationalParameters.altitude)
                                                            setOperationalParameters({...operationalParameters, atmosphere: e.target.value, density: result.rho.toString(), viscosity: result.mu.toString(), soundSpeed: result.a.toString()});
                                                        }}
                                                        style={{ width: '200px', height: '55px' }}
                                                    >
                                                        <MenuItem value='STANDARD'>Standard Day</MenuItem>
                                                        {/* <MenuItem value='COLD'>Cold Day</MenuItem>
                                                        <MenuItem value='HOT'>Hot Day</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                            </MUITooltip>
                                            <OutlinedInput
                                                label='Altitude (ft)'
                                                value={operationalParameters.altitude}
                                                onValueChange={(altitude) => {
                                                    const result = calculateAtmosphere(operationalParameters.atmosphere, altitude)            
                                                    setOperationalParameters({ ...operationalParameters, altitude: altitude, density: result.rho.toString(), viscosity: result.mu.toString(), soundSpeed: result.a.toString() });
                                                } }
                                                style={{ width: '180px', height: '60px' }} 
                                                readOnly={false}
                                                tooltip='Altitude at design point'
                                            />
                                            
                                        </Stack>
                                        <Stack direction='row' spacing={2}>
                                            <OutlinedInput
                                                label='Density &rho; (kg/m^3)'
                                                value={operationalParameters.density}
                                                onValueChange={(density) => {
                                                    setOperationalParameters({ ...operationalParameters, density: density });
                                                } }
                                                style={{ width: '180px', height: '60px' }} 
                                                readOnly={false}
                                                tooltip='Density of design point'
                                            />
                                        
                                            <OutlinedInput
                                                label='Viscosity &mu; (kg/m*s)'
                                                value={operationalParameters.viscosity}
                                                onValueChange={(viscosity) => {
                                                    setOperationalParameters({ ...operationalParameters, viscosity: viscosity });
                                                } }
                                                style={{ width: '180px', height: '60px' }} 
                                                readOnly={false}
                                                tooltip='Viscosity of design point'
                                            />
                                    
                                            <OutlinedInput
                                                label='Speed of Sound &alpha; (m/s)'
                                                value={operationalParameters.soundSpeed}
                                                onValueChange={(speedOfSound) => {
                                                    setOperationalParameters({ ...operationalParameters, soundSpeed: speedOfSound });
                                                } }
                                                style={{ width: '180px', height: '60px' }} 
                                                readOnly={false} 
                                                tooltip='Speed of sound of design point'
                                            />
                                        </Stack>
                                    </Stack>
                                </fieldset>
                                <fieldset className='input-grouping'>
                                        <legend className='input-grouping-label'>Operation Conditions/Requirements</legend>
                                        <Stack>
                                            <Stack direction='row' spacing={2}>
                                                <OutlinedInput
                                                    label='V∞ (ft/s)'
                                                    value={operationalParameters.freestreamVelocity}
                                                    onValueChange={(newFreestreamVelocity) => {
                                                        setOperationalParameters({...operationalParameters, freestreamVelocity: newFreestreamVelocity});
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='Freestream velocity (Airflow speed into propeller system)'
                                                />
                                                <OutlinedInput
                                                    label="T (lbf)"
                                                    value={designParameters.thrust}
                                                    onValueChange={(newThrust) => {
                                                        setDesignParameters({...designParameters, thrust: newThrust});
                                                    }}
                                                    readOnly={false}
                                                    style={{width:'155px', height: '60px'}}
                                                    tooltip='Thrust required by propeller. T = Weight of platform / # of motors Not per blade'
                                                />
                                                <MUITooltip 
                                                    title='Property of flow that defines flow behavior. Impacts airfoil performance significantly. Typical Guess: Re # = (Density*V*Cord) / Viscosity where V = (3/4)*r_t*RPM*(π/30)'
                                                    arrow
                                                    placement='top'
                                                >
                                                    <FormControl sx={smallSX} variant='outlined' style={{width: '200px', marginTop:'8px', marginLeft:'18px'}}>
                                                        <Autocomplete
                                                            id="reynolds-reference-select"
                                                            sx={{ width: 200 }}
                                                            value={designParameters.reynoldsRef} 
                                                            options={reynoldsNumberOptions.map(reynoldsNumber => reynoldsNumber.toString())}
                                                            onChange={(e: any, newReynoldsNumber: string | null) => {
                                                                setDesignParameters({...designParameters, reynoldsRef: newReynoldsNumber?newReynoldsNumber.toString():''});
                                                            }}
                                                            getOptionLabel={(option) => formatNumberWithCommas(option)}
                                                            isOptionEqualToValue={(option, value) => option===value} 
                                                            filterOptions={(option) => option.filter((x) => x.includes((designParameters.reynoldsRef)))}
                                                            componentsProps={{
                                                                popper: {modifiers:[{ name: 'flip', enabled: false}]}
                                                            }}
                                                            ListboxProps={{ style: { maxHeight: '200px' } }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Reynolds Reference" 
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        const newReynoldsNumber = e.target.value;
                                                                        setDesignParameters({...designParameters, reynoldsRef: newReynoldsNumber ? newReynoldsNumber.toString().replaceAll(',','') : ''});
                                                                    }}
                                                                    onBlur={() => {
                                                                        if (Number(designParameters.reynoldsRef)!=0 && designParameters.reynoldsRef != ''){
                                                                            getReynoldsNumberProperties();
                                                                        }
                                                                    }}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    
                                                    </FormControl>
                                                </MUITooltip>
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                            </Stack>
                        </Stack>

                        <fieldset className='input-grouping' style={{width:'1318px'}}>
                            <legend className='input-grouping-label'>Lift Distribution/Design</legend>
                            <Stack spacing={2}>
                                <Stack direction='row' spacing={2}>
                                    <RangeInput
                                        label='Max Design CL'
                                        lowerLimit={designParameters.maxCLLower}
                                        onLowerLimitChange={(newMaxCLLower) => {
                                            setDesignParameters((oldDesignParamters) => ({...oldDesignParamters, maxCLLower: newMaxCLLower}));
                                        }}
                                        upperLimit={designParameters.maxCLUpper}
                                        onUpperLimitChange={(newMaxCLUpper) => {
                                            setDesignParameters((oldDesignParamters) => ({...oldDesignParamters, maxCLUpper: newMaxCLUpper}));
                                        }}
                                        onBlurChange={() => {
                                            if (!isMaxCLValid()) {
                                                enqueueSnackbar('Max CL value is out of the airfoil CL range', {variant: 'error'});
                                            }
                                        }}
                                        isRange={isInputRange.maxCL}
                                        setIsInputRangeType={(value) => setIsInputRange({...isInputRange, maxCL: value})}
                                        tooltip={`Max coefficient of lift for propeller. Airfoil CL Range = [${minAirfoilCLValue}, ${maxAirfoilCLValue}]`}
                                    />
                                    <OutlinedInput
                                        label="Min CL"
                                        value={designParameters.minCL}
                                        onValueChange={(newMinCL) => {
                                            setDesignParameters({...designParameters, minCL: newMinCL});
                                        }}
                                        onBlurChange={() => {
                                            if (!isMinCLValid()) {
                                                enqueueSnackbar('Min CL value is out of the airfoil CL range', {variant: 'error'});
                                            }
                                        }}
                                        readOnly={false}
                                        style={{width:'155px', height: '60px'}}
                                        tooltip={`Only for double break. Specifies what the CL will change to at tip break. Airfoil CL Range = [${minAirfoilCLValue}, ${maxAirfoilCLValue}]`}
                                    />
                                    <FormControl sx={smallSX} variant="outlined" style={{marginTop: '8px', marginLeft:'18px'}}>
                                        <InputLabel id="cl-design">CL Design</InputLabel>
                                        <Select
                                            id='cl-design'
                                            label='CL Design'
                                            value={designParameters.clDesign}
                                            onChange={(e:SelectChangeEvent<typeof designParameters.clDesign>) => {
                                                setDesignParameters({...designParameters, clDesign: e.target.value});
                                            }}
                                            size='small'
                                            style={{height:'56px'}}
                                        >
                                            <MenuItem value='CONSTANT'>
                                                <MUITooltip title='Same CL for all sections' arrow placement='top'>
                                                    <div>Constant</div>
                                                </MUITooltip>
                                            </MenuItem>
                                            <MenuItem value='DOUBLE_BREAK'>
                                                <MUITooltip title='CL distribution goes from max CL to min CL starting at hub break and ending at tip break' arrow placement='bottom'>
                                                        <div>Double Break</div>
                                                </MUITooltip>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    {
                                        (designParameters.clDesign === 'DOUBLE_BREAK') && (
                                            <>
                                                <RangeInput
                                                    label='Hub Break'
                                                    lowerLimit={designParameters.hubBreakCLLower}
                                                    onLowerLimitChange={(newHubBreakCLLower) => {
                                                        setDesignParameters({...designParameters, hubBreakCLLower: newHubBreakCLLower});
                                                    }}
                                                    upperLimit={designParameters.hubBreakCLUpper}
                                                    onUpperLimitChange={(newHubBreakCLUpper) => {
                                                        setDesignParameters({...designParameters, hubBreakCLUpper: newHubBreakCLUpper});
                                                    }}
                                                    isRange={isInputRange.hubBreak}
                                                    setIsInputRangeType={(value) => setIsInputRange({...isInputRange, hubBreak: value})}
                                                    tooltip='Location where CL starts to change from max CL to min CL'
                                                />
                                                <RangeInput
                                                    label='Tip Break'
                                                    lowerLimit={designParameters.tipBreakCLLower}
                                                    onLowerLimitChange={(newTipBreakCLLower) => {
                                                        setDesignParameters({...designParameters, tipBreakCLLower: newTipBreakCLLower});
                                                    }}
                                                    upperLimit={designParameters.tipBreakCLUpper}
                                                    onUpperLimitChange={(newTipBreakCLUpper) => {
                                                        setDesignParameters({...designParameters, tipBreakCLUpper: newTipBreakCLUpper});
                                                    }}
                                                    isRange={isInputRange.tipBreak}
                                                    setIsInputRangeType={(value) => setIsInputRange({...isInputRange, tipBreak: value})}
                                                    tooltip='Location where CL distribution reaches min CL'
                                                />
                                            </>
                                        )
                                    }
                                    <MUITooltip title='Need to fill up Radius @ Hub(r_h), Radius @ Tip (r_t), Reynolds reference, Number of Sections and Max Design CL fields first. If CL Design is Double Break, then the Hub Break and Tip Break will need to be filled in.' arrow placement='top'>
                                        <FormControlLabel 
                                            label="Hybrid Blade" 
                                            control={
                                                <Checkbox 
                                                    name="isHybridBlade" 
                                                    checked={designParameters.isHybridBlade}           
                                                    disabled={!isEligibleForHybridBlade()} 
                                                    onChange={(e) => {
                                                        setDesignParameters({
                                                            ...designParameters, 
                                                            isHybridBlade: e.target.checked
                                                        });
                                                    }}
                                                />
                                            } 
                                        />
                                    </MUITooltip>
                                    {!designParameters.isHybridBlade && (
                                        <MUITooltip title='Cross section of propeller blade that has different performance characteristics' arrow placement='top'>
                                            <FormControl sx={smallSX} style={{marginTop: '8px', marginLeft:'18px'}}>
                                                <Autocomplete
                                                    id="airfoil"
                                                    value={Number(designParameters.airfoilId)}  
                                                    options={airfoils.map((airfoil) => (airfoil.id))}
                                                    getOptionLabel={(option) => findNameById(option)}
                                                    onChange={(e: any, newAirfoilId: number|null) => {
                                                        if (newAirfoilId){
                                                            setDesignParameters({...designParameters, airfoilId: newAirfoilId.toString(), hybridBladeDetail: {...designParameters.hybridBladeDetail, hubAirfoilId: newAirfoilId.toString()}});
                                                        }
                                                    }}
                                                    sx={{ width: 155 }}
                                                    componentsProps={{
                                                        popper: {modifiers:[{ name: 'flip', enabled: false}]}
                                                    }}
                                                    ListboxProps={{ style: { maxHeight: '200px' } }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Airfoil"
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (airfoils.map(airfoil => airfoil.name).includes(e.target.value.toString())){
                                                                    setDesignParameters({...designParameters, airfoilId: findIdByName(e.target.value).toString(), hybridBladeDetail: {...designParameters.hybridBladeDetail, hubAirfoilId: findIdByName(e.target.value).toString()}});
                                                                } 
                                                            }}
                                                            onBlur={() => {
                                                                if (designParameters.reynoldsRef && Number(designParameters.reynoldsRef)!=0 && designParameters.reynoldsRef != ''){
                                                                    getReynoldsNumberProperties();
                                                                }
                                                            }}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </MUITooltip>
                                    )}
                                </Stack>
                            {designParameters.isHybridBlade && isEligibleForHybridBlade() && (
                            <fieldset>
                                <legend>Hybrid Blade Design</legend>
                                <Stack spacing={2}>
                                    <Stack direction='row'>
                                        <FormControl sx={smallSX} variant="outlined">
                                            <Autocomplete
                                                id="Airfoil @ Hub"
                                                value={Number(designParameters.hybridBladeDetail.hubAirfoilId)}  
                                                options={airfoils.map((airfoil) => (airfoil.id))}
                                                getOptionLabel={(option) => findNameById(option)}
                                                onChange={(e: any, newAirfoilId: number|null) => {
                                                    if (newAirfoilId){
                                                        setDesignParameters({...designParameters, airfoilId: newAirfoilId.toString(), hybridBladeDetail: {...designParameters.hybridBladeDetail, hubAirfoilId: newAirfoilId.toString()}});
                                                    }
                                                }}
                                                componentsProps={{
                                                    popper: {modifiers:[{ name: 'flip', enabled: false}]}
                                                }}
                                                ListboxProps={{ style: { maxHeight: '200px' } }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Airfoil @ Hub"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            if (airfoils.map(airfoil => airfoil.name).includes(e.target.value.toString())){
                                                                setDesignParameters({...designParameters, airfoilId: findIdByName(e.target.value).toString(), 
                                                                    hybridBladeDetail: {...designParameters.hybridBladeDetail, hubAirfoilId: findIdByName(e.target.value).toString()}})}
                                                            } 
                                                        }
                                                        inputProps={{
                                                            ...params.inputProps,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                        <FormControl sx={smallSX} variant="outlined">
                                            <Autocomplete
                                                id="Airfoil @ Tip"
                                                value={Number(designParameters.hybridBladeDetail.tipAirfoilId)}  
                                                options={airfoils.map((airfoil) => (airfoil.id))}
                                                getOptionLabel={(option) => findNameById(option)}
                                                onChange={(e: any, newAirfoilId: number|null) => {
                                                    if (newAirfoilId){
                                                        setDesignParameters({...designParameters, hybridBladeDetail: {...designParameters.hybridBladeDetail, tipAirfoilId: newAirfoilId.toString()}})
                                                    }
                                                }}
                                                componentsProps={{
                                                    popper: {modifiers:[{ name: 'flip', enabled: false}]}
                                                }}
                                                ListboxProps={{ style: { maxHeight: '200px' } }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Airfoil @ Tip"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            if (airfoils.map(airfoil => airfoil.name).includes(e.target.value.toString())){
                                                                setDesignParameters({...designParameters, hybridBladeDetail: {...designParameters.hybridBladeDetail, tipAirfoilId:findIdByName(e.target.value).toString()}})
                                                            } 
                                                        }}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                        <OutlinedInput
                                            label='Transition r/R'
                                            value={designParameters.hybridBladeDetail.transition_rOverRadius}
                                            onValueChange={(newTransition_rOverRadius) => {
                                                setDesignParameters({
                                                    ...designParameters, 
                                                    hybridBladeDetail: {...designParameters.hybridBladeDetail, transition_rOverRadius: newTransition_rOverRadius}
                                                });
                                            }}
                                            readOnly={false}
                                            style={{width:'180px', height: '60px', marginLeft: '10px'}}
                                            tooltip="All sections from hub to transition r/R will be filled with hub airfoil's alpha and cd values, and remaining sections after the transition r/R section will be filled with tip airfoil's alpha and cd values"
                                        />
                                        <OutlinedInput
                                            label='Transition smoothing ±r/R'
                                            value={designParameters.hybridBladeDetail.transitionSmoothing_rOverRadius}
                                            onValueChange={(newTransitionSmoothing_rOverRadius) => {
                                                setDesignParameters({
                                                    ...designParameters, 
                                                    hybridBladeDetail: {...designParameters.hybridBladeDetail, transitionSmoothing_rOverRadius: newTransitionSmoothing_rOverRadius}
                                                });
                                            }}
                                            readOnly={false}
                                            style={{width:'180px', height: '60px', marginLeft: '20px'}}
                                            tooltip='All sections within the given range (transition r/R - transition smoothing r/R, transition r/R + transition smoothing r/R) will be used for beta value smoothing and weighted airfoil transition smoothing'
                                        />
                                    </Stack>
                                    <Stack spacing={5}>
                                        <Stack direction='row' spacing={2}>
                                            <fieldset className='input-grouping' style={{width: '600px', height: '320px'}}>
                                                <Scatter 
                                                    options={getGraphOptions('Interpolated Alpha value at each section (r/R vs Alpha)', 'r/R', 'Alpha (Degree)')} 
                                                    data={alphaDistribution}
                                                />
                                            </fieldset>
                                            <fieldset className='input-grouping' style={{width: '600px', height: '320px'}}>
                                                <Scatter 
                                                    options={getGraphOptions('Interpolated CD value at each section (r/R vs CD)', 'r/R', 'CD')} 
                                                    data={cdDistribution}
                                                />
                                            </fieldset>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </fieldset>
                        )}
                            </Stack>
                        </fieldset>  
                    </Stack>
                </Stack>
            </div>
        </Stack>
        {confirmationProps && <ConfirmationDialog {...confirmationProps}/>}
    </>
    );
}

export default WithAuthenticate(ExperimentInputs);