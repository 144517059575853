import React, {useState,useEffect} from 'react';
import CircularSpinner from 'common/CircularSpinner';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from 'utils/api';


export const WithAuthenticate = (WrappedComponent: React.ComponentType) => {
    const AuthenticatedComponent = () => {
        const navigate = useNavigate();

        const [isLoading, setIsLoading] = useState<boolean>(true);

        useEffect(() => {
            if (!isLoggedIn()) {
                navigate('/login');
            }
            setIsLoading(false);
        },[]);

        return (
            isLoading?<CircularSpinner/>:<WrappedComponent/>
        ); 
    }

    return AuthenticatedComponent;
}

export default WithAuthenticate;